import React from 'react'
import Footer from '../Header/Footer'
import Header from '../Header/Header'

export const Techtalentblog = () => {
    return (
        <div>
            <Header />
            <section class="hero-section hero-digital-agency-1 branding-agency video-bg-">
                <video class="full-video" data-mask="3" src="images/hero/home-digital-agency-2.mp4" autoplay="" muted="" loop=""></video>
                <div class="text-block niwax" data-rellax-speed="-3">
                    <div class="container">
                        <div class="row justify-content-center text-center">
                            <div class="col-lg-9 v-center">
                                <div class="header-heading">
                                    <h1 class="wow fadeInUp text-effect-1" data-wow-delay=".2s">Binary Logic Blog</h1>
                                    <p class="wow fadeInUp" data-wow-delay=".4s">Explore all the latest techtalent insights.</p>
                                    <a href="#" class="btn-main bg-btn4 lnk wow fadeInUp" data-wow-delay=".6s">GET STARTED<i class="fas fa-chevron-right fa-icon"></i><span class="circle"></span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="about-dg-2 pb120 pt120 bg-flat1 m-t-c">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6 v-center">
                            <div class="img-block mt0 m-mb30">
                                <img src="images/about/about-dg-agency.jpg" alt="about" class="img-fluid upset" />
                                <div class="shape-dg-1 dg-hero-shp1"><img src="images/shape/dots-dg.png" alt="shape" class="img-fluid" /></div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="common-heading-2 text-l">
                                <span class="text-effect-1">We Are Creative </span>
                                <h2 class="mb20">Explore all the latest techtalent insights.</h2>
                                <p>The TechTalent blog is packed full of content we think you'll find interesting. Explore everything from industry insights to tech talent news, talent trends to the latest stories fresh from the TechTalent Academy.</p>
                                <a href="#" class="btn-main bg-btn5 lnk mt40">Request a proposal<i class="fas fa-chevron-right fa-icon"></i><span class="circle"></span></a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </div>
    )
}
