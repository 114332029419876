import React from 'react'
import Header from './Header'
import bb from '../bb.jpeg'
import { Link } from 'react-router-dom'
import Footer from './Footer'
function Career() {
    return (
        <>
            <Header />
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            {/* <section className="about-lead-gen pad-tb">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="common-heading text-l">
                                <span>Career</span>  
                                <h2 className="text-black">
                                    for us,it's not just work.
                                </h2>
                                <p>
                                    Join our team and explore a more fulfilling career path.
                                </p>
                                <p className="mt10">
                                    Our platform is changing lives and improving society-one student at a time. Join us in our mission to elevate people, companies and communities.
                                </p>
                                <button className=' w-60 text-white p-2 border-none outline-none' style={{ background: "red", marginTop: "40px" }}>View job openings</button>
                            </div>
                        </div>
                        <div className="col-lg-6 lead-intro-">
                            <img
                                src="https://makpowerindia.com/wp-content/uploads/2023/01/Careers.jpeg"
                                style={{ width: "450px" }}
                                alt="image"
                                className="img-fluid "
                            />
                        </div>
                    </div>
                </div>
            </section> */}

            <div class="container-fluid pb-4">
                <section class="row no-gutters align-items-center">
                    <div class="col-12 text-center d-flex">
                        <img class="img-fluid of col-lg-12 ppp" src="https://cdn.syncfusion.com/content/images/Careers/career-logo.png" alt="img1"  />


                    </div>
                </section>
            </div>

            <br></br>
            <br></br>

            <section className=" row">
                <div className="container col-sm">
                    <div className="row justify-content-center text-center">
                        <div className="col-lg-10">
                            <div className="common-heading">
                                <span className='text-black mt-16'><h1><b>Jobs at BinaryLogic</b></h1></span>
                                <h1 className="mb30">
                                    <span className="text-second">Explore </span> what it's like to work with us!
                                </h1>
                                <p className='text-black'>
                                    We are a family of curious and crazy people determine to change the way learning is shared. We want to build a diverse community of people, with a willingness to improve every day, to make a difference in the community we serve....
                                    <Link to="/Binary">
                                        Learn more
                                    </Link>
                                </p>

                                <br></br>
                                <br></br>
                                <a data-bs-toggle="offcanvas"
                                  
                                    href="#offcanvasExample" className="btn-main bg-btn2 lnk">
                                    Apply Now
                                    <i className="fas fa-chevron-right fa-icon" />
                                    <span className="circle" />

                                </a>
                            </div>
                        </div>
                    </div>

                </div>

                <div className='col-sm'>
                    <img src='https://koshatechnohub.com/wp-content/uploads/2022/01/undraw_Interview_re_e5jn-1024x757.png'></img>
                </div>
            </section>




            <Footer />

        </>
    )
}

export default Career