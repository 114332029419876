import React from 'react'
import Footer from './Footer'
import Header from './Header'
import emergings1 from './emergings1.gif'
export const Emerging = () => {
    return (
        <div>

            <br></br>
            <br></br>
       

            <Header />

            <br></br>



            <div id="carouselExampleSlidesOnly" class="carousel slide" data-ride="carousel" >
                <div class="carousel-inner">
                    <div class="carousel-item active">
                        <img class="d-block w-100  emerg emerg1" src={emergings1} alt="First slide"  />
                        
                    </div>

                </div>
            </div>

            <section className="about-lead-gen pad-tb">
                <div className="container">
                    <div className='common-heading text-l'>
                        <span>Emerging Technology Integration</span>

                        <p style={{ textAlign: "justify" }}>
                            Technology integration is the approach that companies use to choose and refine the technologies employed in a new product, process, or service. Access to great research is still immensely important, but if a company selects technologies that don’t work well together, it can end up with a product that is hard to manufacture, is late getting to market, and does not fulfill its envisioned purpose. An effective technology-integration process starts in the earliest phases of an R&D project and provides a road map for all design, engineering, and manufacturing activities. It defines the interaction between the world of research and the worlds of manufacturing and product application.
                        </p>
                    </div>

                </div>
            </section>

            <section className="about-lead-gen ">
                <div className="container" style={{ marginTop: "-3rem" }}>
                    <div className='common-heading text-l'>
                        <span>A company’s approach to technology integration must fit its capabilities and local culture.</span>

                        <p style={{ textAlign: "justify" }}>
                            Another important discovery about technology integration is that there is not just one successful approach. Rather, to be successful, the approach adopted must be in harmony with a company’s capabilities and its local culture and conditions. Our research in the semiconductor industry documents these ideas in detail. But before we delve into the evidence, let us explore how technology integration works.
                        </p>
                    </div>

                    <div className='common-heading text-l'>
                        <span>A New Approach to R&D</span>

                        <p style={{ textAlign: "justify" }}>
                            In 1990, even the mightiest U.S. players in the computer industry were in retreat, and a lot of the weaker players had disappeared. IBM had lost substantial market share to Japanese manufacturers in every hardware segment. Intel was consistently late in introducing new generations of semiconductor technology and new chip designs. And even Microsoft’s competitive position in software seemed in jeopardy as a result of severe delays in introducing new products as well as problems with product reliability.
                        </p>
                    </div>
                    <br></br>
                    <br></br>
                </div>
            </section>

            <Footer />

        </div>
    )
}
