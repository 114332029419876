import React from 'react'
import './Campuses.css'
import Header from '../Header'
import Footer from '../Footer'
import oh1 from './oh1.jpeg'
import oh2 from './oh2.jpeg'
import oh3 from './oh3.jpeg'
import oh4 from './oh4.jpeg'
import oh5 from './oh5.jpeg'
import oh6 from './oh6.jpeg'
import oh7 from './oh7.jpeg'
import campuses6 from './campuses6.webp'
import './new.scss'

// import vir1 from './vir1.jpeg'
import vir2 from './vir2.jpeg'
import vir3 from './vir3.jpeg'
import vir4 from './vir4.jpeg'
import vir5 from './vir5.jpeg'
import vir6 from './vir6.jpeg'
import vir7 from './vir7.jpeg'
import vir8 from './vir8.jpeg'
import { Splide, SplideSlide } from '@splidejs/react-splide';
export const Campuses = () => {

    document.addEventListener('DOMContentLoaded', function () {
        new Splide('#thumbnail-carousel', {
            fixedWidth: 100,
            gap: 10,
            rewind: true,
            pagination: false,
        }).mount();
    });


    return (


        <>

            <Header />

            <br></br>
            <br></br>
            <br></br>
            <br></br>

            <div class="container-fluid pb-4">
                <section class="row no-gutters align-items-center">
                    <div class="col-12 text-center d-flex">
                        <img class="img-fluid of col-lg-12 ppp" src={campuses6} alt="img1" style={{ height: "29rem" }} />


                    </div>
                </section>
            </div>



            <center> <h2><u style={{ color: "#0985dc" }}>Our Campuses</u></h2></center>

            <div className='row'>
                <div className='col-sm-6'>
                    <div class="container mt-5">
                        <div class="carousel-container position-relative row">
                            <div id="myCarousel" class="carousel slide" data-ride="carousel">
                                <div class="carousel-inner">
                                    <div class="carousel-item active" data-slide-number="0">
                                        <img src={oh1} class="d-block " alt="..." data-remote={oh1} data-type="image" data-toggle="lightbox" data-gallery="example-gallery" />
                                    </div>
                                    <div class="carousel-item" data-slide-number="1">
                                        <img src={oh2} class="d-block " alt="..." data-remote={oh2} data-type="image" data-toggle="lightbox" data-gallery="example-gallery" />
                                    </div>
                                    <div class="carousel-item" data-slide-number="2">
                                        <img src={oh3} class="d-block " alt="..." data-remote={oh3} data-type="image" data-toggle="lightbox" data-gallery="example-gallery" />
                                    </div>
                                    <div class="carousel-item" data-slide-number="3">
                                        <img src={oh4} class="d-block " alt="..." data-remote={oh4} data-type="image" data-toggle="lightbox" data-gallery="example-gallery" />
                                    </div>

                                    <div class="carousel-item" data-slide-number="5">
                                        <img src={oh6} class="d-block " alt="..." data-remote={oh6} data-type="image" data-toggle="lightbox" data-gallery="example-gallery" />
                                    </div>
                                    <div class="carousel-item" data-slide-number="6">
                                        <img src={oh7} class="d-block " alt="..." data-remote={oh7} data-type="image" data-toggle="lightbox" data-gallery="example-gallery" />
                                    </div>

                                </div>
                            </div>


                            <div id="carousel-thumbs1" class="carousel slide" data-ride="carousel">
                                <div class="carousel-inner">
                                    <div class="carousel-item active">
                                        <div class="row mx-0">
                                           


                                            <div class="gallery-wrapper">
                                                <div class="image-wrapper">
                                                    <a href="#lightbox-image-1">
                                                        <img src={oh1} class="img-fluid" alt="..." />
                                                      
                                                    </a>
                                                </div>
                                                <div class="image-wrapper">
                                                    <a href="#lightbox-image-2">
                                                        <img src={oh2} class="img-fluid" alt="..." />
                                                     
                                                    </a>
                                                </div>
                                                <div class="image-wrapper">
                                                    <a href="#lightbox-image-3">
                                                        <img src={oh3} class="img-fluid" alt="..." />
                                                       
                                                    </a>
                                                </div>
                                                <div class="image-wrapper">
                                                    <a href="#lightbox-image-4">
                                                        <img src={oh4} class="img-fluid" alt="..." />
                                                    
                                                    </a>
                                                </div>
                                                <div class="image-wrapper">
                                                    <a href="#lightbox-image-7">
                                                        <img src={oh7} class="img-fluid" alt="..." />
                                                     
                                                    </a>
                                                </div>
                                                <div class="image-wrapper">
                                                    <a href="#lightbox-image-6">
                                                        <img src={oh6} class="img-fluid" alt="..." />
                                                     
                                                    </a>
                                                </div>
                                            </div>

                                            <div class="gallery-lightboxes">

                                                <div class="image-lightbox" id="lightbox-image-1">
                                                    <div class="image-lightbox-wrapper">
                                                        <a href="#" class="close"></a>
                                                        <a href="#lightbox-image-3" class="arrow-left"></a>
                                                        <a href="#lightbox-image-2" class="arrow-right"></a>
                                                        <img src={oh1} class="img-fluid" alt="..." />
                                                   
                                                    </div>
                                                </div>

                                                <div class="image-lightbox" id="lightbox-image-2">
                                                    <div class="image-lightbox-wrapper">
                                                        <a href="#" class="close"></a>
                                                        <a href="#lightbox-image-1" class="arrow-left"></a>
                                                        <a href="#lightbox-image-3" class="arrow-right"></a>
                                                        <img src={oh2} class="img-fluid" alt="..." />
                                                        
                                                    </div>
                                                </div>

                                                <div class="image-lightbox" id="lightbox-image-3">
                                                    <div class="image-lightbox-wrapper">
                                                        <a href="#" class="close"></a>
                                                        <a href="#lightbox-image-2" class="arrow-left"></a>
                                                        <a href="#lightbox-image-1" class="arrow-right"></a>
                                                        <img src={oh3} class="img-fluid" alt="..." />
                                                    
                                                    </div>
                                                </div>
                                                <div class="image-lightbox" id="lightbox-image-4">
                                                    <div class="image-lightbox-wrapper">
                                                        <a href="#" class="close"></a>
                                                        <a href="#lightbox-image-4" class="arrow-left"></a>
                                                        <a href="#lightbox-image-2" class="arrow-right"></a>
                                                        <img src={oh4} class="img-fluid" alt="..." />
                                                    
                                                    </div>
                                                </div>
                                                <div class="image-lightbox" id="lightbox-image-5">
                                                    <div class="image-lightbox-wrapper">
                                                        <a href="#" class="close"></a>
                                                        <a href="#lightbox-image-1" class="arrow-left"></a>
                                                        <a href="#lightbox-image-2" class="arrow-right"></a>
                                                        <img src={oh7} class="img-fluid" alt="..." />
                                                    
                                                    </div>
                                                </div>
                                                <div class="image-lightbox" id="lightbox-image-6">
                                                    <div class="image-lightbox-wrapper">
                                                        <a href="#" class="close"></a>
                                                        <a href="#lightbox-image-6" class="arrow-left"></a>
                                                        <a href="#lightbox-image-7" class="arrow-right"></a>
                                                        <img src={oh6} class="img-fluid" alt="..." />
                                                    
                                                    </div>
                                                </div>
                                                

                                            </div>
                                        </div>
                                    </div>
                                 
                                </div>
                             
                            </div>
                            <center> <h4>Ohio Campuses</h4> <h4 style={{ color: "#0985dc" }}> 10883 Yankee St, Dayton, OH 45458</h4></center>
                        </div>
                    </div>

                    <br></br>

                </div>



                <div className='col-sm-6'>
                    <div class="container mt-5">
                        <div class="carousel-container position-relative row">
                            <div id="myCarousel1" class="carousel slide" data-ride="carousel">
                                <div class="carousel-inner" style={{height:"18rem"}}>
                                    <div class="carousel-item active" data-slide-number="0">
                                    <img src={vir5} class="d-block w-100" alt="..." data-remote={vir2} data-type="image" data-toggle="lightbox1" data-gallery="example-gallery1" />
                                    </div>
                                    <div class="carousel-item" data-slide-number="1">
                                        <img src={vir5} class="d-block w-100" alt="..." data-remote={vir2} data-type="image" data-toggle="lightbox1" data-gallery="example-gallery1" />
                                    </div>
                                    <div class="carousel-item" data-slide-number="2">
                                        <img src={vir3} class="d-block w-100" alt="..." data-remote={vir3} data-type="image" data-toggle="lightbox1" data-gallery="example-gallery1" />
                                    </div>
                                    <div class="carousel-item" data-slide-number="3">
                                        <img src={vir4} class="d-block w-100" alt="..." data-remote={vir4} data-type="image" data-toggle="lightbox1" data-gallery="example-gallery1" />
                                    </div>
                                    <div class="carousel-item" data-slide-number="4">
                                        <img src={vir5} class="d-block w-100" alt="..." data-remote={vir5} data-type="image" data-toggle="lightbox1" data-gallery="example-gallery1" />
                                    </div>
                                    <div class="carousel-item" data-slide-number="5">
                                        <img src={vir6} class="d-block w-100" alt="..." data-remote={vir6} data-type="image" data-toggle="lightbox1" data-gallery="example-gallery1" />
                                    </div>
                                   
                                    <div class="carousel-item" data-slide-number="7">
                                        <img src={vir7} class="d-block w-100" alt="..." data-remote={vir7} data-type="image" data-toggle="lightbox1" data-gallery="example-gallery1" />
                                    </div>
                                    <div class="carousel-item" data-slide-number="8">
                                        <img src={vir8} class="d-block w-100" alt="..." data-remote={vir8} data-type="image" data-toggle="lightbox1" data-gallery="example-gallery1" />
                                    </div>
                                   
                                </div>
                            </div>


                            <div id="carousel-thumbs2" class="carousel slide" data-ride="carousel">
                                <div class="carousel-inner" style={{height:"3.6rem"}}>
                                    <div class="carousel-item active">
                                        <div class="row mx-0">

                                        <div class="gallery-wrapper">
                                                <div class="image-wrapper">
                                                    <a href="#lightbox-image-11">
                                                    <img src={vir5} class="d-block w-100" alt="..." data-remote={vir2} data-type="image" data-toggle="lightbox1" data-gallery="example-gallery1" />
                                                      
                                                    </a>
                                                </div>
                                                <div class="image-wrapper">
                                                    <a href="#lightbox-image-12">
                                                    <img src={vir5} class="d-block w-100" alt="..." data-remote={vir2} data-type="image" data-toggle="lightbox1" data-gallery="example-gallery1" />
                                                     
                                                    </a>
                                                </div>
                                                <div class="image-wrapper">
                                                    <a href="#lightbox-image-13">
                                                    <img src={vir3} class="d-block w-100" alt="..." data-remote={vir3} data-type="image" data-toggle="lightbox1" data-gallery="example-gallery1" />
                                                       
                                                    </a>
                                                </div>
                                                <div class="image-wrapper">
                                                    <a href="#lightbox-image-14">
                                                    <img src={vir4} class="d-block w-100" alt="..." data-remote={vir4} data-type="image" data-toggle="lightbox1" data-gallery="example-gallery1" />
                                                    
                                                    </a>
                                                </div>
                                                <div class="image-wrapper">
                                                    <a href="#lightbox-image-17">
                                                    <img src={vir5} class="d-block w-100" alt="..." data-remote={vir5} data-type="image" data-toggle="lightbox1" data-gallery="example-gallery1" />
                                                     
                                                    </a>
                                                </div>
                                                <div class="image-wrapper">
                                                    <a href="#lightbox-image-16">
                                                    <img src={vir5} class="d-block w-100" alt="..." data-remote={vir6} data-type="image" data-toggle="lightbox1" data-gallery="example-gallery1" />
                                                     
                                                    </a>
                                                </div>
                                                <div class="image-wrapper">
                                                    <a href="#lightbox-image-18">
                                                    <img src={vir6} class="d-block w-100" alt="..." data-remote={vir6} data-type="image" data-toggle="lightbox1" data-gallery="example-gallery1" />
                                                     
                                                    </a>
                                                </div>
                                                <div class="image-wrapper">
                                                    <a href="#lightbox-image-19">
                                                    <img src={vir7} class="d-block w-100" alt="..." data-remote={vir6} data-type="image" data-toggle="lightbox1" data-gallery="example-gallery1" />
                                                     
                                                    </a>
                                                </div>
                                                <div class="image-wrapper">
                                                    <a href="#lightbox-image-20">
                                                    <img src={vir8} class="d-block w-100" alt="..." data-remote={vir6} data-type="image" data-toggle="lightbox1" data-gallery="example-gallery1" />
                                                     
                                                    </a>
                                                </div>
                                            </div>

                                            <div class="gallery-lightboxes">

                                                <div class="image-lightbox" id="lightbox-image-11">
                                                    <div class="image-lightbox-wrapper">
                                                        <a href="#" class="close"></a>
                                                        <a href="#lightbox-image-13" class="arrow-left"></a>
                                                        <a href="#lightbox-image-12" class="arrow-right"></a>
                                                        <img src={vir5} class="img-fluid" alt="..." />
                                                   
                                                    </div>
                                                </div>

                                                <div class="image-lightbox" id="lightbox-image-12">
                                                    <div class="image-lightbox-wrapper">
                                                        <a href="#" class="close"></a>
                                                        <a href="#lightbox-image-11" class="arrow-left"></a>
                                                        <a href="#lightbox-image-13" class="arrow-right"></a>
                                                        <img src={vir3} class="img-fluid" alt="..." />
                                                        
                                                    </div>
                                                </div>

                                                <div class="image-lightbox" id="lightbox-image-13">
                                                    <div class="image-lightbox-wrapper">
                                                        <a href="#" class="close"></a>
                                                        <a href="#lightbox-image-20" class="arrow-left"></a>
                                                        <a href="#lightbox-image-16" class="arrow-right"></a>
                                                        <img src={vir4} class="img-fluid" alt="..." />
                                                    
                                                    </div>
                                                </div>
                                                <div class="image-lightbox" id="lightbox-image-14">
                                                    <div class="image-lightbox-wrapper">
                                                        <a href="#" class="close"></a>
                                                        <a href="#lightbox-image-14" class="arrow-left"></a>
                                                        <a href="#lightbox-image-16" class="arrow-right"></a>
                                                        <img src={vir5} class="img-fluid" alt="..." />
                                                    
                                                    </div>
                                                </div>
                                                <div class="image-lightbox" id="lightbox-image-16">
                                                    <div class="image-lightbox-wrapper">
                                                        <a href="#" class="close"></a>
                                                        <a href="#lightbox-image-18" class="arrow-left"></a>
                                                        <a href="#lightbox-image-17" class="arrow-right"></a>
                                                        <img src={vir6} class="img-fluid" alt="..." />
                                                    
                                                    </div>
                                                </div>
                                                <div class="image-lightbox" id="lightbox-image-17">
                                                    <div class="image-lightbox-wrapper">
                                                        <a href="#" class="close"></a>
                                                        <a href="#lightbox-image-16" class="arrow-left"></a>
                                                        <a href="#lightbox-image-17" class="arrow-right"></a>
                                                        <img src={vir7} class="img-fluid" alt="..." />
                                                    
                                                    </div>
                                                </div>
                                                <div class="image-lightbox" id="lightbox-image-18">
                                                    <div class="image-lightbox-wrapper">
                                                        <a href="#" class="close"></a>
                                                        <a href="#lightbox-image-19" class="arrow-left"></a>
                                                        <a href="#lightbox-image-17" class="arrow-right"></a>
                                                        <img src={vir8} class="img-fluid" alt="..." />
                                                    
                                                    </div>
                                                </div>

                                            </div>




                                         
                                        </div>
                                    </div>
                                   
                                </div>
                               
                            </div>

                        </div>
                    </div>

                 
                   
                    <center> <h4>Virginia Campuses</h4> <h4 style={{ color: "#0985dc" }}>44258 Mercure Cir Suite 120 A, Sterling, VA 20166</h4></center>

                </div>


                <div className='col-sm-6'>
                    <div class="container mt-5">
                        <div class="carousel-container position-relative row">
                            <div id="myCarousel1" class="carousel slide" data-ride="carousel">
                                <div class="carousel-inner" style={{height:"18rem"}}>
                                    <div class="carousel-item active" data-slide-number="0">
                                    <img src={vir5} class="d-block w-100" alt="..." data-remote={vir2} data-type="image" data-toggle="lightbox1" data-gallery="example-gallery1" />
                                    </div>
                                    <div class="carousel-item" data-slide-number="1">
                                        <img src={vir5} class="d-block w-100" alt="..." data-remote={vir2} data-type="image" data-toggle="lightbox1" data-gallery="example-gallery1" />
                                    </div>
                                    <div class="carousel-item" data-slide-number="2">
                                        <img src={vir3} class="d-block w-100" alt="..." data-remote={vir3} data-type="image" data-toggle="lightbox1" data-gallery="example-gallery1" />
                                    </div>
                                    <div class="carousel-item" data-slide-number="3">
                                        <img src={vir4} class="d-block w-100" alt="..." data-remote={vir4} data-type="image" data-toggle="lightbox1" data-gallery="example-gallery1" />
                                    </div>
                                    <div class="carousel-item" data-slide-number="4">
                                        <img src={vir5} class="d-block w-100" alt="..." data-remote={vir5} data-type="image" data-toggle="lightbox1" data-gallery="example-gallery1" />
                                    </div>
                                    <div class="carousel-item" data-slide-number="5">
                                        <img src={vir6} class="d-block w-100" alt="..." data-remote={vir6} data-type="image" data-toggle="lightbox1" data-gallery="example-gallery1" />
                                    </div>
                                   
                                    <div class="carousel-item" data-slide-number="7">
                                        <img src={vir7} class="d-block w-100" alt="..." data-remote={vir7} data-type="image" data-toggle="lightbox1" data-gallery="example-gallery1" />
                                    </div>
                                    <div class="carousel-item" data-slide-number="8">
                                        <img src={vir8} class="d-block w-100" alt="..." data-remote={vir8} data-type="image" data-toggle="lightbox1" data-gallery="example-gallery1" />
                                    </div>
                                   
                                </div>
                            </div>


                            <div id="carousel-thumbs2" class="carousel slide" data-ride="carousel">
                                <div class="carousel-inner" style={{height:"3.6rem"}}>
                                    <div class="carousel-item active">
                                        <div class="row mx-0">

                                        <div class="gallery-wrapper">
                                                <div class="image-wrapper">
                                                    <a href="#lightbox-image-11">
                                                    <img src={vir5} class="d-block w-100" alt="..." data-remote={vir2} data-type="image" data-toggle="lightbox1" data-gallery="example-gallery1" />
                                                      
                                                    </a>
                                                </div>
                                                <div class="image-wrapper">
                                                    <a href="#lightbox-image-12">
                                                    <img src={vir5} class="d-block w-100" alt="..." data-remote={vir2} data-type="image" data-toggle="lightbox1" data-gallery="example-gallery1" />
                                                     
                                                    </a>
                                                </div>
                                                <div class="image-wrapper">
                                                    <a href="#lightbox-image-13">
                                                    <img src={vir3} class="d-block w-100" alt="..." data-remote={vir3} data-type="image" data-toggle="lightbox1" data-gallery="example-gallery1" />
                                                       
                                                    </a>
                                                </div>
                                                <div class="image-wrapper">
                                                    <a href="#lightbox-image-14">
                                                    <img src={vir4} class="d-block w-100" alt="..." data-remote={vir4} data-type="image" data-toggle="lightbox1" data-gallery="example-gallery1" />
                                                    
                                                    </a>
                                                </div>
                                                <div class="image-wrapper">
                                                    <a href="#lightbox-image-17">
                                                    <img src={vir5} class="d-block w-100" alt="..." data-remote={vir5} data-type="image" data-toggle="lightbox1" data-gallery="example-gallery1" />
                                                     
                                                    </a>
                                                </div>
                                                <div class="image-wrapper">
                                                    <a href="#lightbox-image-16">
                                                    <img src={vir5} class="d-block w-100" alt="..." data-remote={vir6} data-type="image" data-toggle="lightbox1" data-gallery="example-gallery1" />
                                                     
                                                    </a>
                                                </div>
                                                <div class="image-wrapper">
                                                    <a href="#lightbox-image-18">
                                                    <img src={vir6} class="d-block w-100" alt="..." data-remote={vir6} data-type="image" data-toggle="lightbox1" data-gallery="example-gallery1" />
                                                     
                                                    </a>
                                                </div>
                                                <div class="image-wrapper">
                                                    <a href="#lightbox-image-19">
                                                    <img src={vir7} class="d-block w-100" alt="..." data-remote={vir6} data-type="image" data-toggle="lightbox1" data-gallery="example-gallery1" />
                                                     
                                                    </a>
                                                </div>
                                                <div class="image-wrapper">
                                                    <a href="#lightbox-image-20">
                                                    <img src={vir8} class="d-block w-100" alt="..." data-remote={vir6} data-type="image" data-toggle="lightbox1" data-gallery="example-gallery1" />
                                                     
                                                    </a>
                                                </div>
                                            </div>

                                            <div class="gallery-lightboxes">

                                                <div class="image-lightbox" id="lightbox-image-11">
                                                    <div class="image-lightbox-wrapper">
                                                        <a href="#" class="close"></a>
                                                        <a href="#lightbox-image-13" class="arrow-left"></a>
                                                        <a href="#lightbox-image-12" class="arrow-right"></a>
                                                        <img src={vir5} class="img-fluid" alt="..." />
                                                   
                                                    </div>
                                                </div>

                                                <div class="image-lightbox" id="lightbox-image-12">
                                                    <div class="image-lightbox-wrapper">
                                                        <a href="#" class="close"></a>
                                                        <a href="#lightbox-image-11" class="arrow-left"></a>
                                                        <a href="#lightbox-image-13" class="arrow-right"></a>
                                                        <img src={vir3} class="img-fluid" alt="..." />
                                                        
                                                    </div>
                                                </div>

                                                <div class="image-lightbox" id="lightbox-image-13">
                                                    <div class="image-lightbox-wrapper">
                                                        <a href="#" class="close"></a>
                                                        <a href="#lightbox-image-20" class="arrow-left"></a>
                                                        <a href="#lightbox-image-16" class="arrow-right"></a>
                                                        <img src={vir4} class="img-fluid" alt="..." />
                                                    
                                                    </div>
                                                </div>
                                                <div class="image-lightbox" id="lightbox-image-14">
                                                    <div class="image-lightbox-wrapper">
                                                        <a href="#" class="close"></a>
                                                        <a href="#lightbox-image-14" class="arrow-left"></a>
                                                        <a href="#lightbox-image-16" class="arrow-right"></a>
                                                        <img src={vir5} class="img-fluid" alt="..." />
                                                    
                                                    </div>
                                                </div>
                                                <div class="image-lightbox" id="lightbox-image-16">
                                                    <div class="image-lightbox-wrapper">
                                                        <a href="#" class="close"></a>
                                                        <a href="#lightbox-image-18" class="arrow-left"></a>
                                                        <a href="#lightbox-image-17" class="arrow-right"></a>
                                                        <img src={vir6} class="img-fluid" alt="..." />
                                                    
                                                    </div>
                                                </div>
                                                <div class="image-lightbox" id="lightbox-image-17">
                                                    <div class="image-lightbox-wrapper">
                                                        <a href="#" class="close"></a>
                                                        <a href="#lightbox-image-16" class="arrow-left"></a>
                                                        <a href="#lightbox-image-17" class="arrow-right"></a>
                                                        <img src={vir7} class="img-fluid" alt="..." />
                                                    
                                                    </div>
                                                </div>
                                                <div class="image-lightbox" id="lightbox-image-18">
                                                    <div class="image-lightbox-wrapper">
                                                        <a href="#" class="close"></a>
                                                        <a href="#lightbox-image-19" class="arrow-left"></a>
                                                        <a href="#lightbox-image-17" class="arrow-right"></a>
                                                        <img src={vir8} class="img-fluid" alt="..." />
                                                    
                                                    </div>
                                                </div>

                                            </div>




                                         
                                        </div>
                                    </div>
                                   
                                </div>
                               
                            </div>

                        </div>
                    </div>

                 
                   
                    <center> <h4>Virginia Campuses</h4> <h4 style={{ color: "#0985dc" }}>44258 Mercure Cir Suite 120 A, Sterling, VA 20166</h4></center>

                </div>
            </div>


            <Footer />

        </>
    )
}
