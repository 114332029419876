import React from 'react'
import Footer from '../Header/Footer'
import Header from '../Header/Header'
function Cookies() {
    return (
        <div>

            <div>
                <Header />

                <section class="breadcrumb-area banner-1" data-background="images/banner/9.jpg">
                    <div class="text-block">
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-12 v-center">
                                    <div class="bread-inner">
                                        <div class="bread-menu">
                                            <ul>
                                                <li><a href="#">Binary Logic</a></li>
                                                <li><a href="#">Cookies Policy</a></li>
                                            </ul>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="about-agency pad-tb block-1">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12">

                                <div class="pvcpolcy">
                                    <h4 class="mb10"> Cookies Policy</h4>
                                    <p class="mb10">A. This Cookies Policy explains what Cookies are and how We use them. You should read this policy so You can understand what type of cookies We use, or the information We collect using Cookies and how that information is used. This Cookies Policy has been created with the help of the TermsFeed Cookies Policy Generator.  </p>

                                    <p>B.Cookies do not typically contain any information that personally identifies a user, but personal information that we store about You may be linked to the information stored in and obtained from Cookies. For further information on how We use, store and keep your personal data secure, see our Privacy Policy.    </p>

                                    <p>C.We do not store sensitive personal information, such as mailing addresses, account passwords, etc. in the Cookies We use.
                                        Interpretation and Definitions
                                        Interpretation     </p>

                                    <h4 class="mb10 mt30"> Definitions
                                        For the purposes of this Cookies Policy:</h4>

                                    <p class="mb10">  • Company (referred to as either "the Company", "We", "Us" or "Our" in this Cookies Policy) refers to Binary Logic IT LLC, 44258 Mercure Cir Suite 120 A, Sterling, VA 20166.</p>
                                    <p class="mb10">  • Cookies means small files that are placed on Your computer, mobile device or any other device by a website, containing details of your browsing history on that website among its many uses.</p>
                                    <p class="mb10">  •  Website refers to Binary Logic IT LLC, accessible from binary.organicdeal.in
                                        • You means the individual accessing or using the Website, or a company, or any legal entity on behalf of which such individual is accessing or using the Website, as applicable</p>


                                    <strong>The use of the Cookies</strong><br />
                                    <strong>Type of Cookies We Use</strong>


                                    <p class="mb10">Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies remain on your personal computer or mobile device when You go offline, while Session Cookies are deleted as soon as You close your web browser.  </p>
                                    <p class="mb10">We use both session and persistent Cookies for the purposes set out below  </p>

                                    <strong>Necessary / Essential Cookies</strong><br />
                                    <strong>Type: Session Cookies</strong><br />
                                    <strong>Administered by: Us</strong><br />


                                    <p class="mb10">   <strong>Purpose:</strong>  These Cookies are essential to provide You with services available through the Website and to enable You to use some of its features. They help to authenticate users and prevent fraudulent use of user accounts. Without these Cookies, the services that You have asked for cannot be provided, and We only use these Cookies to provide You with those services. </p>

                                    <strong>Functionality Cookies</strong><br />
                                    <strong>Type: Persistent Cookies</strong><br />
                                    <strong>Administered by: Us</strong><br />
                                    <p class="mb10">   <strong>Purpose:</strong>   These Cookies allow us to remember choices You make when You use the Website, such as remembering your login details or language preference. The purpose of these Cookies is to provide You with a more personal experience and to avoid You having to re-enter your preferences every time You use the Website.
                                        Your Choices Regarding Cookies
                                    </p>
                                    <p class="mb10">If You prefer to avoid the use of Cookies on the Website, first You must disable the use of Cookies in your browser and then delete the Cookies saved in your browser associated with this website. You may use this option for preventing the use of Cookies at any time. </p>
                                    <p class="mb10">If You do not accept Our Cookies, You may experience some inconvenience in your use of the Website and some features may not function properly. </p>
                                    <p class="mb10">If You'd like to delete Cookies or instruct your web browser to delete or refuse Cookies, please visit the help pages of your web browser. </p>
                                    
                                    
                                    <h4 class="mb10 mt30">Contact Us</h4>
                                    <p class="mb10">If you have any questions about this Cookies Policy, You can contact us: </p>
                                    <p class="mb10">By email:<a href='www.info@binarylogicit.com'> info@binarylogicit.com</a> </p>
                                    <p class="mb10">By visiting this page on our website:<a href='https://binary.organicdeal.in/ContactUs'> https://binary.organicdeal.in/ContactUs</a> </p>
                                    <p class="mb10">By phone number:(888) 333-9666 </p>
                                  
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <hr></hr>

                <Footer />
            </div>

        </div>
    )
}

export default Cookies