import React from 'react'
import Footer from './Footer'
import Header from './Header'

export const Binarytraining = () => {
    return (
        <div>

            <Header />

            <section class="hero-section">
                <div class="text-block">
                    <div class="container">
                        <div class="row justify-content-center">
                            <div class="col-lg-8 niwax" data-rellax-speed="-3">
                                <div class="header-heading text-center">
                                    <h1 class="wow fadeInUp text-effect-1" data-wow-delay=".2s">TECH TRAINING</h1>
                                    <p class="wow fadeInUp" data-wow-delay=".4s">Upskill, Train & Develop with tech talent.</p>
                                    <a href="#" class="niwax-btn2 wow zoomInDown" data-wow-delay=".6s"> Our catalogue features courses and certifications in tech,
                                        across software development, cyber security, data science and cloud. We believe that learning should be an interactive and
                                        engaging experience.<i class="fas fa-chevron-right fa-icon"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="service-section-prb pad-tb">
                <div class="container">
                    <div class="row upset">
                        <div data-tilt data-tilt-max="5" data-tilt-speed="1000" class="col-lg-6-cus wow fadeInUp" data-wow-delay=".2s">
                            <div class="service-sec-brp srvc-bg-nx bg-gradient13 text-w">
                                <h4 class="mb10">INTEGRATED SERVICES</h4>
                                <p>Lorem Ipsum is text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500.</p>
                                <a href="javascript:void(0)" class="mt20 link-prb">Learn More <i class="fas fa-chevron-right fa-icon"></i></a>
                            </div>
                        </div>
                        <div data-tilt data-tilt-max="5" data-tilt-speed="1000" class="col-lg-3-cus wow fadeInUp" data-wow-delay=".4s">
                            <div class="service-sec-list srvc-bg-nx srcl1">
                                <img src="images/icons/development.svg" alt="service" />
                                <h5 class="mb10">Web Development</h5>
                                <ul class="-service-list">
                                    <li> <a href="#">PHP</a> </li>
                                    <li> <a href="#"><strong>.</strong>Net</a> </li>
                                    <li> <a href="#">Java</a> </li>
                                    <li> <a href="#">Joomla</a></li>
                                </ul>
                                <p>Lorem Ipsum is text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500.</p>
                            </div>
                        </div>
                        <div data-tilt data-tilt-max="5" data-tilt-speed="1000" class="col-lg-3-cus wow fadeInUp" data-wow-delay=".6s">
                            <div class="service-sec-list srvc-bg-nx srcl2">
                                <img src="images/icons/ecommerce.svg" alt="service" />
                                <h5 class="mb10">Ecommerce Development</h5>
                                <ul class="-service-list">
                                    <li> <a href="#">Magento </a> </li>
                                    <li> <a href="#">WP</a> </li>
                                    <li> <a href="#">Shopify </a> </li>
                                    <li> <a href="#">Joomla</a></li>
                                </ul>
                                <p>Lorem Ipsum is text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500.</p>
                            </div>
                        </div>
                        <div data-tilt data-tilt-max="5" data-tilt-speed="1000" class="col-lg-3-cus mt30- wow fadeInUp" data-wow-delay=".8s">
                            <div class="service-sec-list srvc-bg-nx srcl3">
                                <img src="images/icons/app.svg" alt="service" />
                                <h5 class="mb10">Mobile App Development</h5>
                                <ul class="-service-list">
                                    <li> <a href="#">iPhone </a> </li>
                                    <li> <a href="#">Android</a> </li>
                                    <li> <a href="#">Cross Platform </a></li>
                                </ul>
                                <p>Lorem Ipsum is text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500.</p>
                            </div>
                        </div>
                        <div data-tilt data-tilt-max="5" data-tilt-speed="1000" class="col-lg-3-cus mt30- wow fadeInUp" data-wow-delay="1s">
                            <div class="service-sec-list srvc-bg-nx srcl4">
                                <img src="images/icons/tech.svg" alt="service" />
                                <h5 class="mb10">Trending Technologies</h5>
                                <ul class="-service-list">
                                    <li> <a href="#">React.JS </a> </li>
                                    <li> <a href="#">Node.JS </a> </li>
                                    <li> <a href="#"> Angular.JS </a></li>
                                </ul>
                                <p>Lorem Ipsum is text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500.</p>
                            </div>
                        </div>
                        <div data-tilt data-tilt-max="5" data-tilt-speed="1000" class="col-lg-6-cus mt30- wow fadeInUp" data-wow-delay="1.2s">
                            <div class="service-sec-list srvc-bg-nx srcl5">
                                <img src="images/icons/seo.svg" alt="service" />
                                <h5 class="mb10">Digital Marketing</h5>
                                <ul class="-service-list">
                                    <li> <a href="#">SEO </a> </li>
                                    <li> <a href="#">SMO </a> </li>
                                    <li> <a href="#">PPC </a></li>
                                    <li> <a href="#">PPC </a></li>
                                </ul>
                                <p>Lorem Ipsum is text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500.</p>
                            </div>
                        </div>
                    </div>
                    <div class="-cta-btn mt70">
                        <div class="free-cta-title v-center zoomInDown wow" data-wow-delay="1.4s">
                            <p>Hire a <span>Dedicated Developer & Deploy</span></p>
                            <a href="#" class="btn-main bg-btn2 lnk">Hire Now<i class="fas fa-chevron-right fa-icon"></i><span class="circle"></span></a>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />

        </div>
    )
}
