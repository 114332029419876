import React from 'react'
import Footer from '../Footer'
import Header from '../Header'
import Meritize from './Meritize.png'
import student from './student.jpg'

function Tution() {
    return (
        <>
            <Header />
            <br></br>
            <br></br>
            <br></br>
           


            <div class="container-fluid p-0">
                    <section class="row no-gutters align-items-center">
                        <div class="col-12 text-center d-flex">
                            <img class="img-fluid of col-lg-12 fff posterImage" src={student} alt="img1" />
                            <h1 class="w-50 position-absolute  " style={{marginTop:"180px",fontSize:"4rem"}}>Tuition</h1>
                           
                        </div>
                    </section>
                </div>




            <section className="service p-4" style={{background:"#ebd87d"}}>
                <div className="container">
                    <div className="row">

                        <div className="col-lg-8 block-1">
                            <div className="common-heading text-l pl25">

                               
                                <p>
                                    BLIT has teamed up with the Meritize platform* to offer our student’s a unique financing option. Meritize works with students, educators, and employers to help people excel in skills-based careers.
                                    Meritize knows you are more than your credit score, and that is why they look at your past achievements to potentially enhance your loan options.
                                    A few reasons why the Meritize Platform is a great option for students include:.{" "}
                                </p>
                                <h5 className="mt20 mb10">BLIT PROVIDE</h5>
                                <ul className="list-style-">
                                    <li className='text-black'>Fixed and variable interest rates available** Flexible payment options, including deferring full principal and interest payments until 3 months after you complete your training.</li>
                                    <li className='text-black'>
                                    Customer support available 6 days a week.
                                    </li>
                                    <li className='text-black'>0.25% interest rate deduction if you sign up for autopay***</li>
                                   
                                </ul>
                                <h5 className="mt20 mb10">Checking your loan options with Meritize has never been easier. You can fill out a short application to check your loan options without impacting your credit score.</h5>
                                <h5 className="mt20 mb10">If loan options are available to you, Meritize will request your merit information to finalize your application. This could be in the form of.</h5>
                                <ul className="list-style-">
                                    <li className='text-black'>Academic Experience (Official or unofficial transcript or HSE certification).</li>
                                    <li className='text-black'>
                                    Military Experience (DD214).
                                    </li>
                                    
                                   
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="image-block upset bg-shape">
                                <img
                                    src={Meritize}
                                    alt="image"
                                    className="img-fluid"
                                    style={{marginTop:"170px"}}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>




       
            <Footer />
        </>
    )
}

export default Tution
