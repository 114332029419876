import React from 'react'
import Footer from '../Footer'
import Header from '../Header'

function GetStart() {
    return (
        <div>
            <Header />
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <section className="about-freelance pad-tb"  >
                <div className="container">
                    <div className="row ">
                        <div className="col-lg-6 mt-4 ">
                            <div className="common-heading text-l">
                                <span style={{ fontSize: "2.8em" }} >  Start the application process </span>
                                <span style={{ fontSize: "2.8em" }}>now!</span>
                                <h3>No coding knowledge is required to get started.</h3>
                                <div className="servie-key-points">

                                    <ul className="key-points mt30">

                                        <li>In our free aptitude test, you're using basic problem-solving ability—the skills we build upon during the coding bootcamp.</li>

                                        <li>It's the proven foundation for becoming a successful software engineer or web developer. Our approach has worked for thousands of students nationwide.</li>
                                        <h5>Go ahead; take the test. You've got this.</h5>


                                    </ul>
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-6">


                            <div className="col-lg-12 v-center" style={{ background: "darkgray", padding: "14px", borderRadius: "10px" }}>
                                <div className="common-heading text-l">

                                    <h4 className="text-center" style={{ color: "darkblue" }}>
                                        Take the Aptitude Test!
                                    </h4>
                                </div>
                                <div className="form-block" >
                                    <form action="#" method="post" name="feedback-form" >
                                        <div className="fieldsets row">
                                            <div className="col-md-6">
                                                <input type="text" placeholder="Full Name" name="name" />
                                            </div>

                                            <div className="col-md-6">
                                                <input type="text" placeholder="Last Name" name="name" />
                                            </div>
                                            <div className="col-md-12">
                                                <input
                                                    type="email"
                                                    placeholder="Email Address"
                                                    name="email"
                                                />
                                            </div>
                                        </div>
                                        <div className="fieldsets row">

                                            <div className="col-md-6">
                                                <input type="text" placeholder="ZipCode" name="subject" />
                                            </div>
                                            <div className="col-md-6">
                                                <input
                                                    type="text"
                                                    placeholder="Phone Number"
                                                    name="phone"
                                                />
                                            </div>
                                        </div>
                                        <div className="fieldsets">
                                            <div class="form-group col-md-12 ppp">
                                                <label>Where are you in your journey to learn more about a career in software development?</label>

                                                <select id="inputState" class="form-control">
                                                    <option selected>-Select-</option>
                                                    <option>...</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="custom-control custom-checkbox">
                                            <input
                                                type="checkbox"
                                                className="custom-control-input"
                                                id="customCheck"
                                                name="example1"
                                                defaultChecked="checked"
                                            />
                                            <label className="custom-control-label" htmlFor="customCheck">
                                                By submitting this form, you agree to receive admissions and marketing communications via email or automated text, and you agree to our Privacy Policy and Site Terms.

                                            </label>
                                        </div>
                                        <div className="fieldsets mt20">
                                            {" "}
                                            <button
                                                type="submit"
                                                name="submit"
                                                className="lnk btn-main bg-btn"
                                            >
                                                Get Started <i className="fas fa-chevron-right fa-icon" />
                                                <span className="circle" />
                                            </button>{" "}
                                        </div>
                                        <p className="trm">
                                            <i className="fas fa-lock" />
                                            We hate spam, and we respect your privacy.
                                        </p>
                                    </form>
                                </div>
                            </div>


                        </div>

                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default GetStart
