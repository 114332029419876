import React from 'react'
import Footer from '../Footer'
import Header from '../Header'
import corse from './corse.jpg'

function Forindividual2() {
    return (
        <div>
            <Header />
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <div >

                <div class="container-fluid p-0">
                    <section class="row no-gutters align-items-center">
                        <div class="col-12 text-center d-flex">
                            <img class="img-fluid of col-lg-12 fff posterImage" src={corse} alt="img1" />
                            <h1 class="w-50 position-absolute    pr-5 poster">Find Your Course</h1>
                            <div className="col-lg-6  position-absolute  mr-5 pr-5" >
                                <div>
                                    <h1 className="poster1"> <b>WE'RE  HERE TO HELP YOU OUT</b></h1>

                                    <h1 className='textBlack'>Wondering how to choose from our variety of courses, classes, workshops, and events? BLIT Admissions team is here to assist.</h1>

                                </div>


                                <div class="row" style={{marginLeft:"105px",marginTop:"80px"}}>
                                    <div class="col-sm-6">
                                        <div class="card " style={{background:"lightgoldenrodyellow"}}>
                                            <div class="card-body">
                                                <div className='d-flex'>
                                                <img src="images/icons/call.svg " className='phoneIcon' />
                                                <h6 class="card-title text-l">Call Us At <br/>+1 (917) 722-0237</h6>
                                                </div>
                                               
                                                <p class="card-text text-l">Talk to a member of our
                                                    Admissions team right away.</p>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="card ">
                                            <div class="card-body" style={{background:"lightgoldenrodyellow"}}>
                                                <h6 class="card-title text-l">
                                                    <i class="fa fa-file-text-o" aria-hidden="true" ></i>
                                                   
                                                   &nbsp; Get Custom Advice</h6>
                                                <p class="card-text text-l ">Fill out the form below, and we'll
                                                    give you a ring to talk through
                                                    your options.</p><p style={{display:"none"}}>.</p>

                                            </div>
                                        </div>
                                    </div>
                                </div>





                            </div>
                        </div>
                    </section>
                </div>




                <section className="about-lead-gen">



                    <section className="about-freelance pad-tb"  >
                        <div className="container">
                            <div className="row ">

                                <div className="col-lg-12">


                                    <div className="col-lg-12 " style={{ padding: "14px" }}>
                                        <div className="common-heading text-l">

                                            <h4 className="" style={{ color: "Black" }}>
                                                Contact Information
                                            </h4>
                                        </div>
                                        <div className="form-block" >
                                            <form action="#" method="post" name="feedback-form" >
                                                <div className="fieldsets row">
                                                    <div className="col-md-6">
                                                        <input type="text" placeholder="Your Name" name="name" />
                                                    </div>

                                                    <div className="col-md-6">
                                                        <input type="text" placeholder="Your Email" name="name" />
                                                    </div>

                                                </div>

                                                <div className="fieldsets row">
                                                    <div className="col-md-6">
                                                        <input type="text" placeholder="Your Phone Number" name="name" />
                                                    </div>

                                                    <div className="col-md-6">

                                                    </div>

                                                </div>




                                                <div className="col-lg-6 mt-4 ">
                                                    <div className="common-heading text-l">


                                                        <span className='text-black BlitText'>How Would learning at BLIT fit into your Schedule?.</span>
                                                        <div>

                                                            <div className='d-flex'>
                                                                <input type="radio" style={{ width: "11px" }} name="fav_language" value="HTML" />
                                                                <p className='mt-2 ml-2'>I'd learn on the side (nights and weekends).</p>
                                                            </div>

                                                            <div className='d-flex'>
                                                                <input type="radio" style={{ width: "11px" }} name="fav_language" value="HTML" />
                                                                <p className='mt-2 ml-2'>I'd consider quitting my job to pursue a new path.</p>
                                                            </div>
                                                            <div className='d-flex'>
                                                                <input type="radio" style={{ width: "11px" }} name="fav_language" value="HTML" />
                                                                <p className='mt-2 ml-2'>I'm not sure (somewhere in-between).</p>
                                                            </div>



                                                        </div>

                                                    </div>

                                                </div>
                                                <hr />
                                                <div className="col-lg-6 mt-4 ">
                                                    <div className="common-heading text-l">


                                                        <span className='text-black BlitText'>Where would you like to learn?</span>
                                                        <div>

                                                            <div className='d-flex'>
                                                                <input type="radio" style={{ width: "11px" }} name="fav_language" value="HTML" />
                                                                <p className='mt-2 ml-2'>Online</p>
                                                            </div>

                                                            <div className='d-flex'>
                                                                <input type="radio" style={{ width: "11px" }} name="fav_language" value="HTML" />
                                                                <p className='mt-2 ml-2'>On Campus</p>
                                                            </div>


                                                            <div className="form-group">

                                                                <span className='text-black BlitText' style={{ fontSize: "24px" }}><b>Anything else you'd like to tell us?</b></span>
                                                                <textarea
                                                                    id="message"
                                                                    rows={5}
                                                                    placeholder="Enter your message"
                                                                    required=""
                                                                    defaultValue={""}
                                                                />
                                                                <div className="help-block with-errors" />
                                                            </div>

                                                        </div>

                                                    </div>

                                                </div>


                                                <div className="custom-control custom-checkbox">
                                                    <input
                                                        type="checkbox"
                                                        className="custom-control-input"
                                                        id="customCheck"
                                                        name="example1"
                                                        defaultChecked="checked"
                                                    />
                                                    <label className="custom-control-label" htmlFor="customCheck">
                                                        By submitting this form, you agree to receive admissions and marketing communications via email or automated text, and you agree to our Privacy Policy and Site Terms.

                                                    </label>
                                                </div>
                                                <div className="fieldsets mt20">
                                                    {" "}
                                                    <button
                                                        className='p-2 text-white'
                                                        type="button"
                                                        name="submit"
                                                        style={{ width: "140px", background: "red" }}
                                                    >
                                                        Submit <i className="fas fa-chevron-right fa-icon" />
                                                        <span className="circle" />
                                                    </button>{" "}
                                                </div>

                                            </form>
                                        </div>
                                    </div>


                                </div>

                            </div>
                        </div>
                    </section>





                </section>
            </div>
            <Footer />
        </div>
    )
}

export default Forindividual2
