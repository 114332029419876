import React, { useState, useEffect } from 'react'
import axios from 'axios';
import Footer from '../Footer'
import Header from '../Header'

function ApplyForm() {


    const [FName, setfNAME] = useState("");
    const [LName, setlName] = useState("");
    const [Email, setEmails] = useState("");

    const [Mobile, setMobiles] = useState("");
    const [City, setCitys] = useState("");

    const [Country, setCountrys] = useState([]);
    const [ReferralName, setReferralNames] = useState([]);
    const auth = localStorage.getItem('loginToken')


    const SkillId = localStorage.getItem('skill6')

    const LanguageId = localStorage.getItem('skill7')


    const [Skills, setSkills] = useState([]);
    const skill = async () => {
        const { data } = await axios.get('https://binaryadmin.organicdeal.in/api/InqueryAPI/Skill');
        setSkills(data.resut);
    }
    useEffect(() => {
        skill();
    }, []);


    const [Languages, setLanguages] = useState([]);
    const language = async () => {
        const { data } = await axios.get('https://binaryadmin.organicdeal.in/api/InqueryAPI/Language');
        setLanguages(data.resut);
    }
    useEffect(() => {
        language();
    }, [])


    function applynowsubmit() {

        console.warn({ FName, LName, Email, Mobile, City, Country, SkillId, LanguageId, ReferralName });

        let data = { FName, LName, Email, Mobile, City, Country, SkillId, LanguageId, ReferralName }

        fetch("https://binaryadmin.organicdeal.in/api/InqueryAPI/LetConnect",
            {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${auth}`,
                    "Content-Type": "application/json",

                },
                body: JSON.stringify(data)
            }).then((result) => {
                console.warn("result", result);
            })
    }


    return (
        <div>
            <Header />
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <section id="apply">
                <div className="col-lg-12 v-center container mx-auto p-4 ">
                    <div className="form-block formcover shadow bg-info">
                        <h4>Apply Now </h4>
                        <form
                            id="contactForm"
                            data-bs-toggle="validator"
                            className="shake mt30"
                        >
                            <div className="row">

                                <div className="form-group col-sm-6">
                                    <label className='text-black'> Full Name:</label>
                                    <input

                                        value={FName} onChange={(e) => { setfNAME(e.target.value) }}
                                        type="text"
                                        id="name"
                                        placeholder="Enter Your First Name"
                                        required=""
                                        data-error="Please fill Out"
                                    />
                                    <div className="help-block with-errors" />
                                </div>
                                <div className="form-group col-sm-6">
                                    <label className='text-black'> Last Name:</label>
                                    <input

                                        value={LName} onChange={(e) => { setlName(e.target.value) }}
                                        type="text"
                                        placeholder="Enter Your Last Name"
                                        required=""
                                    />
                                    <div className="help-block with-errors" />
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group col-sm-6">
                                    <label className='text-black'>Your Email:</label>
                                    <input

                                        value={Email} onChange={(e) => { setEmails(e.target.value) }}
                                        type="text"
                                        id="name"
                                        placeholder="Enter Your Email"
                                        required=""
                                        data-error="Please fill Out"
                                    />
                                    <div className="help-block with-errors" />
                                </div>
                                <div className="form-group col-sm-6">
                                    <label className='text-black'> Phone Number:</label>
                                    <input

                                        value={Mobile} onChange={(e) => { setMobiles(e.target.value) }}
                                        type="text"
                                        placeholder="Enter Your Phone Number"
                                        required=""
                                    />

                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group col-sm-6">
                                    <label className='text-black'> City:</label>
                                    <input

                                        value={City} onChange={(e) => { setCitys(e.target.value) }}
                                        type="text"

                                        placeholder="Enter Your City"
                                        required=""
                                        data-error="Please fill Out"
                                    />
                                    <div className="help-block with-errors" />
                                </div>
                                <div className="form-group col-sm-6">
                                    <label className='text-black'> Country:</label>
                                    <input

                                        value={Country} onChange={(e) => { setCountrys(e.target.value) }}
                                        type="text"
                                        placeholder="Enter Your Country"
                                        required=""
                                    />
                                    <div className="help-block with-errors" />
                                </div>
                            </div>
                            <div className="row">

                                <div className="form-group col-sm-6">
                                    <label className='text-black'>Select Program</label>
                                    <select name="Dtype" id="Dtype" required="">
                                        <option value="web">Select--</option>


                                        {Skills?.map((item, index) => {

                                            return (
                                                <>

                                                    <option value="Id:1" key={item.Id}>{item.Skills}</option>
                                                    {localStorage.setItem('skill6', item.Id)}
                                                </>
                                            )
                                        })}
                                    </select>
                                    <div className="help-block with-errors" />
                                </div>
                                <div className="form-group col-sm-6">
                                    <label className='text-black'>Do You Speak any Other language than english</label>
                                    <select name="Dtype" id="Dtype" required="">
                                        <option value="">Select--</option>
                                        {Languages?.map((item, index) => {

                                            return (
                                                <>

                                                    <option value="Id:1" key={item.Id}>{item.Languages}</option>
                                                    {localStorage.setItem('skill7', item.Id)}
                                                </>
                                            )
                                        })}

                                    </select>
                                    <div className="help-block with-errors" />
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group col-sm-6">
                                    <label className='text-black'>How did you hear about us?</label>
                                    <select name="Dtype" id="Dtype" required="">
                                        <option value="">Select--</option>
                                        {Languages?.map((item, index) => {

                                            return (
                                                <>

                                                    <option value="Id:1" key={item.Id}>{item.Languages}</option>
                                                </>
                                            )
                                        })}
                                    </select>
                                    <div className="help-block with-errors" />
                                </div>
                                <div className="form-group col-sm-6">
                                    <label className='text-black'>Referral's name (if yes), ignore if not referred</label>
                                    <input

                                        value={ReferralName} onChange={(e) => { setReferralNames(e.target.value) }}
                                        type="text"
                                        placeholder=""
                                        required=""
                                    />
                                    <div className="help-block with-errors" />
                                </div>
                            </div>


                            <button
                                type="button"
                                id="form-submit"
                                className="btn btn-main col-sm-2 bg-btn3 text-white"

                                disabled={!FName  } onClick={() => applynowsubmit()}
                            >
                                Submit
                            </button>


                        </form>
                    </div>
                </div>
            </section>
            <Footer />

        </div>
    )
}

export default ApplyForm
