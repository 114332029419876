import React from 'react'
import Footer from './Footer'
import Header from './Header'
import about from './Mains/about.png'
import culture from './Mains/culture.gif'
import vision12 from './Mains/vision12.png'
import mission from './Mains/mission.png'
export const Binary = () => {
    return (
        <div>

            <Header />




            <div>


                <div class="container-fluid pb-4">
                    <section class="row no-gutters align-items-center">
                        <div class="col-12 text-center d-flex">
                            <img class="img-fluid of col-lg-12 ppp binaryimg binaryimg1" src={about} alt="img1"  />
                            <h1 class="w-50 position-absolute binarys binarys1" >The Binary Difference</h1>

                        </div>
                    </section>
                </div>
                <section className="why-choos-lg">
                    <div className="container">
                        <div className="row">


                        </div>
                    </div>
                </section>
                <section className="why-choos-lg ">
                    <div className="container mt-4">
                        <div className="row">
                            <div className="col-lg-6">
                                <div class="header-heading">

                                    <h1 class="wow fadeInUp text-effect-2 " data-wow-delay=".2s">Our Culture.</h1>
                                    <h2 className="mb20">At BLIT, our core values that align, engage and inspire us.</h2>
                                    <p class="text-black wow fadeInUp " data-wow-delay=".4s">
                                        Our values are upheld by actions from everyone in the company—so our promise to ourselves is to extend the same to everyone we work with and  that the way in which we accomplish our mission is as important, or more, than doing it.
                                    </p>
                                    <a data-bs-toggle="offcanvas"
                                        href="#offcanvasExample" class="btn-main bg-btn6 lnk wow fadeInUp" data-wow-delay=".6s">GET STARTED<i class="fas fa-chevron-right fa-icon"></i><span class="circle"></span></a>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="single-image">
                                    {" "}
                                    <img
                                        src={culture}
                                        alt="hero image"
                                        className="img-fluid ml-4"
                                    // style={{height:"22rem"}}

                                    />{" "}
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
                <hr></hr>
                <section className="about-lead-gen ">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 lead-intro-">
                                <img
                                    src={mission}
                                    alt="image"
                                    className="img-fluid "
                                  
                                />
                            </div>

                            
                            <div className="col-lg-6">
                                <div class="header-heading">
                                    <h1 class="wow fadeInUp text-effect-2 mt60" data-wow-delay=".2s">Mission Statement.</h1>
                                    <p class="text-black wow fadeInUp" data-wow-delay=".4s">Mission Statement
                                        We are on the mission of Intensifying Talent by bridging the gap
                                        between Technology & Human. We firmly believe that during
                                        the era of Digital Transformation, technologies should be
                                        accessible by everyone, for everyone. With years of experience in
                                        nurturing talent in various fields of IT, we profoundly change the
                                        lives of hundreds of people, by securing them dream IT jobs
                                        across our prestigious clientele. We believe, together we can build
                                        a diverse community of technology leaders, and help bridge the
                                        talent gap needed by Fortune 500 companies in today's fast-
                                        changing times.</p>
                                    <a data-bs-toggle="offcanvas"
                                        href="#offcanvasExample" class="btn-main bg-btn6 lnk wow fadeInUp" data-wow-delay=".6s">GET STARTED<i class="fas fa-chevron-right fa-icon"></i><span class="circle"></span></a>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
                <hr></hr>

                <section className="about-lead-gen ">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div class="header-heading">
                                    <h1 class="wow fadeInUp text-effect-2" data-wow-delay=".2s">Vision Statement</h1>
                                    <p class="wow fadeInUp text-black" data-wow-delay=".4s">
                                        Our vision is to make IT skills accessible for everyone
                                        by continued innovation in IT learning arena. We want to make
                                        positive impact in the communities using technology as a tool,
                                        and learning as drivers. We believe anyone and everyone
                                        deserves the right to be successful and our goal is to provide
                                        world's best in-class training in diverse IT skills & domains, to
                                        help our people and client for an impactful handshake. We will
                                        continue to keep our promise of making a difference in the
                                        society by delivering top-class learning methods.
                                    </p>
                                    <a data-bs-toggle="offcanvas"
                                        href="#offcanvasExample" class="btn-main bg-btn6 lnk wow fadeInUp" data-wow-delay=".6s">GET STARTED<i class="fas fa-chevron-right fa-icon"></i><span class="circle"></span></a>
                                </div>
                            </div>
                            <div className="col-lg-6 lead-intro-">
                                <img
                                    src={vision12}
                                    alt="image"
                                    className="img-fluid"
                                />
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <hr></hr>

            <Footer />

        </div>
    )
}
