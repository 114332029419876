import React from 'react'
import Footer from '../Header/Footer'
import Header from '../Header/Header'

export const Events = () => {
    return (
        <div>

            <Header />
            
            <section class="breadcrumb-area banner-4">
                <div class="text-block">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12 v-center">
                                <div class="bread-inner">
                                    <div class="bread-menu">
                                        <ul>
                                            <li><a href="index.html">Home</a></li>
                                            <li><a href="team.html">Team</a></li>
                                            <li><a href="#">Upcoming Events.</a></li>
                                        </ul>
                                    </div>
                                    <div class="bread-title">
                                        <h2>Upcoming Events.</h2>
                                    </div>

                                    <div class="bread-menu">
                                        <p>
                                            WE'RE UPDATING OUR LIST OF UPCOMING EVENTS, CHECK BACK SOON.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </div>
    )
}
