import React from 'react'
import Header from '../Header'
import Footer from '../Footer'
import upskil7 from '../upskil7.jpeg'
import '../Staff.css'
export const Enterprise = () => {
    return (
        <div>
            <div>

                <div>

                    <div>
                        <Header />
                    </div>

                    <br></br>
                    <br></br>

                    <div id="carouselExampleSlidesOnly" class="carousel slide" data-ride="carousel" >
                        <div class="carousel-inner">
                            <div class="carousel-item active">
                                <img class="d-block w-100" src={upskil7} alt="First slide" className='upskils upskils1' />
                                <div > <h2 ><span className='upskilcont upskilcont1'> Enterprise  <br></br> Upskills Program</span></h2></div>
                            </div>

                        </div>
                    </div>

                    {/* <div className='staffs staffs1'> <span className='common-heading text-l' style={{ fontSize: "2.8rem" , color:"black"  }}>Upskill Your Team  with Corporate <br></br>Training from the <br></br> Experts.</span></div> */}

                    <section className="about-lead-gen pad-tb"  >
                        <div className="container" >
                            <div className="row" >
                                <div className="col-lg-7">
                                    <div className='common-heading text-l'>

                                        <span>Introducing edX For Business:</span>
                                        <p className="mt10">
                                            edX For Business delivers an on-demand learning experience built to help companies grow and thrive.
                                        </p>

                                        <span >Business eLearning platform</span>
                                        <p className="mt10">
                                            The edX For Business eLearning platform helps businesses upskill, reskill, and train their workforces with content from the best institutions in the world and in subject areas relevant to their business today and in the future.
                                        </p>

                                    </div>
                                </div>
                                <div className="col-lg-5">
                                    <img
                                        src="https://f.hubspotusercontent30.net/hubfs/3277184/employee%20training%20and%20development.png"
                                        alt="image"

                                        className="img-fluid"


                                    />
                                </div>
                            </div>
                        </div>
                    </section>


                    <br></br>
                    <br></br>

                    <div>
                        <Footer />
                    </div>


                </div>

            </div>
        </div>
    )
}
