import React from 'react'
import { Link } from 'react-router-dom'
function Header() {
  return (
    <>
      <header className="nav-bg-w main-header navfix fixed-top menu-red">
        <div className="container-fluid m-pad"  >
          <div className="menu-header">
            <div className="dsk-logo">
              <a className="nav-brand" href="/">
                <img
                  src="images/white-logo.png"
                  alt="Logo"
                  className="mega-white-logo"
                />
                <img src="https://media.licdn.com/dms/image/C4E0BAQFzjGN9TWNyWw/company-logo_200_200/0/1640200917535?e=2147483647&v=beta&t=5cxXIssJb_Id9qhjK4lo4h-ohi6wDKf3PdF_uz8Zskk" alt="Logo" className="mega-darks-logo" />
              </a>
            </div>
            <div className="custom-nav" role="navigation">
              <ul className="nav-list">
                <li>
                  <a href="#" className="menu-links">
                    Employer Solutions
                  </a>
                  <div className="nx-dropdown">
                    <div className="sub-menu-section">
                      <div className="container">
                        <div className="col-md-12">
                          <div className="sub-menu-center-block">
                            <div className="sub-menu-column">
                              {/* <div className="menuheading">Multi-Page Demo</div> */}
                              <ul>

                                <li>
                                  <a >
                                    <h6><b style={{ color: "black" }}>Training & Development</b></h6>
                                    <Link to="/Tech2" style={{ color: "#3b71c6", }}>  Transform Your Teams  </Link>
                                    {/* <span className="badge bg-primary">New</span> */}
                                  </a>
                                </li>


                                <li>
                                  <a >
                                    <h6><b style={{ color: "black" }}> Staff Augmentation</b></h6>
                                    <Link to="/Staff" style={{ color: "#3b71c6" }}> Find out how we can help  </Link>
                                    {/* <span className="badge bg-primary">New</span> */}
                                  </a>
                                </li>


                                <li>
                                  <a >
                                    <h6><b style={{ color: "black" }}>  Emerging Technology Integration</b></h6>
                                    <Link to="/Emerging" style={{ color: "#3b71c6" }}>  Tech Meets R&D </Link>
                                  </a>
                                </li>

                                <li>
                                  <a >
                                    <h6><b style={{ color: "black" }}> Digital Transformation</b></h6>
                                    <Link to="/Digital" style={{ color: "#3b71c6" }}>Modernize Your Organization</Link>
                                  </a>
                                </li>

                              </ul>
                            </div>

                            <div className="sub-menu-column">
                              {/* <div className="menuheading">One-Page Demo</div> */}
                              <ul>


                                <li>

                                  <a >
                                    <h6><b style={{ color: "black" }}> Content Development & Delivery</b></h6>
                                    <Link to="/Content" style={{ color: "#3b71c6" }}> Constructivism and  Microlearning </Link>
                                  </a>

                                </li>

                                <li>

                                  <a >
                                    <h6><b style={{ color: "black" }}>Enterprise Upskills Programs</b></h6>
                                    <Link to="/Enterprise" style={{ color: "#3b71c6" }}>Upskills or Reskills your staff</Link>
                                  </a>

                                </li>
                              </ul>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li >
                  <a href="#" className="menu-links">
                    For Learners
                  </a>
                  <div className="nx-dropdown">
                    <div className="sub-menu-section">
                      <div className="container">
                        <div className="sub-menu-center-block">
                          <div className="sub-menu-column">
                            <ul>

                              <li>

                                <a >

                                  <h6><b style={{ color: "black" }}>Find Your Program</b></h6>
                                  <Link to="/Courses" style={{ color: "#3b71c6" }}>Software Engineering</Link> <br></br>
                                  <Link to="/quality" style={{ color: "#3b71c6" }}>Quality Assurance</Link> <br></br>
                                  <Link to="/scrummaster" style={{ color: "#3b71c6" }}>Scrum Master</Link><br></br>


                                </a>
                              </li>



                              <li>
                                <a >
                                  <h6><b style={{ color: "black" }}> What do our Alums Say</b></h6>
                                  <Link to="/Alumini" style={{ color: "#3b71c6" }}>Hear their story</Link>
                                </a>
                              </li>



                              <li>

                                <a >
                                  <h6><b style={{ color: "black" }}>Admissions</b></h6>
                                  <Link to="/admission" style={{ color: "#3b71c6" }}>How to Apply</Link>
                                </a>
                              </li>

                              {/* <li>
                          <a href="about-2.html">About Us V2</a>
                        </li>
                        <li>
                          <a href="why-us.html">Why Us</a>
                        </li>
                        <li>
                          <a href="team.html">Our Team</a>
                        </li>
                        <li>
                          <a href="team-details.html">Team Single</a>
                        </li>
                        <li>
                          <a href="case-study.html">Case Study</a>
                        </li>
                        <li>
                          <a href="case-study-details.html">
                            Case Study Single
                          </a>
                        </li> */}
                            </ul>
                          </div>
                          <div className="sub-menu-column">
                            <ul>


                              <li>

                                <a>
                                  <h6><b style={{ color: "black" }}>The Learning Process</b></h6>


                                  <Link to="/Howdothings" style={{ color: "#3b71c6" }}>
                                    How we do things
                                  </Link>



                                </a>

                              </li>



                              <li>

                                <a >
                                  <h6><b style={{ color: "black" }}>Campuses</b></h6>
                                  <Link to='/Campuses' style={{ color: "#3b71c6" }}>Virginia</Link> <br></br>
                                  <Link to='/Campuses' style={{ color: "#3b71c6" }}>Ohio</Link>
                                </a>
                              </li>



                              <li>
                                <a >
                                  <h6><b style={{ color: "black" }}>Financing 101</b></h6>
                                  {/* <Link to="/Financing" style={{ color: "#3b71c6" }}> Meritize</Link> <br></br> */}
                                  <Link to="/tution" style={{ color: "#3b71c6" }}> Meritize</Link>
                                </a>



                              </li>


                              {/* <li>
                          <a href="development-process.html">
                            Development Process
                          </a>
                        </li>
                        <li>
                          <a href="client-reviews.html">Client Reviews</a>{" "}
                        </li>
                        <li>
                          <a href="clients.html">Our Clients</a>
                        </li>
                        <li>
                          <a href="get-quote.html">Contact Us</a>{" "}
                        </li>
                        <li>
                          <a href="get-quote-2.html">Contact Us 2</a>{" "}
                        </li>
                        <li>
                          <a href="get-quote-3.html">Contact Us 3</a>{" "}
                        </li> */}
                            </ul>
                          </div>


                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li >
                  <a href="#" className="menu-links">
                    About Us
                  </a>
                  <div className="nx-dropdown">
                    <div className="sub-menu-section">
                      <div className="container">
                        <div className="sub-menu-center-block">

                          <div className="sub-menu-column">
                            <a><b style={{ color: "black", fontSize: "1rem" }} className="why why1"><u>Why Us</u></b></a>
                            <ul>

                              {/* <Link to="/Leader">
                                <li>
                                  <a href="#">Leadership</a>
                                </li>
                              </Link> */}


                              <Link to="/Binary">
                                <li>
                                  <u><a style={{ color: "#3b71c6" }}>The Binary Difference</a></u>
                                </li>
                              </Link>

                              <ul>
                                <Link to="/Binary">
                                  <li>
                                    <a style={{ color: "#3b71c6" }}>Our Culture</a>
                                  </li>

                                </Link>
                                <Link to="/Mission">
                                  <li>
                                    <a style={{ color: "#3b71c6" }}>Mission Statement</a>{" "}
                                  </li>
                                </Link>

                                <Link to="/Vission">
                                  <li>
                                    <a style={{ color: "#3b71c6" }}>Vision Statement</a>
                                  </li>
                                </Link>

                              </ul>


                              {/* <li>
                          <a href="header-v2.html">Header v2</a>
                        </li>
                        <li>
                          <a href="header-v3.html">Header v3</a>
                        </li>
                        <li>
                          <a href="header-v4.html">Header v4</a>
                        </li>
                        <li>
                          <a href="footer-design.html">Footer</a>
                        </li> */}
                            </ul>
                          </div>


                          <div className="sub-menu-column">
                            <a><b style={{ color: "black", fontSize: "1rem" }} className="why why1"><u>Who and Where</u></b></a>
                            <ul>

                              <Link to="/career">
                                <li>
                                  <a style={{ color: "#3b71c6" }}>Career</a>
                                </li>
                              </Link>
                              <Link to="/Campuses">
                                <li>
                                  <a style={{ color: "#3b71c6" }}>Campuses</a>
                                </li>
                              </Link>

                              <Link to="/ContactUs">
                                <li>
                                  <a style={{ color: "#3b71c6" }}>Contact Us</a>{" "}
                                </li>
                              </Link>

                              <Link to="/Alumini">
                                <li>
                                  <a style={{ color: "#3b71c6" }}>Alumini Spoghlight</a>
                                </li>
                              </Link>


                              {/* <li>
                          <a href="typography.html">Typography</a>
                        </li>
                        <li>
                          <a href="button.html">Buttons</a>
                        </li>
                        <li>
                          <a href="locations.html">Office Location</a>
                        </li> */}
                            </ul>
                          </div>


                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li >
                  <a href="#" className="menu-links">
                    Resources
                  </a>
                  <div className="nx-dropdown">
                    <div className="sub-menu-section">
                      <div className="container">
                        <div className="sub-menu-center-block">
                          <div className="sub-menu-column">
                            <ul>

                              <Link to="Blogs">
                                <li>
                                  <a href="#" style={{ color: "#3b71c6" }} >Blog</a>
                                </li>
                              </Link>
                              <Link to="/Courses">
                                <li>
                                  <a href="#" style={{ color: "#3b71c6" }}>Curriculum</a>
                                </li>
                              </Link>
                              <Link to="/apply">
                                <li>
                                  <a href="#" style={{ color: "#3b71c6" }}>Apply Now</a>
                                </li>
                              </Link>

                              {/* <Link to="/Meetourteam">
                                <li>
                                  <a href="#">Meet Our Team</a>
                                </li>
                              </Link> */}
                              {/* <li>
                          <a href="typography.html">Typography</a>
                        </li>
                        <li>
                          <a href="button.html">Buttons</a>
                        </li>
                        <li>
                          <a href="locations.html">Office Location</a>
                        </li> */}
                            </ul>
                          </div>
                          <div className="sub-menu-column">
                            <ul>

                              <Link to="/Alumini">
                                <li>
                                  <a href="#" style={{ color: "#3b71c6" }}>Testimonials</a>{" "}
                                </li>
                              </Link>

                              <Link to="/faqs">
                                <li>
                                  <a href="#" style={{ color: "#3b71c6" }}>Curious You - FAQs</a>
                                </li>
                              </Link>

                              {/* <li>
                          <a href="tabs.html">Tabs</a>
                        </li>
                        <li>
                          <a href="error.html">404 Page</a>
                        </li>
                        <li>
                          <a href="gradients.html">Background Gradients</a>
                        </li> */}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                {/* <li className="sbmenu rpdropdown">
            <a href="#" className="menu-links">
              Portfolio
            </a>
            
          </li> */}



                {/* <div className="nx-dropdown menu-dorpdown">
              <div className="sub-menu-section">
                <div className="sub-menu-center-block">
                  <div className="sub-menu-column smfull">
                    <ul>
                      <li>
                        <a href="blog-grid-1.html">Blog Grid 1</a>{" "}
                      </li>
                      <li>
                        <a href="blog-grid-2.html">Blog Grid 2</a>{" "}
                      </li>
                      <li>
                        <a href="blog-right-sidebar.html">Blog Right Sidebar</a>{" "}
                      </li>
                      <li>
                        <a href="blog-left-sidebar.html">Blog Left Sidebar</a>{" "}
                      </li>
                      <li>
                        <a href="blog-single.html">Blog Single</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div> */}


              </ul>
              {/* mobile + desktop - sidebar menu- dark mode witch and button */}
              <ul className="nav-list right-end-btn">
                <li className="hidemobile">
                  <a
                    data-bs-toggle="offcanvas"
                    href="#offcanvasExample"
                    className=" btn-br bg-btn2 btn-br bg-btn3 btshad-b2 lnk"
                  >
                    Let’s Talk
                  </a>
                </li>


                <li className="hidedesktop darkmodeswitch">
                  <div style={{ marginLeft: "-5rem", color: "#4ca4e9 0" }} className="switch-wrapper d-lg-none" data-bs-toggle="offcanvas" data-bs-target="#offcanvasWithBothOptions" aria-controls="offcanvasWithBothOptions">

                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-menu-2" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="#00bfd8" fill="none" stroke-linecap="round" stroke-linejoin="round">
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <line x1="4" y1="6" x2="20" y2="6" />
                      <line x1="4" y1="12" x2="20" y2="12" />
                      <line x1="4" y1="18" x2="20" y2="18" />
                    </svg>

                  </div>



                </li>
                <li className="hidedesktop">
                  <a
                    data-bs-toggle="offcanvas"
                    href="#offcanvasExample"
                    className="btn-round- btn-br bg-btn2"
                  >
                    <i className="fas fa-phone-alt" />

                  </a>

                </li>
                <li className="navm- hidedesktop">
                  {" "}
                  <a className="toggle" href="#">
                    <span />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          {/*Mobile Menu*/}

        </div>
      </header>

      <div class="offcanvas offcanvas-start" data-bs-scroll="true" tabindex="-1" id="offcanvasWithBothOptions" aria-labelledby="offcanvasWithBothOptionsLabel" >
        <div class="offcanvas-header">
          <h5 class="offcanvas-title" id="offcanvasWithBothOptionsLabel" style={{ marginLeft: "1.8rem" }}>Binary Logic</h5>
          <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body">
          <ul>
            <li>
              <div class="dropdown">
                <a style={{ color: "#4ca4e9 " }}> Employer Solutions
                  <i class="fas fa-angle-down btn  dropdown-toggle" data-toggle="dropdown" style={{ color: "black" }}></i>

                  <div class="dropdown-content">
                    <a class="dropdown-item" href="/Tech2">Training & Development</a>
                    <a class="dropdown-item" href="/Staff">Staff Augmentation</a>
                    <a class="dropdown-item" href="/Emerging">Emerging Technology integration</a>
                    <a class="dropdown-item" href="/Digital">Digital Transformation</a>
                    <a class="dropdown-item" href="/Content">Content Development & Delivery</a>
                    <a class="dropdown-item" href="/Enterprise">Enterprise Upskills Programs</a>
                  </div>

                </a>
              </div>
            </li>
            <br></br>


            <li>
              <div class="dropdown">
                <span style={{ color: "#4ca4e9 " }}>  For Learners
                  <i class="fas fa-angle-down btn  dropdown-toggle" data-toggle="dropdown" style={{ color: "black", marginLeft: "2.6rem" }}></i>

                  <div class="dropdown-content">
                    <a class="dropdown-item" href="/Courses">Find Your Program</a>
                    <a class="dropdown-item" href="/Binaryjourney">What do our Alum Says</a>
                    <a class="dropdown-item" href="/admission">Admissions</a>
                    <a class="dropdown-item" href="/Alumini">The Learning Process</a>
                    <a class="dropdown-item" href="/Campuses">Campuses</a>
                    <a class="dropdown-item" href="/Financing ">Financing 101</a>
                  </div>

                </span>
              </div>
            </li>
            <br></br>
            <li>
              <div class="dropdown row">
                <a style={{ color: "#4ca4e9 " }}> About Us
                  <i class="fas fa-angle-down btn  dropdown-toggle" data-toggle="dropdown" style={{ color: "black", marginLeft: "4.1rem" }}></i>

                  <div class="dropdown-content " >

                    <div className='col-sm'>
                      <p><b className='why why1'><u>Why Us</u></b></p>

                      <a class="dropdown-item" href="/Binary">The Binary Diffrences</a>
                      <a class="dropdown-item" href="/Binary">Our Culture</a>
                      <a class="dropdown-item" href="/Mission">Mission Statement</a>
                      <a class="dropdown-item" href="/Vission">Vision Statement</a>
                    </div>

                    <br></br>
                    <div className='col-sm'>
                      <p><b className='why why1'><u>Who & Where</u></b></p>
                      <a class="dropdown-item" href="/Career">Career</a>
                      <a class="dropdown-item" href="/Campuses">Campuses</a>
                      <a class="dropdown-item" href="/ContactUs">ContactUs</a>
                      <a class="dropdown-item" href="/Alumini">Alumini Spoghlight</a>
                    </div>





                  </div>

                </a>
              </div>
            </li>
            <br></br>
            <li>
              <div class="dropdown">
                <a style={{ color: "#4ca4e9 " }}> Resources
                  <i class="fas fa-angle-down btn  dropdown-toggle" data-toggle="dropdown" style={{ color: "black", marginLeft: "3.5rem" }}></i>

                  <div class="dropdown-content">
                    <a class="dropdown-item" href="/Blogs">Blog</a>
                    <a class="dropdown-item" href="/Alumini">Testimonials</a>
                    <a class="dropdown-item" href="/Curriculum">Curriculum</a>
                    <a class="dropdown-item" href="/faqs">Curious You- FAQs</a>
                  </div>

                </a>
              </div>
            </li>
            <br></br>
          </ul>
        </div>
      </div>

    </>
  )
}

export default Header