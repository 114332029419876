import React from 'react'
import emp1 from './emp1.png'
import upskill from './upskill.png'
import { Link } from 'react-router-dom'
import Slider from "react-slick";

export const Four = () => {


    

    var settings = {

        responsive: [
            {
              breakpoint: 1024,
              settings: {
               slidesToShow: 3,
              }
            },
            {
              breakpoint: 600,
              settings: {
               slidesToShow: 2,
              }
             }
          ],

        
        dots: true,
        autoplay: true,
        infinite: true,
        speed: 4000,
        slidesToShow: 3,
        slidesToScroll: 3
    };
    return (

        <>



            <div className="row justify-content-center mt40">
                <div className="col-lg-8">
                    <div className="common-heading">
                        <span>What we’re great at</span>
                        <h2 className="mb30">EMPLOYER SOLUTIONS</h2>
                    </div>
                </div>
            </div>


            <Slider {...settings} >

                <div>

                    <div
                        className=""
                        data-wow-delay=".2s"
                    >
                        <div
                            className="s-block"
                            data-tilt=""
                        
                            data-tilt-speed={1000}
                        >
                            <div className="s-card-icon">
                                <img
                                    src="https://www.aetos.com.sg/images/career/Traning%20Development.svg"
                                    alt="service"
                                    className="img-fluid"
                                />
                            </div>
                            <h4 >Training & Development</h4>
                            <p >
                                Training and development initiatives are educational activities within an organization that are designed to improve the job performance of an individual or group...

                            </p>
                            <a href="/Tech2">
                                Read More <i className="fas fa-chevron-right fa-icon" />
                            </a>
                        </div>
                    </div>

                </div>
                <div>

                    <div
                        className="  wow fadeInUp"
                        data-wow-delay=".4s"
                    >
                        <div
                            className="s-block"
                            data-tilt=""
                            data-tilt-max={4}
                            data-tilt-speed={1000}
                        >
                            <div className="s-card-icon">
                                <img
                                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRoFV-CbDXD8qBz1JNY9KzBKUeENRy1lb2l2Q&usqp=CAU"

                                    alt="service"
                                    className="img-fluid"
                                />
                            </div>
                            <h4 style={{ fontSize: "1.1rem" }}>Staff Augmentation</h4>
                            <p style={{ textAlign: "justify" }}>
                                When organizations seek outside help in completing important IT projects, they often consider two primary delivery models: staff augmentation and project outsourcing.
                            </p>
                            <a href="/Staff">
                                Read More <i className="fas fa-chevron-right fa-icon" />
                            </a>
                        </div>
                    </div>

                </div>
                <div>

                    <div
                        className="  wow fadeInUp"
                        data-wow-delay=".6s"
                    >
                        <div
                            className="s-block"
                            data-tilt=""
                            data-tilt-max={4}
                            data-tilt-speed={1000}
                        >
                            <div className="s-card-icon">
                                <img
                                    src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTiCYuBcg4GTtigs78zo587JOEjmzRLHF1EMA&usqp=CAU'
                                    alt="service"
                                    className="img-fluid"
                                />
                            </div>
                            <h4 style={{ fontSize: "1.1rem" }}>Emerging Technology Integration</h4>
                            <p style={{ textAlign: "justify" }}>
                                It’s little known, but one of the breakthroughs that led to the seemingly miraculous comeback of  the 1990s was the obscure process of technology integration...
                            </p>

                            <a href="/Emerging">
                                Read More <i className="fas fa-chevron-right fa-icon" />
                            </a>

                        </div>
                    </div>

                </div>
                <div>

                    <div
                        className="  wow fadeInUp"
                        data-wow-delay=".8s"
                    >
                        <div
                            className="s-block mb0"
                            data-tilt=""
                            data-tilt-max={4}
                            data-tilt-speed={1000}
                        >
                            <div className="s-card-icon">
                                <img
                                    src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTlKWBBkUM7M-_d1PbGj79nCyf2kvD6--dQ6TOtz_gte3lnwSf4fe-vf2842qbjrIfE_gw&usqp=CAU'
                                    alt="service"
                                    className="img-fluid"
                                />
                            </div>
                            <h4 style={{ fontSize: "1.1rem" }}>Digital Transformation</h4>
                            <p style={{ textAlign: "justify" }}>
                                Digital transformation is the integration of digital technology into all areas of a business, fundamentally changing how you operate and deliver to customers..
                            </p>
                            <a href="/Digital">
                                Read More <i className="fas fa-chevron-right fa-icon" />
                            </a>
                        </div>
                    </div>

                </div>
                <div>

                    <div
                        className="  wow fadeInUp"
                        data-wow-delay="1s"
                    >
                        <div
                            className="s-block mb0"
                            data-tilt=""
                            data-tilt-max={4}
                            data-tilt-speed={1000}
                        >
                            <div className="s-card-icon">
                                <img
                                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS4R8dOZ9fWDRN6Hsr0XEuEGIq1YEQbgpvIaA&usqp=CAU"
                                    alt="service"

                                    className="img-fluid"
                                    style={{ height: "80px" }}
                                />
                            </div>
                            <h4 style={{ fontSize: "1.1rem" }}>Content Development & Delivery</h4>
                            <p style={{ textAlign: "justify" }}>
                                Considerations for content development and delivery include the medium of delivery, intentional design, and style, among other things. And knowledge .
                            </p>
                            <a href="/Content">
                                Read More <i className="fas fa-chevron-right fa-icon" />
                            </a>
                        </div>
                    </div>

                </div>
                <div>

                    <div
                        className="  wow fadeInUp"
                        data-wow-delay="1.2s"
                    >
                        <div
                            className="s-block mb0"
                            data-tilt=""
                            data-tilt-max={4}
                            data-tilt-speed={1000}
                        >
                            <div className="s-card-icon">
                                <img
                                    src={upskill}
                                    alt="service"
                                    className="img-fluid"
                                />
                            </div>
                            <h4 style={{ fontSize: "1.1rem" }}>Enterprise Upskill Programs</h4>
                            <p style={{ textAlign: "justify" }}>
                                Develop the skills your business needs to compete and win. Our curated learning pathways help close knowledge gaps and drive measurable outcomes..
                            </p>
                            <a href="/Enterprise">
                                Read More <i className="fas fa-chevron-right fa-icon" />
                            </a>
                        </div>
                    </div>

                </div>
            </Slider>
        </>
    )
}


