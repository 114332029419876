import React from 'react'
import { Link } from 'react-router-dom'

function Footer() {
  return (
    <>
      <footer >

        {/* <footer >
          <div class="container">
            <div class="row text-center newslatter">
              <div class="col-sm-8 col-sm-offset-2">
                <h1>Subscribe to our newsletter</h1>
                <form class="form-inline line"> 
                  <div class="form-group">
                    <label class="sr-only">email</label>
                    <input type="email" placeholder="Enter email address" class="form-control input-lg control" />
                  </div>
                  <button style={{ background: "linear-gradient(to right, #031d27 0%, #2196F3 100%)" }} type="button" class="btn btn-default btn-lg subscribe">Subscribe now</button>
                </form>
                <p class="para para1"><u>Subscribe now if you want to recieve updates and news via email.<br />Don’t worry, it won’t happen often.</u></p>
              </div>
            </div>

          </div>
        </footer> */}

        <div className="footer-row2">
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-lg-3 col-sm-4  ftr-brand-pp">
                <a className="navbar-brand mt30  f-dark-logo" href="#">
                  {" "}
                  <img src="https://media.licdn.com/dms/image/C4E0BAQFzjGN9TWNyWw/company-logo_200_200/0/1640200917535?e=2147483647&v=beta&t=5cxXIssJb_Id9qhjK4lo4h-ohi6wDKf3PdF_uz8Zskk" alt="Logo" className="mega-darks-logo" style={{ height: "6rem" }} />
                </a>
                <a className="navbar-brand mt30 mb25 f-white-logo" href="#">
                  {" "}
                  <img src="https://media.licdn.com/dms/image/C4E0BAQFzjGN9TWNyWw/company-logo_200_200/0/1640200917535?e=2147483647&v=beta&t=5cxXIssJb_Id9qhjK4lo4h-ohi6wDKf3PdF_uz8Zskk" alt="Logo" className="mega-darks-logo" />
                </a>
                <p>
                  It’s about providing the platform which connects the dots and getting you to the next level. Connecting with the right audience and platforms to get you there.
                </p>
                <a data-bs-toggle="offcanvas"
                  href="#offcanvasExample" className="btn-main bg-btn3 lnk mt20">
                  We can help <i className="fas fa-chevron-right fa-icon" />
                  <span className="circle" />
                </a>
              </div>
              <div className="col-lg-3 col-sm-4">
                <h5 style={{ marginLeft: "2rem" }}>Contact Us</h5>
                <ul className="footer-address-list ftr-details">
                  <li>
                    <span>
                      <i className="fas fa-envelope" />
                    </span>
                    <p>
                      Email{" "}
                      <span>
                        {" "}
                        <a href="mailto:Info@binarylogicit.com">
                          Info@binarylogicit.com
                        </a>
                      </span>
                    </p>
                  </li>

                  <li>
                    <span>
                      <i className="fas fa-map-marker-alt" />
                    </span>
                    <p>
                      Virginia <span> 44258 Mercure Cir Suite 120 A, Sterling, VA 20166 </span>
                    </p>
                  </li>

                  <li>
                    <span>
                      <i className="fas fa-map-marker-alt" />
                    </span>
                    <p>
                      Ohio <span>10883 Yankee St, Dayton, OH 45458 </span>
                    </p>
                  </li>

                  <li>

                    <span>
                      <i class="fas fa-mobile-alt" ></i>
                    </span>
                    <p ><span>(888) 333-9666 </span></p>

                  </li>


                </ul>
              </div>
              <div className="col-lg-2 col-sm-4">
                <h5 style={{ marginLeft: "2rem" }}>Company</h5>
                <ul className="footer-address-list link-hover">


                  <li>
                    <Link to="/Refundpolicy">
                      <a href="javascript:void(0)">Refund Policy</a>
                    </Link>
                  </li>
                  <li>
                    <Link to="/Privacypolicy">
                      <a href="javascript:void(0)">Privacy Policy</a>
                    </Link>
                  </li>
                  <li>
                    <Link to="/Termcondition">
                      <a href="javascript:void(0)">Terms and Conditions</a>
                    </Link>
                  </li>
                  <li>
                    <Link to="/cookiesPolicy">
                      <a href="javascript:void(0)">Cookies Policy</a>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>




        </div>

        <div className="footer-row3">
          <div className="copyright">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="footer-social-media-icons">
                    <a href="javascript:void(0)" target="blank">
                      <i className="fab fa-facebook" />
                    </a>
                    <a href="javascript:void(0)" target="blank">
                      <i className="fab fa-twitter" />
                    </a>
                    <a href="javascript:void(0)" target="blank">
                      <i className="fab fa-instagram" />
                    </a>
                    <a href="javascript:void(0)" target="blank">
                      <i className="fab fa-linkedin" />
                    </a>
                    <a href="javascript:void(0)" target="blank">
                      <i className="fab fa-youtube" />
                    </a>

                  </div>
                  <div className="footer-">
                    <p>
                      © 2020-2023. All Rights Reserved | Binary Logic IT LLC
                      <a
                        href="https://ndinfotech.com"
                        target="blank"
                      >

                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>

    </>
  )
}

export default Footer