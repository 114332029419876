import React from 'react'
import Header from '../Header'
import Footer from '../Footer'
import technology from './technology.jpg'
import '../Staff.css'
export const Digital = () => {
    return (
        <>
                <div> 
                        <Header />    
                    <img src={technology} className ="digimg digimg1" ></img>
                    <div> <h2 ><span  className='digital digital1'> Digital <br></br> Transformation </span></h2></div>


                    <section className="about-lead-gen  " style={{ marginTop: "3rem" }}>
                        <div className="container">
                            <div className='common-heading text-l'>
                                <span>Digital transformation</span>

                                <p style={{ textAlign: "justify" }}>
                                    Digital transformation is the integration of digital technology into all areas of a business, fundamentally changing how you operate and deliver value to customers. It's also a cultural change that requires organizations to continually challenge the status quo, experiment, and get comfortable with failure.
                                </p>
                            </div>

                        </div>
                    </section>

                    <section className="about-lead-gen pad-tb">
                        <div className="container">
                            <div className='common-heading text-l'>
                                <span>Why does digital transformation matter?</span>

                                <p style={{ textAlign: "justify" }}>

                                    A business may take on digital transformation for several reasons. But by far, the most likely reason is that they have to: It's a survival issue. In the wake of the pandemic, an organization's ability to adapt quickly to supply chain disruptions, time to market pressures, and rapidly changing customer expectations has become critical.
                                </p>

                                <p style={{ textAlign: "justify" }}>
                                    It's early to guess which long-term consumer behavior changes will stick. However, Rodney Zemmel, global leader, McKinsey Digital of McKinsey & Company, says that on the consumer side "digital has been accelerating in just about all categories." An important factor to watch will be the degree to which forced change — three out of four Americans tried a new shopping behavior, for example — will revert when possible, post today's emphasis on stay-in-place.

                                </p>

                                <p style={{ textAlign: "justify" }}>
                                    McKinsey data shows that the accelerated shift towards streaming and online fitness is likely to stay permanently, Zemmel says. But the biggest shifts were around food. Both home cooking and online grocery shopping — a category that has been generally resistant to getting moved online — will probably stay more popular with consumers than in the past. Cashless transactions are also gaining steam. On the B2B side, McKinsey data shows remote selling is working.
                                </p>
                            </div>

                        </div>
                    </section>


                    <section className="about-lead-gen  " style={{ marginTop: "-3.5rem" }}>
                        <div className="container">
                            <div className='common-heading text-l'>
                                <span>How can I measures ROI on digital transformation?</span>

                                <p style={{ textAlign: "justify" }}>
                                    To prove the success of digital transformation efforts, leaders need to quantify the return on investment. That's easier said than done with projects that cross functional and business boundaries, change how a company goes to market, and often fundamentally reshape interactions with customers and employees.
                                </p>

                                <p style={{ textAlign: "justify" }}>
                                    A project such as revamping a mobile application may have a short-term payoff but other projects are chasing longer-term business value.
                                </p>

                                <p style={{ textAlign: "justify" }}>
                                    Moreover, as we have reported, "Digital transformation efforts are ongoing and evolving, which can render traditional business value calculations and financial governance approaches less effective."
                                </p>
                            </div>

                        </div>
                    </section>

                    <br></br>
                    <br></br>

                    <div>
                        <Footer />
                    </div>


                </div>

          

        </>
    )
}
