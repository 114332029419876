import React from 'react'
import './faqs.css'
import Footer from './Footer'
import Header from './Header'
export const Faqs = () => {
  return (
    <div>

      <div>
        <Header />
      </div>

      <br></br>
      <br></br>
      <br></br>


      <section class="faq-section">
        <div class="container">
          <div class="row">

            <div class="col-md-6 offset-md-3">

              <div class="faq-title text-center pb-3">
                <h2>FAQ</h2>
              </div>
            </div>
            <div class="col-md-6 offset-md-3">
              <div class="faq" id="accordion">
                <div class="card">
                  <div class="card-header" id="faqHeading-1">
                    <div class="mb-0">
                      <h5 class="faq-title" data-toggle="collapse" data-target="#faqCollapse-1" data-aria-expanded="true" data-aria-controls="faqCollapse-1">
                        <span class="badge">1</span>What makes Binary Logic graduates stand out from other bootcamp graduates?

                      </h5>
                    </div>
                  </div>
                  <div id="faqCollapse-1" class="collapse" aria-labelledby="faqHeading-1" data-parent="#accordion">
                    <div class="card-body">
                      <p>"Year after year, Binary Logic continues to lead in the number of graduates hired as mid- and senior-level software engineers, as well as median starting salaries from $85,000 to $130,000.

                        This foundation continues to be built upon as residents are introduced to various approaches to develop their skills, from Hack Hours and Tech Talks to several student-led projects during the program.

                        Binary Logic alumni are employed at top companies such as Freddi Mac, JPMC, Apple, NIH & Netflix etc."
                      </p>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header" id="faqHeading-2">
                    <div class="mb-0">
                      <h5 class="faq-title" data-toggle="collapse" data-target="#faqCollapse-2" data-aria-expanded="false" data-aria-controls="faqCollapse-2">
                        <span class="badge">2</span> What's the difference between each of the programs?

                      </h5>
                    </div>
                  </div>
                  <div id="faqCollapse-2" class="collapse" aria-labelledby="faqHeading-2" data-parent="#accordion">
                    <div class="card-body">
                      <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.</p>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header" id="faqHeading-3">
                    <div class="mb-0">
                      <h5 class="faq-title" data-toggle="collapse" data-target="#faqCollapse-3" data-aria-expanded="false" data-aria-controls="faqCollapse-3">
                        <span class="badge">3</span>What is Binary Logic doing in the Diversity, Equity, & Inclusion space?

                      </h5>
                    </div>
                  </div>
                  <div id="faqCollapse-3" class="collapse" aria-labelledby="faqHeading-3" data-parent="#accordion">
                    <div class="card-body">
                      <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here.</p>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header" id="faqHeading-4">
                    <div class="mb-0">
                      <h5 class="faq-title" data-toggle="collapse" data-target="#faqCollapse-4" data-aria-expanded="false" data-aria-controls="faqCollapse-4">
                        <span class="badge">4</span> Does Binary Logic provide online training?

                      </h5>
                    </div>
                  </div>
                  <div id="faqCollapse-4" class="collapse" aria-labelledby="faqHeading-4" data-parent="#accordion">
                    <div class="card-body">
                      <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header" id="faqHeading-5">
                    <div class="mb-0">
                      <h5 class="faq-title" data-toggle="collapse" data-target="#faqCollapse-5" data-aria-expanded="false" data-aria-controls="faqCollapse-5">
                        <span class="badge">5</span> What is the admission Process?

                      </h5>
                    </div>
                  </div>
                  <div id="faqCollapse-5" class="collapse" aria-labelledby="faqHeading-5" data-parent="#accordion">
                    <div class="card-body">
                      <p> It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing</p>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header" id="faqHeading-6">
                    <div class="mb-0">
                      <h5 class="faq-title" data-toggle="collapse" data-target="#faqCollapse-6" data-aria-expanded="false" data-aria-controls="faqCollapse-6">
                        <span class="badge">6</span> How do I know if I'm a qualified candidate for the program?

                      </h5>
                    </div>
                  </div>
                  <div id="faqCollapse-6" class="collapse" aria-labelledby="faqHeading-6" data-parent="#accordion">
                    <div class="card-body">
                      <p>The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham.</p>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header" id="faqHeading-7">
                    <div class="mb-0">
                      <h5 class="faq-title" data-toggle="collapse" data-target="#faqCollapse-7" data-aria-expanded="false" data-aria-controls="faqCollapse-7">
                        <span class="badge">7</span> Do you need a high school diploma or college degree to participate in the program?

                      </h5>
                    </div>
                  </div>
                  <div id="faqCollapse-7" class="collapse" aria-labelledby="faqHeading-7" data-parent="#accordion">
                    <div class="card-body">
                      <p>Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).</p>
                    </div>
                  </div>
                </div>

                <div class="card">
                  <div class="card-header" id="faqHeading-7">
                    <div class="mb-0">
                      <h5 class="faq-title" data-toggle="collapse" data-target="#faqCollapse-7" data-aria-expanded="false" data-aria-controls="faqCollapse-7">
                        <span class="badge">8</span>Is there an application fee?


                      </h5>
                    </div>
                  </div>
                  <div id="faqCollapse-7" class="collapse" aria-labelledby="faqHeading-7" data-parent="#accordion">
                    <div class="card-body">
                      <p>Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).</p>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header" id="faqHeading-7">
                    <div class="mb-0">
                      <h5 class="faq-title" data-toggle="collapse" data-target="#faqCollapse-7" data-aria-expanded="false" data-aria-controls="faqCollapse-7">
                        <span class="badge">9</span> How much is the tuition fee and what financing options are available?


                      </h5>
                    </div>
                  </div>
                  <div id="faqCollapse-7" class="collapse" aria-labelledby="faqHeading-7" data-parent="#accordion">
                    <div class="card-body">
                      <p>Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).</p>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header" id="faqHeading-7">
                    <div class="mb-0">
                      <h5 class="faq-title" data-toggle="collapse" data-target="#faqCollapse-7" data-aria-expanded="false" data-aria-controls="faqCollapse-7">
                        <span class="badge">10</span> Does Binary Logic offer scholarships?


                      </h5>
                    </div>
                  </div>
                  <div id="faqCollapse-7" class="collapse" aria-labelledby="faqHeading-7" data-parent="#accordion">
                    <div class="card-body">
                      <p>Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).</p>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header" id="faqHeading-7">
                    <div class="mb-0">
                      <h5 class="faq-title" data-toggle="collapse" data-target="#faqCollapse-7" data-aria-expanded="false" data-aria-controls="faqCollapse-7">
                        <span class="badge">11</span> What are the scholarship application requirements?


                      </h5>
                    </div>
                  </div>
                  <div id="faqCollapse-7" class="collapse" aria-labelledby="faqHeading-7" data-parent="#accordion">
                    <div class="card-body">
                      <p>Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).</p>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header" id="faqHeading-7">
                    <div class="mb-0">
                      <h5 class="faq-title" data-toggle="collapse" data-target="#faqCollapse-7" data-aria-expanded="false" data-aria-controls="faqCollapse-7">
                        <span class="badge">12</span> Is Binary Logic an officially accredited institution?


                      </h5>
                    </div>
                  </div>
                  <div id="faqCollapse-7" class="collapse" aria-labelledby="faqHeading-7" data-parent="#accordion">
                    <div class="card-body">
                      <p>While we are excited to provide a transformative, impactful educational experience, at this time Binary Logic is not accredited by a U.S. Department of Education-recognized accrediting body. This means that federal student loan aid cannot be used in conjunction with our courses.</p>
                    </div>
                  </div>
                </div>


              </div>
            </div>
          </div>
        </div>
      </section>


      <Footer />

    </div>
  )
}
