import React from 'react'
import Footer from '../Footer'
import menu1 from './menu1.png'
import Header from '../Header'

import how from './how.jpg'
export const Howdothings = () => {
    return (
        <div>
            <Header />

            <br></br>
            <br></br>
            <br></br>
            <br></br>

            <div class="container-fluid pb-4">
                <section class="row no-gutters align-items-center">
                    <div class="col-12 text-center d-flex">
                        <img class="img-fluid of col-lg-12 ppp" src={how} alt="img1" style={{ height: "29rem" }} />


                    </div>
                </section>
            </div>


            <div>
                <center ><h1 style={{ color: "black" }} >How we do things</h1></center>
                <br></br>
                <br></br>
                <div style={{ background: "linear-gradient(to right, rgb(235 143 28) 0%, rgb(40 185 209) 100%)", maxWidth: "1367px", marginTop: "-2rem" }} >

                    <div className='program program1' >

                        <br></br>
                        <center><p style={{ color: "black" }}>6 months Guaranteed Upskilling & Placement Program with Salary  $75000.00</p></center>
                        {/* <center className='sys' style={{ color: "black" }}> <p style={{ color: "black", background: "lightcyan", textAlign: "center", width: "30rem" }} > Total Program Fees $18,000.00 <br></br> Guaranteed placement within 6 months</p></center> */}
                        {/* <div style={{ marginTop: "-7rem" }}>

                        <span style={{ fontSize: "5rem", color: "black", marginLeft: "4.8em", marginTop: "-2em" }}>&#8595;</span>
                        <span style={{ fontSize: "5rem", color: "black", marginLeft: "2.8em", marginTop: "-2em" }}>&#8595;</span>
                        <span style={{ fontSize: "5rem", color: "black", marginLeft: "1.9em", marginTop: "1em" }}>&#8595;</span>
                        <div className='row'>
                            <span style={{ fontSize: "5rem", color: "black", marginLeft: "1em", marginTop: "-1.5em" }}>&#8595;</span>
                            <div className='col-sm'>
                                <center>  <button type="button" class="btn btn-primary" style={{ borderRadius: ".1rem", boxShadow: "1rem", background: "linear-gradient(to right, #031d27 0%, #2196F3 100%)", color: "white", width: "10em" }}>Step 1 </button></center>
                                <br></br>
                            </div>


                            <div className='col-sm'>

                                <center>  <button type="button" class="btn btn-primary" style={{ borderRadius: ".1rem", boxShadow: "1rem", background: "linear-gradient(to right, #031d27 0%, #2196F3 100%)", color: "white", width: "10em" }}>Step 2 </button></center>
                            </div>

                            <br></br>
                            <div className='col-sm'>
                                <center>  <button type="button" class="btn btn-primary" style={{ borderRadius: ".1rem", boxShadow: "1rem", background: "linear-gradient(to right, #031d27 0%, #2196F3 100%)", color: "white", width: "10em" }}>Step 3 </button></center>
                            </div>

                            <br></br>

                            <div className='col-sm'>
                                <center>  <button type="button" class="btn btn-primary" style={{ borderRadius: ".1rem", boxShadow: "1rem", background: "linear-gradient(to right, #031d27 0%, #2196F3 100%)", color: "white", width: "10em" }}>Step 4 </button></center>
                            </div>

                            <br></br>
                        </div>
                       

                    </div> */}

                        <div>
                            <img src={menu1} alt="menu1" style={{ height: "11em", width: "75vw" }} />
                        </div>
                        <div className='row gap-2 m-2 pb-4'>
                            <div className='col-sm' style={{ background: "wheat" }}>

                                <p style={{ color: "black" }}>
                                    <i class="fas fa-check"></i>   Get in touch with our recruitment team to learn more about the program.
                                </p>
                                <p style={{ color: "black" }}>
                                    <i class="fas fa-check"></i> Take an aptitude test
                                    for qualification.
                                </p>
                                <p style={{ color: "black" }}>
                                    <i class="fas fa-check"></i>  Once qualified, one on one discussion with Q&A.
                                </p>
                                <p style={{ color: "black" }}>
                                    <i class="fas fa-check"></i>   Sign the letter of intent.
                                </p>
                                <p style={{ color: "black" }}>
                                    <i class="fas fa-check"></i>  Day 1 - Onboarding
                                </p>
                            </div>

                            <div className='col-sm' style={{ background: "gainsboro" }}>

                                <p style={{ color: "black" }}>
                                    <i class="fas fa-check"></i>  Apply for a $18,000 loan with our preferred lending partner
                                </p>

                                <p style={{ color: "black" }} >
                                    <i class="fas fa-check"></i>  Loan is approved and contract is signed with us

                                </p>
                                <p style={{ color: "black" }}>
                                    <i class="fas fa-check"></i>  We will train and place you within 6 months.
                                </p>
                                <p style={{ color: "black" }}>
                                    <i class="fas fa-check"></i> You are not obliged to make any payment for 6 months.
                                </p>
                                <p style={{ color: "black" }}>
                                    <i class="fas fa-check"></i>  Binary Logic will take care of all the payments for 6 months, during guaranteed placement period.
                                </p>
                            </div>

                            <div className='col-sm' style={{ background: "aliceblue" }}>

                                <p style={{ color: "black" }}>
                                    <i class="fas fa-check"></i> Week 5: Core Assessment
                                </p>
                                <p style={{ color: "black" }}>
                                    <i class="fas fa-check"></i> Week 9: Advanced Assessment
                                </p>
                                <p style={{ color: "black" }}>
                                    <i class="fas fa-check"></i>  Meritorious students who pass Week 5 AND/OR Week 9 assessments with over 70% score, will qualify for the scholarship program offered by Binary Logic.
                                </p>
                                <p style={{ color: "black" }}>
                                    <i class="fas fa-check"></i>  The scholarship program will enable students to receive a Per Diem for upto 4 months.
                                </p>

                            </div>

                            <div className='col-sm' style={{ background: "#e1e5a6" }}>

                                <p style={{ color: "black" }}>
                                    <i class="fas fa-check"></i>  You get a placement with one of our clients within 6 months of enrolling.
                                </p>
                                <p style={{ color: "black" }}>
                                    <i class="fas fa-check"></i>  Once you get a job, you start making regular monthly payments in easy installments.
                                </p>
                                <p style={{ color: "black" }}>
                                    <i class="fas fa-check"></i>  If we are not able to find you a suitable job in 6 months, we will refund the remaining loan amount and terminate the contract.
                                </p>
                            </div>


                        </div>

                    </div>

                </div>

            </div>

            <Footer />
        </div>
    )
}
