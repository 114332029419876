import React from 'react'
import Footer from '../Header/Footer'
import Header from '../Header/Header'

export const Techtalent = () => {
    return (
        <div>

            <Header />

            <section class="hero-section niwax-lp1" data-background="images/banner/niwax-lpbg.jpg" id="home">
                <div class="text-block">
                    <div class="container">
                        <div class="row v-center justify-content-between">
                            <div class="col-lg-6">
                                <div class="header-headings">
                                    <span class="text-effect-1">Our Techie</span>
                                    <h1 class="wow fadeIn mt20 mb30 cd-headline clip" data-wow-delay=".4s">Creative Talent We Create
                                        <span class="cd-words-wrapper"> <b class="is-visible">Websites</b> <b>Apps</b> <b>Graphics</b> <b>Brandings</b> <b>Marketings</b> </span> </h1>
                                    <p class="wow fadeIn" data-wow-delay=".8s">Niwax is one of the most creative and experienced mobile app development companies in India.</p>

                                    <div class="niwaxbtnvideo mt30">
                                        <a href="#" class="niwax-btn2">GET STARTED<i class="fas fa-chevron-right fa-icon"></i></a>
                                        <div class="video-btn  d-flex v-center gap10">
                                            <div class="video-intro-ppx"><a class="video-link play-video" href="https://www.youtube.com/watch?v=SZEflIVnhH8?autoplay=1&rel=0"><span class="triangle-play"></span></a></div>
                                            <div class="title-hero">
                                                <p>Play Video</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-5 v-center">
                                <div class="form-block formcover shadow">
                                    <h4>Request a Free Quote</h4>
                                    <form id="contactForm" data-bs-toggle="validator" class="shake mt30">
                                        <div class="row">
                                            <div class="form-group col-sm-6">
                                                <input type="text" id="name" placeholder="Enter name" required data-error="Please fill Out" />
                                                <div class="help-block with-errors"></div>
                                            </div>
                                            <div class="form-group col-sm-6">
                                                <input type="email" id="email" placeholder="Enter email" required />
                                                <div class="help-block with-errors"></div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="form-group col-sm-6">
                                                <input type="text" id="mobile" placeholder="Enter mobile" required data-error="Please fill Out" />
                                                <div class="help-block with-errors"></div>
                                            </div>
                                            <div class="form-group col-sm-6">
                                                <select name="Dtype" id="Dtype" required>
                                                    <option value="">Select Requirement</option>
                                                    <option value="web">web</option>
                                                    <option value="graphic">graphic</option>
                                                    <option value="video">video</option>
                                                </select>
                                                <div class="help-block with-errors"></div>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <textarea id="message" rows="5" placeholder="Enter your message" required></textarea>
                                            <div class="help-block with-errors"></div>
                                        </div>
                                        <button type="submit" id="form-submit" class="btn btn-main bg-btn3">Submit</button>
                                        <div id="msgSubmit" class="h3 text-center hidden"></div>
                                        <div class="clearfix"></div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            < section class="dg-service2 pad-tb" id="services">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-8">
                            <div class="common-heading text-center">
                                <span class="text-effect-2">Tech Talents</span>
                                <h2 class="mb30">Our Design & Development Services</h2>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-lg-4 col-sm-6 mt30 wow fadeIn" data-wow-delay=".2s">
                            <div class="isotope_item h-scl-">
                                <div class="item-image h-scl-base">
                                    <a href="#"><img src="images/portfolio/servc-1.jpg" alt="portfolio" class="img-fluid" /> </a>
                                </div>
                                <div class="item-info">
                                    <h4><a href="#">Web Design</a></h4>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 mt30 wow fadeIn" data-wow-delay=".5s">
                            <div class="isotope_item h-scl-">
                                <div class="item-image h-scl-base">
                                    <a href="#"><img src="images/portfolio/servc-2.jpg" alt="portfolio" class="img-fluid" /> </a>
                                </div>
                                <div class="item-info">
                                    <h4><a href="#">UI/UX Design</a></h4>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 mt30 wow fadeIn" data-wow-delay=".8s">
                            <div class="isotope_item h-scl-">
                                <div class="item-image h-scl-base">
                                    <a href="#"><img src="images/portfolio/servc-3.jpg" alt="portfolio" class="img-fluid" /> </a>
                                </div>
                                <div class="item-info">
                                    <h4><a href="#">Product Design</a></h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="-cta-btn mt70">
                        <div class="free-cta-title v-center wow zoomInDown" data-wow-delay=".9s">
                            <p>Hire a <span>Dedicated Developer</span></p>
                            <a href="#" class="niwax-btn2">Hire Developer<i class="fas fa-chevron-right fa-icon"></i></a>
                        </div>
                    </div>
                </div>
            </section>

            <div class="niwax-company-awards-cover">
                <div class="container">
                    <div class="row v-center">
                        <div class="companytime col-lg-3">
                            <p>20 Yesrs* <span>Industry Experience</span></p>
                        </div>
                        <div class="companyawards col-lg-9">
                            <div class="niwax-logo-awards niwax-logo-slider owl-carousel">
                                <div class="industry-workfors">
                                    <img src="images/icons/house.svg" alt="img" />
                                    <h6>Real estate</h6>
                                </div>
                                <div class="industry-workfors">
                                    <img src="images/icons/travel-case.svg" alt="img" />
                                    <h6>Tour &amp; Travels</h6>
                                </div>
                                <div class="industry-workfors">
                                    <img src="images/icons/video-tutorials.svg" alt="img" />
                                    <h6>Education</h6>
                                </div>
                                <div class="industry-workfors">
                                    <img src="images/icons/taxi.svg" alt="img" />
                                    <h6>Transport</h6>
                                </div>
                                <div class="industry-workfors">
                                    <img src="images/icons/event.svg" alt="img" />
                                    <h6>Event</h6>
                                </div>
                                <div class="industry-workfors">
                                    <img src="images/icons/smartphone.svg" alt="img" />
                                    <h6>eCommerce</h6>
                                </div>
                                <div class="industry-workfors">
                                    <img src="images/icons/joystick.svg" alt="img" />
                                    <h6>Game</h6>
                                </div>
                                <div class="industry-workfors">
                                    <img src="images/icons/healthcare.svg" alt="img" />
                                    <h6>Healthcare</h6>
                                </div>
                                <div class="industry-workfors">
                                    <img src="images/icons/money-growth.svg" alt="img" />
                                    <h6>Finance</h6>
                                </div>
                                <div class="industry-workfors">
                                    <img src="images/icons/baker.svg" alt="img" />
                                    <h6>Restaurant</h6>
                                </div>
                                <div class="industry-workfors">
                                    <img src="images/icons/mobile-app.svg" alt="img" />
                                    <h6>On-Demand</h6>
                                </div>
                                <div class="industry-workfors">
                                    <img src="images/icons/groceries.svg" alt="img" />
                                    <h6>Grocery</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}
