import React from 'react'
import Footer from './Header/Footer'
import Header from './Header/Header'
import Main from './Header/Main'
import Tech1 from './Footerpage/Tech1'

function home() {
    return (
        <>
            <Header /> 
            <Main />
            <Footer />
        </>
    )
}

export default home
