import React from 'react'
import Footer from '../Header/Footer'
import Header from '../Header/Header'

export const Skill = () => {
    return (
        <div>

            <Header />

            <section class="hero-section niwax-lp2" id="home">
                <div class="niwax-lp-img nlp2 niwax" data-rellax-speed="-2"><img src="images/hero/niwax-lp-2b.svg" alt="" /></div>
                <div class="niwax-lp-img nlp1 niwax" data-rellax-speed="4"><img src="images/hero/niwax-lp-2a.svg" alt="" /></div>
                <div class="niwax-lp-img nlp4 niwax" data-rellax-speed="2"><img src="images/hero/niwax-lp-2d.svg" alt="" /></div>
                <div class="niwax-lp-img nlp3 niwax" data-rellax-speed="3"><img src="images/hero/niwax-lp-2c.svg" alt="" /></div>
                <div class="text-block">
                    <div class="container">
                        <div class="row v-center">
                            <div class="col-lg-7">
                                <div class="header-headings">
                                    <span class="text-effect-1">Solution-Oriented Services</span>
                                    <h1 class="wow fadeIn mt20 mb30" data-wow-delay=".4s">The most straightforward payment solution for your company's requirements</h1>

                                    <ul class="service-point-2 mt20 wow fadeIn" data-wow-delay=".8s">
                                        <li># 800+ Mobile Delivered</li>
                                        <li># 200+ Team Strength</li>
                                        <li># User-Friendly Interface</li>
                                        <li># 400 Happy Clients</li>
                                        <li># 95% Repeat business</li>
                                        <li># Quality Service UX</li>
                                    </ul>

                                    <div class="niwaxbtnvideo mt40 wow fadeIn" data-wow-delay="1s">
                                        <a href="#" class="niwax-btn2">GET STARTED<i class="fas fa-chevron-right fa-icon"></i></a>
                                        <div class="video-btn  d-flex v-center gap10">
                                            <div class="video-intro-ppx"><a class="video-link play-video" href="https://www.youtube.com/watch?v=SZEflIVnhH8?autoplay=1&rel=0"><span class="triangle-play"></span></a></div>
                                            <div class="title-hero">
                                                <p>Play Video</p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="col-lg-5 v-center">
                                <div class="niwax-lpimg-2">
                                    <img src="images/hero/payment.svg" alt="img" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="dg-service2 pad-tb" id="services">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-7">
                            <div class="common-heading text-center">
                                <span class="text-effect-2">Our Best Services</span>
                                <h2 class="mb30">Why Should You Use Niwax Payment Service?</h2>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center">

                        <div class="col-lg-3 col-sm-6 mt30 wow fadeIn niwax" data-wow-delay=".2s" data-rellax-speed=".5" data-rellax-percentage=".5">
                            <div class="s-block text-l hoverwithbg up-hor animation">
                                <div class="nx-circl-icon nxlp-a"> <img src="images/icons/integration.png" alt="icon" /> </div>
                                <h4><a href="#">Easy Integration</a></h4>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                <a href="#">More Details</a>
                            </div>
                        </div>

                        <div class="col-lg-3 col-sm-6 mt30 wow fadeIn niwax" data-wow-delay=".2s" data-rellax-speed="-.5" data-rellax-percentage=".5">
                            <div class="s-block text-l hoverwithbg up-hor animation">
                                <div class="nx-circl-icon nxlp-b"> <img src="images/icons/integration.png" alt="icon" /> </div>
                                <h4><a href="#">Rate of Transaction</a></h4>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                <a href="#">More Details</a>
                            </div>
                        </div>

                        <div class="col-lg-3 col-sm-6 mt30 wow fadeIn niwax" data-wow-delay=".2s" data-rellax-speed=".5" data-rellax-percentage=".5">
                            <div class="s-block text-l hoverwithbg up-hor animation">
                                <div class="nx-circl-icon nxlp-c"> <img src="images/icons/integration.png" alt="icon" /> </div>
                                <h4><a href="#">Payment Process</a></h4>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                <a href="#">More Details</a>
                            </div>
                        </div>

                        <div class="col-lg-3 col-sm-6 mt30 wow fadeIn niwax" data-wow-delay=".2s" data-rellax-speed="-.5" data-rellax-percentage=".5">
                            <div class="s-block text-l hoverwithbg up-hor animation">
                                <div class="nx-circl-icon nxlp-d"> <img src="images/icons/integration.png" alt="icon" /> </div>
                                <h4><a href="#">Text Invoice</a></h4>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                <a href="#">More Details</a>
                            </div>
                        </div>



                    </div>

                    <div class="-cta-btn mt70">
                        <div class="free-cta-title v-center wow zoomInDown" data-wow-delay=".9s">
                            <p>Create Your Own<span> Payment Getway </span> Service</p>
                            <a href="#" class="niwax-btn2">Get Started<i class="fas fa-chevron-right fa-icon"></i></a>
                        </div>
                    </div>
                </div>
            </section>

            <section class="why-choos-lg pad-tb deep-dark">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="common-heading text-l">
                                <span>Who We Are</span>
                                <h2 class="mb20">Since 2000, We've Been the Most Dependable Web Design Company.</h2>
                                <p>We have produced IT solutions at a worldwide level by using the full potential of the latest disruptive technologies as one of the leading IT consulting companies in India. We have produced IT solutions at a worldwide level by using the full potential of the latest disruptive technologies as one of the leading IT consulting companies in India.</p>
                                <p>We have produced IT solutions at a worldwide level by using the full potential of the latest disruptive technologies as one of the leading IT consulting companies in India.</p>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="img-design h-scl-">
                                <div class="imgac "> <img src="images/hero/creative-ag2a.jpg" alt="creative agnecy html template" class="ag-dg1 img-fluid h-scl-base" /></div>
                                <div class="imgac h-scl-base"><img src="images/hero/creative-ag2b.jpg" alt="creative agnecy html template" class="ag-dg2 img-fluid " /></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />

        </div>
    )
}
