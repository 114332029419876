import React from 'react'
import Header from '../Header/Header'
import Footer from '../Header/Footer'
export const Course1 = () => {
    return (
        <div>

            <Header />
            <section class="breadcrumb-area banner-2">
                <div class="text-block">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12 v-center">
                                <div class="bread-inner">
                                    <div class="bread-menu">
                                        <ul>
                                            <li><a href="index.html">Home</a></li>
                                            <li><a href="#">javascript</a></li>
                                        </ul>
                                    </div>
                                    <div class="bread-title">
                                        <h2>javascript</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="reviews-block pad-tb">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-8">
                            <div class="common-heading ptag">
                                <span>javascript</span>
                                <h2>Course Overview</h2>
                                <p class="mb30">Check our customers success stories.</p>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4 mt30">
                            <div class="reviews-card pr-shadow">
                                <div class="row v-center">
                                    <div class="col"> <span class="revbx-lr"><i class="fas fa-quote-left"></i></span> </div>
                                    <div class="col"> <span class="revbx-rl"><img src="images/client/upwork-logo.png" alt="review service logo" /></span> </div>
                                </div>
                                <div class="review-text">
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                </div>
                                <div class="-client-details-">
                                    <div class="-reviewr">
                                        <img src="images/client/reviewer-a.jpg" alt="Good Review" class="img-fluid" />
                                    </div>
                                    <div class="reviewer-text">
                                        <h4>Mario Speedwagon</h4>
                                        <p>Business Owner, <small>Jaipur</small></p>
                                        <div class="star-rate">
                                            <ul>
                                                <li> <a href="javascript:void(0)" class="chked"><i class="fas fa-star" aria-hidden="true"></i></a> </li>
                                                <li> <a href="javascript:void(0)" class="chked"><i class="fas fa-star" aria-hidden="true"></i></a> </li>
                                                <li> <a href="javascript:void(0)" class="chked"><i class="fas fa-star" aria-hidden="true"></i></a> </li>
                                                <li> <a href="javascript:void(0)" class="chked"><i class="fas fa-star" aria-hidden="true"></i></a> </li>
                                                <li> <a href="javascript:void(0)" ><i class="fas fa-star" aria-hidden="true"></i></a> </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 mt30">
                            <div class="reviews-card pr-shadow">
                                <div class="row v-center">
                                    <div class="col"> <span class="revbx-lr"><i class="fas fa-quote-left"></i></span> </div>
                                    <div class="col"> <span class="revbx-rl"><img src="images/client/envato.png" alt="review service logo" /></span> </div>
                                </div>
                                <div class="review-text">
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                </div>
                                <div class="-client-details-">
                                    <div class="-reviewr">
                                        <img src="images/client/reviewer-b.jpg" alt="Good Review" class="img-fluid" />
                                    </div>
                                    <div class="reviewer-text">
                                        <h4>Petey Cruiser</h4>
                                        <p>Business Owner, <small>Jaipur</small></p>
                                        <div class="star-rate">
                                            <ul>
                                                <li> <a href="javascript:void(0)" class="chked"><i class="fas fa-star" aria-hidden="true"></i></a> </li>
                                                <li> <a href="javascript:void(0)" class="chked"><i class="fas fa-star" aria-hidden="true"></i></a> </li>
                                                <li> <a href="javascript:void(0)" class="chked"><i class="fas fa-star" aria-hidden="true"></i></a> </li>
                                                <li> <a href="javascript:void(0)" class="chked"><i class="fas fa-star" aria-hidden="true"></i></a> </li>
                                                <li> <a href="javascript:void(0)" ><i class="fas fa-star" aria-hidden="true"></i></a> </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 mt30">
                            <div class="reviews-card pr-shadow">
                                <div class="row v-center">
                                    <div class="col"> <span class="revbx-lr"><i class="fas fa-quote-left"></i></span> </div>
                                    <div class="col"> <span class="revbx-rl"><img src="images/client/freelancer-logo.png" alt="review service logo" /></span> </div>
                                </div>
                                <div class="review-text">
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                </div>
                                <div class="-client-details-">
                                    <div class="-reviewr">
                                        <img src="images/client/reviewer-c.jpg" alt="Good Review" class="img-fluid" />
                                    </div>
                                    <div class="reviewer-text">
                                        <h4>Anna Sthesia</h4>
                                        <p>Business Owner, <small>Jaipur</small></p>
                                        <div class="star-rate">
                                            <ul>
                                                <li> <a href="javascript:void(0)" class="chked"><i class="fas fa-star" aria-hidden="true"></i></a> </li>
                                                <li> <a href="javascript:void(0)" class="chked"><i class="fas fa-star" aria-hidden="true"></i></a> </li>
                                                <li> <a href="javascript:void(0)" class="chked"><i class="fas fa-star" aria-hidden="true"></i></a> </li>
                                                <li> <a href="javascript:void(0)" class="chked"><i class="fas fa-star" aria-hidden="true"></i></a> </li>
                                                <li> <a href="javascript:void(0)" ><i class="fas fa-star" aria-hidden="true"></i></a> </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </div>
    )
}
