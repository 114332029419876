import React from 'react'
import Tech1 from '../Footerpage/Tech1'
import { Blogs } from './Mains/Blogs'
import { Eight } from './Mains/Eight'
import { Five } from './Mains/Five'
import Forindividual2 from './Mains/Forindividual2'
import { Forindividuals } from './Mains/Forindividuals'
import { Four } from './Mains/Four'
import { Frst } from './Mains/Frst'
import { Offcanvas } from './Mains/Offcanvas'
import { Second } from './Mains/Second'
import { Seven } from './Mains/Seven'
import { Six } from './Mains/Six'
import { Third } from './Mains/Third'
function Main() {
    return (
        <>
            <>
                
                <Frst />
                <Five  />
                <Third />
                <Four />
                <Forindividuals />
             
                <Tech1 />
                <Eight />
                <Six />
               

                
            </>

        </>
    )
}

export default Main