import React from 'react'
import Footer from '../Footer'
import Header from '../Header'
import vision3 from './vision3.jpg'
export const Vission = () => {
    return (
        <div>
            <Header />

            <div>


                <div >
                    <img className='vision3 vision33 ' src={vision3} ></img>


                </div>


                {/* <section class="hero-section hero-creative-agenc1" data-background="images/banner/hero-creative-agenc1.jpg" id="home" style={{ marginTop: "-2rem" }}>
                    <div class="text-block">
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-7 v-center niwax" data-rellax-speed="-3">
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}

                <section className="about-lead-gen pad-tb">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div class="header-heading">
                                    <h1 class="wow fadeInUp text-effect-2" data-wow-delay=".2s">Vision Statement</h1>
                                    <p class="wow fadeInUp" data-wow-delay=".4s">
                                        Our vision is to make IT skills accessible for everyone
                                        by continued innovation in IT learning arena. We want to make
                                        positive impact in the communities using technology as a tool,
                                        and learning as drivers. We believe anyone and everyone
                                        deserves the right to be successful and our goal is to provide
                                        world's best in-class training in diverse IT skills & domains, to
                                        help our people and client for an impactful handshake. We will
                                        continue to keep our promise of making a difference in the
                                        society by delivering top-class learning methods.
                                    </p>
                                    <a href="#" class="btn-main bg-btn6 lnk wow fadeInUp" data-wow-delay=".6s">GET STARTED<i class="fas fa-chevron-right fa-icon"></i><span class="circle"></span></a>
                                </div>
                            </div>
                            <div className="col-lg-6 lead-intro-">
                                <img
                                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSm4CP6sdQoRfvhANdAk4BE_5vNd9xAxq0fig&usqp=CAU"
                                    alt="image"
                                    className="img-fluid vis vis1"
                                />
                            </div>
                        </div>
                    </div>
                </section>


                {/* <section class="dg-service2 pb120 pt120" id="services">
                    <div class="container">
                        <div class="row justify-content-center">
                            <div class="col-lg-8">
                                <div class="common-heading-2 text-center">
                                    <span class="text-effect-2">Populor Courses</span>
                                    <h2 class="mb30">We help courses with</h2>
                                </div>
                            </div>
                        </div>
                        <div class="row upset ovr-bg2 ho-gdnt">
                            <div class="col-lg-4 col-sm-6 mt30 wow fadeInUp" data-wow-delay=".2s">
                                <div class="s-block up-hor ovr-base">
                                    <div class="nn-card-set">
                                        <div class="s-card-icon"><img src="images/icons/branding.svg" alt="service" class="img-fluid" /></div>
                                        <h4>Social Media Marketing</h4>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                        <a href="javascript:void(0)">Learn More <i class="fas fa-chevron-right fa-icon"></i></a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-sm-6 mt30 wow fadeInUp" data-wow-delay=".4s">
                                <div class="s-block up-hor ovr-base">
                                    <div class="nn-card-set">
                                        <div class="s-card-icon"><img src="images/icons/development.svg" alt="service" class="img-fluid" /></div>
                                        <h4>Branding & Identity</h4>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                        <a href="javascript:void(0)">Learn More <i class="fas fa-chevron-right fa-icon"></i></a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-sm-6 mt30 wow fadeInUp" data-wow-delay=".6s">
                                <div class="s-block up-hor ovr-base">
                                    <div class="nn-card-set">
                                        <div class="s-card-icon"><img src="images/icons/app.svg" alt="service" class="img-fluid" /></div>
                                        <h4>Performance Marketing</h4>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                        <a href="javascript:void(0)">Learn More <i class="fas fa-chevron-right fa-icon"></i></a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-sm-6 mt30 wow fadeInUp" data-wow-delay=".8s">
                                <div class="s-block up-hor ovr-base">
                                    <div class="nn-card-set">
                                        <div class="s-card-icon"><img src="images/icons/marketing.svg" alt="service" class="img-fluid" /></div>
                                        <h4>Instagram Growth</h4>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                        <a href="javascript:void(0)">Learn More <i class="fas fa-chevron-right fa-icon"></i></a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-sm-6 mt30 wow fadeInUp" data-wow-delay="1s">
                                <div class="s-block up-hor ovr-base">
                                    <div class="nn-card-set">
                                        <div class="s-card-icon"><img src="images/icons/marketing.svg" alt="service" class="img-fluid" /></div>
                                        <h4>Online Branding</h4>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                        <a href="javascript:void(0)">Learn More <i class="fas fa-chevron-right fa-icon"></i></a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-sm-6 mt30 wow fadeInUp" data-wow-delay="1.2s">
                                <div class="s-block up-hor ovr-base">
                                    <div class="nn-card-set">
                                        <div class="s-card-icon"><img src="images/icons/marketing.svg" alt="service" class="img-fluid" /></div>
                                        <h4>Video Marketing</h4>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                        <a href="javascript:void(0)">Learn More <i class="fas fa-chevron-right fa-icon"></i></a>
                                    </div>
                                </div>
                            </div>
                            <div class="-cta-btn mt70">
                                <div class="free-cta-title v-center wow zoomInDown" data-wow-delay="1s">
                                    <p>Want to  <span>kick start</span> your project right now?</p>
                                    <a href="#" class="btn-main bg-btn6 lnk"> Request a proposal<i class="fas fa-chevron-right fa-icon"></i><span class="circle"></span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="about-dg- dg-bg--2 sec-dark pb130 pt130" id="about">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="common-heading-2 text-l">
                                    <span class="text-effect-2">We have Creative Courses </span>
                                    <h2 class="mb20">About Courses</h2>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500.</p>
                                    <div class="itm-media-object mt60 tilt-3d">
                                        <div class="media">
                                            <div class="img-ab- base" data-tilt data-tilt-max="20" data-tilt-speed="1000"><img src="images/icons/computers.svg" alt="icon" class="layer" /></div>
                                            <div class="media-body">
                                                <h4>Streamlined Project Management</h4>
                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc aliquet ligula nec leo elementum semper.</p>
                                            </div>
                                        </div>
                                        <div class="media mt50">
                                            <div class="img-ab- base" data-tilt data-tilt-max="20" data-tilt-speed="1000"><img src="images/icons/worker.svg" alt="icon" class="layer" /></div>
                                            <div class="media-body">
                                                <h4>A Dedicated Team of Experts</h4>
                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc aliquet ligula nec leo elementum semper.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 v-center h-scl-">
                                <div class="img-block hover-scale h-scl-base">
                                    <img src="images/about/digital-agency-dg.jpg" alt="about" class="img-fluid upset" />
                                    <div class="shape-dg-1 dg-hero-shp1"><img src="images/shape/dots-dg.png" alt="shape" class="img-fluid" /></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}


            </div>

            <Footer />
        </div>
    )
}
