import React from 'react'
import Footer from '../Footer'
import Header from '../Header'

export const Blogs = () => {
    return (
        <div>

            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <div>
                <Header />
            </div>
            <section className="blog-home pad-tb">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <div className="common-heading">
                                <span>Blogs</span>

                                <br></br>
                              
                                <h2 className="mb0">Tech Skills in Demand</h2>
                                <br></br>
                                <br></br>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div
                            className="col-lg-3 col-sm-6 single-card-item wow fadeInUp"
                            data-wow-delay=".2s"
                        >
                            <div className="isotope_item hover-scale">
                                <center> <div className="">
                                    <a href="#">
                                        <img
                                            src="https://cdn.iconscout.com/icon/premium/png-512-thumb/fullstack-developer-1176979.png?f=avif&w=256"
                                            alt="blog"
                                            className="img-fluid"
                                            style={{ width: "10rem" }}
                                        />
                                    </a>
                                    {/* <span className="category-blog">
                                        <a href="#">Java</a> <a href="#">Full Stack</a>
                                    </span> */}
                                </div>
                                    <div className="item-info blog-info">
                                        {/* <div className="entry-blog">
                                        <span className="bypost">
                                            <a href="#">
                                                <i className="fas fa-user" /> Tom Black
                                            </a>
                                        </span>
                                        <span className="posted-on">
                                            <a href="#">
                                                <i className="fas fa-clock" /> Sep 24, 2019
                                            </a>
                                        </span>
                                        <span>
                                            <a href="#">
                                                <i className="fas fa-comment-dots" /> (23)
                                            </a>
                                        </span>
                                    </div> */}
                                        <h4>
                                            <a href="#" style={{ fontSize: "1rem" }}>
                                                Full Stack Java Development Program
                                            </a>
                                        </h4>
                                        <p style={{ textAlign: "justify" }}>
                                            New to the tech world and don’t know where to start? Well, a career in tech can be hugely rewarding, and there are so many different roles you can go into with the right training.
                                        </p>
                                    </div></center>
                            </div>
                        </div>
                        <div
                            className="col-lg-3 col-sm-6 single-card-item wow fadeInUp"
                            data-wow-delay=".4s"
                        >
                            <div className="isotope_item hover-scale">
                                <center> <div className="">
                                    <a href="#">
                                        <img
                                            src="https://cdn.iconscout.com/icon/premium/png-512-thumb/quality-assurance-1686431-1431234.png?f=avif&w=256"
                                            alt="blog"
                                            className="img-fluid"
                                            style={{ width: "10rem" }}
                                        />
                                    </a>
                                    {/* <span className="category-blog">
                                        <a href="#">SDET</a> <a href="#">Automation Testing</a>
                                    </span> */}
                                </div>
                                    <div className="item-info blog-info">
                                        {/* <div className="entry-blog">
                                        <span className="bypost">
                                            <a href="#">
                                                <i className="fas fa-user" /> Tom Black
                                            </a>
                                        </span>
                                        <span className="posted-on">
                                            <a href="#">
                                                <i className="fas fa-clock" /> Sep 24, 2019
                                            </a>
                                        </span>
                                        <span>
                                            <a href="#">
                                                <i className="fas fa-comment-dots" /> (23)
                                            </a>
                                        </span>
                                    </div> */}
                                        <h4>
                                            <a href="#" style={{ fontSize: "1rem" }}>SDET Automation Testing Program</a>
                                        </h4>
                                        <p style={{ textAlign: "justify" }}>
                                            Need of SDET :
                                            Organizations have eveolved in the way they do their business. Everyone wants more but with less.
                                            Hospitality
                                            Entertainment.
                                        </p>
                                    </div></center>
                            </div>
                        </div>
                        <div
                            className="col-lg-3 col-sm-6 single-card-item wow fadeInUp"
                            data-wow-delay=".6s"
                        >
                            <div className="isotope_item hover-scale">
                                <center>   <div className="">
                                    <a href="#">
                                        <img
                                            src="https://cdn.iconscout.com/icon/premium/png-512-thumb/scrum-master-3397083-2826170.png?f=avif&w=256"
                                            alt="blog"
                                            className="img-fluid"
                                            style={{ width: "10rem" }}
                                        />
                                    </a>
                                    {/* <span className="category-blog">
                                        <a href="#">Scrum Master</a>
                                    </span> */}
                                </div>
                                    <div className="item-info blog-info">
                                        {/* <div className="entry-blog">
                                        <span className="bypost">
                                            <a href="#">
                                                <i className="fas fa-user" /> Tom Black
                                            </a>
                                        </span>
                                        <span className="posted-on">
                                            <a href="#">
                                                <i className="fas fa-clock" /> Sep 24, 2019
                                            </a>
                                        </span>
                                        <span>
                                            <a href="#">
                                                <i className="fas fa-comment-dots" /> (23)
                                            </a>
                                        </span>
                                    </div> */}
                                        <h4>
                                            <a href="#" style={{ fontSize: "1rem" }}>Scrum Master/ Project Management Program</a>
                                        </h4>
                                        <p style={{ textAlign: "justify" }}>
                                            Scrum Master vs. Project Manager: Which One Is Right For You?

                                            What Are the Differences Between Scrum Master and Project Manager?
                                        </p>
                                    </div></center>


                            </div>
                        </div>
                        <div
                            className="col-lg-3 col-sm-6 single-card-item wow fadeInUp"
                            data-wow-delay=".6s"
                        >
                            <div className="isotope_item hover-scale">
                                <center>  <div className="">
                                    <a href="#">
                                        <img
                                            src="https://cdn.iconscout.com/icon/premium/png-512-thumb/support-530-1106487.png?f=avif&w=256"
                                            alt="blog"
                                            className="img-fluid"
                                            style={{ width: "10rem" }}
                                        />
                                    </a>
                                    {/* <span className="category-blog">
                                        <a href="#">L1/L2 Support</a>
                                    </span> */}
                                </div>
                                    <div className="item-info blog-info">
                                        {/* <div className="entry-blog">
                                        <span className="bypost">
                                            <a href="#">
                                                <i className="fas fa-user" /> Tom Black
                                            </a>
                                        </span>
                                        <span className="posted-on">
                                            <a href="#">
                                                <i className="fas fa-clock" /> Sep 24, 2019
                                            </a>
                                        </span>
                                        <span>
                                            <a href="#">
                                                <i className="fas fa-comment-dots" /> (23)
                                            </a>
                                        </span>
                                    </div> */}
                                        <h4>
                                            <a href="#" style={{ fontSize: "1rem" }}>L1/L2 Support Training Program</a>
                                        </h4>
                                        <p style={{ textAlign: "justify" }}>
                                            The pre-sale support chats and emails are addressed by Level 1 technicians whose technical knowledge is limited compared to the L2 and L3 level technicians.
                                        </p>
                                    </div></center>


                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div>
                <Footer />
            </div>
        </div>
    )
}
