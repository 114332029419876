import React from 'react'
import Footer from './Footer'
import Header from './Header'

function OurCulture() {
  return (
    <div>

    <Header />

    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <div>
        <br></br>
        <br></br>

        <div>
            <img src="https://images.unsplash.com/photo-1519389950473-47ba0277781c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8c3R1ZHklMjBjdWx0dXJlfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60" alt='banner' className='col-lg-12 ppp' style={{height:"650px"}}/>
        </div>

        <div >
            <center>  <h1 style={{ color: "#0985dc",marginTop:"10px" }}>Our Culture</h1></center>

        </div>


        <section className="why-choos-lg ">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="common-heading text-l">
                            <span>What we stand for</span>
                            <h2 className="mb20">At BLIT, our core values that align, engage and inspire us.</h2>
                            <p>
                            Our values are upheld by actions from everyone in the company—so our promise to ourselves is to extend the same to everyone we work with and  that the way in which we accomplish our mission is as important, or more, than doing it.
                            </p>
                            <div className="itm-media-object mt40 tilt-3d">
                                <div className="media">
                                    <div
                                        className="img-ab- base"
                                        data-tilt=""
                                        data-tilt-max={20}
                                        data-tilt-speed={1000}
                                    >
                                        <img
                                            src="images/icons/computers.svg"
                                            alt="icon"
                                            className="layer"
                                        />
                                    </div>
                                    <div className="media-body">
                                        <h4>High-Quality Leads</h4>
                                        <p>
                                            our principles define us and our work.
                                        </p>
                                    </div>
                                </div>
                                <div className="media mt40">
                                    <div
                                        className="img-ab- base"
                                        data-tilt=""
                                        data-tilt-max={20}
                                        data-tilt-speed={1000}
                                    >
                                        <img
                                            src="images/icons/worker.svg"
                                            alt="icon"
                                            className="layer"
                                        />
                                    </div>
                                    <div className="media-body">
                                        <h4>Real Time Leads</h4>
                                        <p>
                                            We're about more than developing great talent and technology..
                                        </p>
                                    </div>
                                </div>
                                <div className="media mt40">
                                    <div
                                        className="img-ab- base"
                                        data-tilt=""
                                        data-tilt-max={20}
                                        data-tilt-speed={1000}
                                    >
                                        {" "}
                                        <img
                                            src="images/icons/deal.svg"
                                            alt="icon"
                                            className="layer"
                                        />
                                    </div>
                                    <div className="media-body">
                                        <h4>Get Consistent Leads</h4>
                                        <p>
                                            We're about more than developing great talent and technology.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="single-image">
                            {" "}
                            <img
                                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRPruYd-sy4bZaY7PYbJeI-7HYwT3e52sDTew&usqp=CAU"
                                alt="hero image"
                                className="img-fluid"

                                style={{height:"20rem"}}
                            />{" "}
                        </div>
                       
                    </div>
                </div>
            </div>
        </section>
    </div>
    <hr/>

    <Footer />

</div>
  )
}

export default OurCulture






