import React from 'react'
import Footer from '../Footer'
import Header from '../Header'
import project from '../Mains/project.jpeg'

function ScrumMaster() {
    return (
        <div>


            <Header />

            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <section id="project">
                <center> <h4 class="wow fadeInUp " data-wow-delay=".2s">Scrum Master/Project Management Program</h4></center>




                <img
                    src={project}
                    alt="image"
                    style={{ width: "100%" }}
                />
            </section>
            <hr/>
            <Footer />
        </div>
    )
}

export default ScrumMaster
