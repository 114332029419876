import React from 'react'
import Footer from '../Header/Footer'
import Header from '../Header/Header'
import train1 from './train1.png'
import training6 from './training6.gif'
export const Tech2 = () => {
    return (
        <div>

            <div>
                <Header />
            </div>

            <div>
                <br></br>
                <br></br>
                <br></br>

                <section class="hero-card-web bg-gradient12 shape-bg3" style={{ background: "linear-gradient(to right, rgb(46, 168, 205) 0%, rgb(188 188 219) 100%)" }} >
                    <div class="hero-main-rp container-fluid">
                        <div class="row">
                            <div class="col-lg-5">
                                <div class="hero-heading-sec">
                                    <h3 style={{ color: "white" }}>Attract Train and Deploy</h3>
                                    <p class="wow fadeIn" data-wow-delay="0.6s">
                                        Training programs can be created independently or with a learning administration system, with the goal of employee long-term development.
                                    </p>

                                    <p class="wow fadeIn" data-wow-delay="0.6s">
                                        Training programs can be created independently or with a learning administration system, with the goal of employee long-term development. Common training practices include orientations, classroom lectures, case studies, role playing, simulations and computer-based training, including e-learning.
                                    </p>

                                </div>
                            </div>
                            <div class="col-lg-7">
                                <div class="hero-content-sec wow fadeIn" data-wow-delay="0.8s">
                                    {/* <div class="video-intro-pp"><a class="video-link play-video" href="https://www.youtube.com/watch?v=SZEflIVnhH8?autoplay=1&amp;rel=0"><span class="triangle-play"></span></a></div> */}
                                    <div class="title-hero-oth" >

                                        <img src={train1} style={{ height: "25rem" }} ></img>
                                    </div>
                                </div>
                                <div class="hero-right-scmm">
                                    <div class="hero-service-cards wow fadeInRight" data-wow-duration="2s">
                                        <div class="owl-carousel service-card-prb">
                                            <div class="service-slide card-bg-a" data-tilt data-tilt-max="10" data-tilt-speed="1000">
                                                <a href="#">
                                                    <div class="service-card-hh">
                                                        <div class="image-sr-mm">
                                                            <img alt="custom-sport" src="images/service/vr.png" />
                                                        </div>
                                                        <div class="title-serv-c"><span>VR</span> Solution</div>
                                                    </div>
                                                </a>
                                            </div>
                                            <div class="service-slide card-bg-b" data-tilt data-tilt-max="10" data-tilt-speed="1000">
                                                <a href="#">
                                                    <div class="service-card-hh">
                                                        <div class="image-sr-mm">
                                                            <img alt="custom-sport" src="images/service/app-develop.png" />
                                                        </div>
                                                        <div class="title-serv-c"><span>Custom</span> App Solution</div>
                                                    </div>
                                                </a>
                                            </div>
                                            <div class="service-slide card-bg-c" data-tilt data-tilt-max="10" data-tilt-speed="1000">
                                                <a href="#">
                                                    <div class="service-card-hh">
                                                        <div class="image-sr-mm">
                                                            <img alt="custom-sport" src="images/service/startup.png" />
                                                        </div>
                                                        <div class="title-serv-c"><span>Startup</span> Solution</div>
                                                    </div>
                                                </a>
                                            </div>
                                            <div class="service-slide card-bg-d" data-tilt data-tilt-max="10" data-tilt-speed="1000">
                                                <a href="#">
                                                    <div class="service-card-hh">
                                                        <div class="image-sr-mm">
                                                            <img alt="custom-sport" src="images/service/car-rental.png" />
                                                        </div>
                                                        <div class="title-serv-c"><span>Car</span> Rental Solution</div>
                                                    </div>
                                                </a>
                                            </div>
                                            <div class="service-slide card-bg-e" data-tilt data-tilt-max="10" data-tilt-speed="1000">
                                                <a href="#">
                                                    <div class="service-card-hh">
                                                        <div class="image-sr-mm">
                                                            <img alt="custom-sport" src="images/service/marketing.png" />
                                                        </div>
                                                        <div class="title-serv-c"><span>Marketing</span> Solution</div>
                                                    </div>
                                                </a>
                                            </div>
                                            <div class="service-slide card-bg-f" data-tilt data-tilt-max="10" data-tilt-speed="1000">
                                                <a href="#">
                                                    <div class="service-card-hh">
                                                        <div class="image-sr-mm">
                                                            <img alt="custom-sport" src="images/service/ewallet.png" />
                                                        </div>
                                                        <div class="title-serv-c"><span>e-Wallet</span> Solution</div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <br></br>
                <br></br>

                <section class="service-section-prb pad-tb">
                    <br></br>
                    <br></br>
                    <br></br>
                    <div class="container"  >
                        <div class="row upset" >
                            <div data-tilt data-tilt-max="5" data-tilt-speed="1000" class="col-lg-6-cus wow fadeInUp" data-wow-delay=".2s" style={{ width: "40%" }}>
                                <div class="service-sec-brp srvc-bg-nx bg-gradient13 text-w">
                                    <h4 class="mb10">Employee Training and Development</h4>
                                    <p>A strategic tool for improving business outcomes by implementing internal educational programs that advance employee growth and retention..</p>

                                </div>
                            </div>

                            <div data-tilt data-tilt-max="5" data-tilt-speed="1000" class="col-lg-6-cus wow fadeInUp" data-wow-delay=".2s" style={{ width: "40%" }}>
                                <div class="service-sec-brp srvc-bg-nx bg-gradient13 text-w">
                                    <h4 class="mb10">Management Training and Development</h4>
                                    <p>The practice of growing employees into managers and managers into effective leaders by the ongoing enhancement of certain knowledge, skills and abilities..</p>

                                </div>
                            </div>


                            {/* <div data-tilt data-tilt-max="5" data-tilt-speed="1000" class="col-lg-3-cus mt30- wow fadeInUp" data-wow-delay=".8s">
                                <div class="service-sec-list srvc-bg-nx srcl3">
                                    <img src="images/icons/app.svg" alt="service" />
                                    <h5 class="mb10">Mobile App Development</h5>
                                    <ul class="-service-list">
                                        <li> <a href="#">iPhone </a> </li>
                                        <li> <a href="#">Android</a> </li>
                                        <li> <a href="#">Cross Platform </a></li>
                                    </ul>
                                    <p>Lorem Ipsum is text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500.</p>
                                </div>
                            </div>

                            <div data-tilt data-tilt-max="5" data-tilt-speed="1000" class="col-lg-3-cus mt30- wow fadeInUp" data-wow-delay=".8s">
                                <div class="service-sec-list srvc-bg-nx srcl3">
                                    <img src="images/icons/app.svg" alt="service" />
                                    <h5 class="mb10">Mobile App Development</h5>
                                    <ul class="-service-list">
                                        <li> <a href="#">iPhone </a> </li>
                                        <li> <a href="#">Android</a> </li>
                                        <li> <a href="#">Cross Platform </a></li>
                                    </ul>
                                    <p>Lorem Ipsum is text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500.</p>
                                </div>
                            </div>
                            <div data-tilt data-tilt-max="5" data-tilt-speed="1000" class="col-lg-3-cus mt30- wow fadeInUp" data-wow-delay="1s">
                                <div class="service-sec-list srvc-bg-nx srcl4">
                                    <img src="images/icons/tech.svg" alt="service" />
                                    <h5 class="mb10">Trending Technologies</h5>
                                    <ul class="-service-list">
                                        <li> <a href="#">React.JS </a> </li>
                                        <li> <a href="#">Node.JS </a> </li>
                                        <li> <a href="#"> Angular.JS </a></li>
                                    </ul>
                                    <p>Lorem Ipsum is text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500.</p>
                                </div>
                            </div> */}
                            <div >
                                <br></br>
                                <br></br>
                                <section className="about-lead-gen pad-tb"  >
                                    <div className="container" >
                                        <div className="row" >
                                            <div className="col-lg-7">
                                                <div className="common-heading text-l">
                                                    <span>Training & Development</span>
                                                    <h2 className="mb20">
                                                        Employee & Management Training Development.
                                                    </h2>
                                                    <p>
                                                        Training and development initiatives are educational activities within an organization that are designed to improve the job performance of an individual or group. These programs typically involve advancing a worker’s knowledge and skill sets and instilling greater motivation to enhance job performance.

                                                    </p>
                                                    <p className="mt10">
                                                        Training programs can be created independently or with a learning administration system, with the goal of employee long-term development. Common training practices include orientations, classroom lectures, case studies, role playing, simulations and computer-based training, including e-learning.

                                                    </p>

                                                    <p className="mt10">
                                                        Sometimes referred to as Human Resource Development (HRD), most employee training and development efforts are driven by an organization’s HRD function. These efforts are roughly divided into two types of programs:

                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-lg-5">
                                                <img
                                                    src={training6}
                                                    alt="image"

                                                    className="img-fluid"


                                                />
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                        <div class="-cta-btn mt2">
                            <div class="free-cta-title v-center zoomInDown wow" data-wow-delay="1.4s">
                                <p>Hire a <span>Dedicated Developer & Deploy</span></p>
                                <a href="#" class="btn-main bg-btn2 lnk">Hire Now<i class="fas fa-chevron-right fa-icon"></i><span class="circle"></span></a>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <div class="statistics-section bg-gradient6 pad-tb tilt3d" >
                <div class="container" >
                    <div class="row justify-content-center t-ctr">
                        <div class="col-lg-4 col-sm-6">
                            <div class="statistics">
                                <div data-tilt data-tilt-max="20" data-tilt-speed="1000" class="statistics-img">
                                    <img src="images/icons/startup.svg" alt="years" class="img-fluid" />
                                </div>
                                <div class="statnumb">
                                    <span class="counter">15</span><span>+</span>
                                    <p>Year In Business</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="statistics">
                                <div data-tilt data-tilt-max="20" data-tilt-speed="1000" class="statistics-img">
                                    <img src="images/icons/team.svg" alt="team" class="img-fluid" />
                                </div>
                                <div class="statnumb">
                                    <span class="counter">80</span><span>+</span>
                                    <p>Team Members</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row small t-ctr">
                        <div class="col-lg-3 col-sm-6">
                            <div class="statistics">
                                <div data-tilt data-tilt-max="20" data-tilt-speed="1000" class="statistics-img">
                                    <img src="images/icons/deal.svg" alt="happy" class="img-fluid" />
                                </div>
                                <div class="statnumb">
                                    <span class="counter">450</span>
                                    <p>Happy Clients</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-sm-6">
                            <div class="statistics">
                                <div data-tilt data-tilt-max="20" data-tilt-speed="1000" class="statistics-img">
                                    <img src="images/icons/computers.svg" alt="project" class="img-fluid" />
                                </div>
                                <div class="statnumb counter-number">
                                    <span class="counter">48</span><span>k</span>
                                    <p>Projects Done</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-sm-6">
                            <div class="statistics">
                                <div data-tilt data-tilt-max="20" data-tilt-speed="1000" class="statistics-img">
                                    <img src="images/icons/worker.svg" alt="work" class="img-fluid" />
                                </div>
                                <div class="statnumb">
                                    <span class="counter">95</span><span>k</span>
                                    <p>Hours Worked</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-sm-6">
                            <div class="statistics mb0">
                                <div data-tilt data-tilt-max="20" data-tilt-speed="1000" class="statistics-img">
                                    <img src="images/icons/customer-service.svg" alt="support" class="img-fluid" />
                                </div>
                                <div class="statnumb">
                                    <span class="counter">24</span><span>/</span><span class="counter">7</span>
                                    <p>Support Available</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <Footer />

        </div>
    )
}
