import React from 'react'
import Header from '../Header'
import Footer from '../Footer'
import contents5 from '../contents5.jpg'
import '../Staff.css'
export const Content = () => {
    return (
        <div>

            <div>

                <div>
                    <Header />
                </div>

                <br></br>
                <br></br>
                <br></br>


                <div id="carouselExampleSlidesOnly" class="carousel slide" data-ride="carousel"  >
                    <div class="carousel-inner">
                        <div class="carousel-item active">
                            <img class="d-block w-100  contentss contentss1 " src="https://blog.planview.com/wp-content/uploads/2020/02/Project-Planning-and-Delivery.jpg" alt="First slide" />
                        </div>

                    </div>
                </div>

                <section className="about-lead-gen pad-tb"  >
                    <div className="container" >
                        <div className="row" >
                            <div className="col-lg-7">
                                <div className="common-heading text-l">
                                    <span>Content Development & Delivery</span>

                                    <p>
                                        Considerations for content development and delivery include the medium of delivery, intentional design, and style, among other things. And knowledge of your audience should influence the choices you make. What are your objectives? Can students offer input for the determination of those objectives? What kinds of experiences will you include for learners? Will you incorporate metacognitive activities? How will you know your students are understanding your material? Is the content accessible to all learners? Is it interactive? Engaging? Flexible? There is much to ponder.
                                    </p>
                                    <p className="mt10">
                                        High-quality courses are grounded in a comprehensive understanding of learning and teaching practices and a willingness to engage students with authentic content. We’re here to help; read on:
                                    </p>


                                </div>
                            </div>
                            <div className="col-lg-5">
                                <img
                                    src="https://img.freepik.com/free-vector/content-filling-concept-making-responsive-viral-content-business-development-idea-digital-strategy-business-promotion-communication-with-customer-flat-vector-illustration_613284-2052.jpg"
                                    alt="image"

                                    className="img-fluid"


                                />
                            </div>
                        </div>
                    </div>
                </section>


                <section className="about-lead-gen " style={{ marginTop: "-3.5rem" }}>
                    <div className="container">
                        <div className='common-heading text-l'>

                            <span>Using Constructivism to Help students Build New Knowledge</span>
                            <p className="mt10">
                                Constructivist theories assert that to develop understanding and build new knowledge, learners must be actively engaged in seeking knowledge and information, not passively taking it in (Bada, 2018). As learners encounter the world around them, they build knowledge and integrate it into their existing personal knowledge base, also known as a schema.
                            </p>

                            <span>Using Student-to-Student Interactions to Boost Engagement</span>
                            <p className="mt10">

                                Credit: luckybusiness / Adobe Stock

                                Engaging students in class discussions and activities is always a challenging endeavor for instructors because there is no one-size-fits-all approach that will work to draw in everyone. Students will have varying degrees of interest in the topic, and not everyone will be able to find the same degree of relevance in the concepts presented.                                    </p>


                        </div>

                    </div>
                </section>

                <br></br>
                <br></br>
                <div>
                    <Footer />
                </div>


            </div>

        </div>
    )
}
