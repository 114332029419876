import React from 'react'
import { Link } from 'react-router-dom'
import staffing1 from './staffing1.gif'
export const Frst = () => { 
    return (
        <div>
            <section className="hero-section lead-hero">
                <div className="text-block">
                    <div className="container">
                        <div className="row" style={{ marginTop: "-2rem" }}>
                            <div className="col-sm v-center service service1" >

                                <img
                                    src="https://onlineadvantages.net/wp-content/uploads/icon-globe-cogs-database.png"
                                    alt="hero image"
                                    className="img-fluid"
                                />
                                <div className=" service service1">
                                    <h3>
                                        BRINGING THE TALENT AS A SERVICE
                                    </h3>
                                    <p style={{color:"orangered"}} >
                                        Exclusive Talent Pool For Your Hiring Need
                                    </p>

                                    <Link to="/ContactUs"
                                        className="btn-main bg-btn lnk"
                                        data-wow-delay=".6s"
                                    >
                                        Discuss Your Strategy 
                                        <i className="fas fa-chevron-right fa-icon" />
                                        <span className="circle" />
                                    </Link>
                                </div>


                            </div>
                            <div className="col-sm wow fadeIn" data-wow-delay=".4s">
                                <div className="single-image">
                                    <img
                                        src={staffing1}
                                        alt="hero image"
                                        className="img-fluid"
                                    />
                                </div>

                                <div className=" upscale upscale1">
                                    <h3>
                                          GAIN THE SKILLS AS A SERVICE
                                    </h3>
                                    <p  style={{color:"orangered"}}>
                                        Upscale Your Skills to Get Your Dream Job!
                                    </p>

                                    <Link to="/Binaryjourney"
                                        className="btn-main bg-btn lnk wow fadeInUp"
                                        data-wow-delay=".6s"
                                    >
                                        Begin Your Journey
                                        <i className="fas fa-chevron-right fa-icon" />
                                        <span className="circle" />
                                    </Link>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </section>
        </div>
    )
}
