import React, { useEffect, useState } from 'react'
import Footer from './Footer'
import Header from './Header'
import $ from 'jquery'
import binayvid1 from "../Header/binayvid1.mp4"
import binaryvid2 from "../Header/binaryvid2.mp4"
import binaryvid3 from "../Header/binaryvid3.mp4"
import { ControlBar, Player, BigPlayButton, PosterImage } from "video-react";
import './Testimonials.css'
export const Testimonials = () => {

    const [flag, setFlag] = useState(false);


    return (
        <>

            <Header />

            <div>
                <div class="testimonials">
                    <div class="testimonial-inner">
                        <h1 style={{ marginTop: "6rem" }}>Testimonial</h1>
                        <div class="border"></div>

                        <div class="row">
                            <div class="col" >
                                <div class="testimonial" >
                                    <div id="carouselExampleCaptions" class="carousel slide" data-bs-ride="carousel">
                                        <div class="carousel-inner"  >

                                            <Player
                                                playsInline
                                                fluid={true}
                                                height={500}
                                                width="100%"
                                                controls={false}


                                            >

                                                <source src={binaryvid2} />
                                                <ControlBar autoHide={false} />
                                                <BigPlayButton position="center" />

                                            </Player>
                                        </div>
                                    </div>
                                    <div class="name">Luke</div>

                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Eaque repellat aspernatur temporibus assumenda sint odio minima. Voluptate alias possimus aspernatur voluptates excepturi placeat iusto cupiditate.</p>
                                </div>
                            </div>

                            <div class="col">
                                <div class="testimonial">

                                    <div id="carouselExampleCaptions" class="carousel slide" data-bs-ride="carousel">
                                        <div class="carousel-inner">

                                            <Player
                                                playsInline
                                                fluid={true}
                                                height={500}
                                                width="100%"
                                                controls={false}

                                            >

                                                <source src={binaryvid2} />
                                                <ControlBar autoHide={false} />
                                                <BigPlayButton position="center" />
                                            </Player>


                                        </div>
                                    </div>
                                    <div class="name">Luke</div>

                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Eaque repellat aspernatur temporibus assumenda sint odio minima. Voluptate alias possimus aspernatur voluptates excepturi placeat iusto cupiditate.</p>
                                </div>
                            </div>

                            <div class="col">
                                <div class="testimonial">

                                    <div id="carouselExampleCaptions" class="carousel slide" data-bs-ride="carousel">
                                        <div class="carousel-inner">

                                            <Player
                                                playsInline
                                                fluid={true}
                                                height={500}
                                                width="100%"
                                                controls={false}

                                            >

                                                <source src={binaryvid3} />
                                                <ControlBar autoHide={false} />
                                                <BigPlayButton position="center" />
                                            </Player>


                                        </div>
                                    </div>
                                    <div class="name">James </div>

                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Eaque repellat aspernatur temporibus assumenda sint odio minima. Voluptate alias possimus aspernatur voluptates excepturi placeat iusto cupiditate!</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



            </div>


            <Footer />

        </>
    )
}
