import React from 'react'
import Header from '../Header/Header'
import Footer from '../Header/Footer'
export const Aboutus = () => {
    return (
        <div>

            <Header />


            <div>
                <section class="breadcrumb-area text-l banner-1"  >
                    <div class="text-block" >
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-5 v-center">
                                    <div class="bread-inner">
                                        <div class="bread-menu" id="#b1">
                                            <ul>
                                                <li><a href="index.html">Home</a></li>
                                                <li><a href="#">About Us</a></li>
                                            </ul>
                                        </div>
                                        <div class="bread-title">
                                            <h2>About Binary Logic </h2>
                                            <p class="pt20">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. </p>
                                            <a href="#" class="btn-main bg-btn lnk mt30">Contact Us <i class="fas fa-chevron-right fa-icon"></i><span class="circle"></span></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-7 v-center">
                                    <div class="img-design h-scl-">
                                        <div class="imgac "> <img src="images/banner/office-view-2.jpg" alt="creative agnecy html template" class="ag-dg1 img-fluid h-scl-base" /></div>
                                        <div class="imgac h-scl-base"><img src="images/banner/office-view-1.jpg" alt="creative agnecy html template" class="ag-dg2 img-fluid " /></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="about-agencys pad-tb block-1">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-5 v-center">
                                <div class="image-block upset bg-shape wow fadeIn">
                                    <img src="images/about/cheerful-businessman.jpg" alt="about us Niwax" class="img-fluid" />
                                </div>
                            </div>
                            <div class="col-lg-7">
                                <div class="common-heading text-l">
                                    <span>Chairman's message</span>
                                    <h2 class="mb0">Team Smile</h2>
                                    <p class="niwax21">Founder, Chairman & CFO</p>
                                    <p class="pt20"><i class="fas fa-quote-left"></i> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. Lorem Ipsum is simply dummy text of the printing and typesetting industry.  Lorem Ipsum is simply dummy text of the printing and typesetting industry. is simply dummy text of the printing and typesetting industry. <i class="fas fa-quote-right"></i> </p>
                                    <a href="#" class="btn-main bg-btn3 lnk mt30">Meet Our Team <i class="fas fa-chevron-right fa-icon"></i><span class="circle"></span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <Footer />
        </div>
    )
}
