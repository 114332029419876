import React, { useState, useEffect } from 'react'
import axios from 'axios';


export const Offcanvas = () => {

    const [FullName, setFULLNAME] = useState("");
    const [Email, setEMAIL] = useState("");
    const [Mobile, setMOBILE] = useState("");

    const [CompanyName, setCOmpanyName] = useState("");
    const [DOB, setDOBs] = useState("");

    const [Message, setMEssage] = useState([]);
    
    const auth = localStorage.getItem('loginToken')
    const touchId = localStorage.getItem('Idss')
    const [open, setOPen] = useState('');
    let RequirementId = localStorage.getItem('Id')
    const toggle = (event) => {
        const getOption = event.target.value;
        setOPen(getOption);
    };
    //for select Requirment dropdown
    const [Requirement, setRequirement] = useState([]);
    const getSelectDown = async () => {
        const { data } = await axios.get('https://binaryadmin.organicdeal.in/api/InqueryAPI/Requirement');
        setRequirement(data.resut);
    }
    useEffect(() => {
        getSelectDown();
    }, []);

    //for connect with platform api
    const [connect, setConnect] = useState([]);
    const getConnectteam = async () => {
        const { data } = await axios.get('https://binaryadmin.organicdeal.in/api/InqueryAPI/Touch');
        setConnect(data.resut);
    }
    useEffect(() => {
        getConnectteam();
    }, []);


    function letconnect() {

        console.warn({ FullName, Email, Mobile, RequirementId, CompanyName, DOB, Message, touchId });

        let data = { FullName, Email, Mobile, RequirementId, CompanyName, DOB, Message, touchId }

        fetch("https://binaryadmin.organicdeal.in/api/InqueryAPI/LetConnect",
            {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${auth}`,
                    "Content-Type": "application/json",

                },
                body: JSON.stringify(data)
            }).then((result) => {
                console.warn("result", result);
            })
    }


    return (
        <div>
            <div
                className="niwaxofcanvas offcanvas offcanvas-end"
                tabIndex={-1}
                id="offcanvasExample"
            >
                <div className="offcanvas-body">
                    <div className="cbtn animation">
                        <div className="btnclose">
                            {" "}
                            <button
                                type="button"
                                className="btn-close text-reset"
                                data-bs-dismiss="offcanvas"
                                aria-label="Close"
                            />
                        </div>
                    </div>
                    <div className="form-block sidebarform">
                        <h4>Let’s Connect</h4>
                        <p>Enter the below Details</p>
                        <form
                            id="contactForm"
                            data-bs-toggle="validator"
                            className="shake mt20"
                        >
                            <div className="row">
                                <div className="form-group col-sm-12">
                                    <input

                                        value={FullName} onChange={(e) => { setFULLNAME(e.target.value) }}
                                        type="text"
                                        id="name"
                                        placeholder="Enter name"
                                        required=""
                                        data-error="Please fill Out"
                                    />
                                    <div className="help-block with-errors" />
                                </div>
                                <div className="form-group col-sm-12">
                                    <input

                                        value={Email} onChange={(e) => { setEMAIL(e.target.value) }}
                                        type="email"
                                        id="email"
                                        placeholder="Enter email"
                                        required=""
                                    />
                                    <div className="help-block with-errors" />
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group col-sm-12">
                                    <input

                                        value={Mobile} onChange={(e) => { setMOBILE(e.target.value) }}
                                        type="text"

                                        placeholder="Enter mobile"
                                        required=""
                                        data-error="Please fill Out"
                                    />
                                    <div className="help-block with-errors" />
                                </div>
                            </div>



                            <div className="form-group col-sm-12 ppp">
                                <select name="Dtype" onChange={(e) => (toggle(e))} >
                                    <option value="1" >Select Requirement</option>

                                    {Requirement?.map((item, index) => {

                                        return (
                                            <>


                                                <option value="Id:1" key={item.Id}>{item.Requirements}</option>
                                                {localStorage.setItem('Id', item.Id)}
                                            </>
                                        )
                                    })}
                                </select>
                                <div className="help-block with-errors" />
                            </div>

                            <div>

                                {open === 'Id:1' && (
                                    <div className="form-group col-sm-12 ppp">
                                        <input

                                            value={CompanyName} onChange={(e) => { setCOmpanyName(e.target.value) }}
                                            type="text"

                                            placeholder="Company Name"
                                            required=""
                                            data-error="Please fill Out"
                                        />
                                        <div className="help-block with-errors" />
                                    </div>
                                )}
                            </div>





                            <div className="form-group col-sm-12 ppp">
                                <label>Date of Birth:</label>
                                <input

                                    value={DOB} onChange={(e) => { setDOBs(e.target.value) }}
                                    type="date"
                                    required=""
                                    data-error="Please fill Out"
                                />
                                <div className="help-block with-errors" />
                            </div>





                            <div className="form-group">
                                <textarea

                                    value={Message} onChange={(e) => { setMEssage(e.target.value) }}
                                    id="message"
                                    rows={5}
                                    placeholder="Enter your message"
                                    required=""
                                    defaultValue={""}
                                />
                                <div className="help-block with-errors" />
                            </div>


                            <div>
                                <label>How would you like to Get in touch.</label>
                                {connect?.map((item, index) => {

                                    return (
                                        <div className='d-flex'>
                                            <input type="radio" name="fav_language" value="HTML" style={{ width: "11px" }} />
                                            <p className='mt-2 ml-2'>{item.Touchs}</p>

                                            {localStorage.setItem('Idss', item.Id)}
                                        </div>
                                    )
                                })}




                            </div>

                            <button
                                type="button"
                                id="form-submit"
                                className="btn lnk btn-main bg-btn text-white"

                                disabled={!FullName  } onClick={() => letconnect()}
                            >
                                Submit <span className="circle" />
                            </button>
                            <div id="msgSubmit" className="h3 text-center hidden" />
                            <div className="clearfix" />
                        </form>
                    </div>

                </div>
            </div>
        </div>
    )
}
