import React from 'react'
import Footer from '../Header/Footer'
import Header from '../Header/Header'
import { Link } from 'react-router-dom'
import human1 from '../Header/Mains/human1.png'
import human2 from '../Header/Mains/human2.png'
import femaleicon from '../Header/Mains/femaleicon.png'
export default function Tech1() {
    return (
        <>
            <div>
                <Header />
            </div>

            <div>
                <section class="hero-card-web bg-gradient12 shape-bg3" style={{ background: "linear-gradient(to right, #031d27 0%, #2196F3 100%)" }}>
                    <div class="hero-main-rp container-fluid">
                        <div class="row">
                            <div >
                                <div class="hero-heading-sec">
                                    <center><h2 class="" data-wow-delay="0.3s"><span>Learn the skills employers are looking for. </span> </h2></center>
                                    <div class="row" >

                                        <br></br>
                                        <br></br>

                                        <div class="col-sm learn1 learn">

                                            <center> <i class="fas fa-signal" style={{ fontSize: "4rem", color: "white" }}></i><br></br><br></br>

                                                <h5 style={{ color: "white" }}>Acelerate your learning curve</h5></center>

                                        </div>

                                        <div class="col-sm learn1 learn">
                                            <center> <i class="fa fa-trophy" style={{ fontSize: "4rem", color: "white" }}></i><br></br><br></br>
                                                <h5 style={{ color: "white" }}>Become a Skilled IT Professional</h5></center>

                                        </div>

                                        <div class="col-sm learn1 learn">
                                            <center> <i class="fa fa-briefcase" style={{ fontSize: "4rem", color: "white" }}></i><br></br><br></br>
                                                <h5 style={{ color: "white" }}> Guaranteed job offer </h5></center>

                                        </div>

                                    </div>


                                </div>
                            </div>
                            {/* <div class="col-lg-7">

                                <div class="hero-right-scmm">
                                    <div class="hero-service-cards wow fadeInRight" data-wow-duration="2s">
                                        <div class="owl-carousel service-card-prb">
                                            <div class="service-slide card-bg-a" data-tilt data-tilt-max="10" data-tilt-speed="1000">
                                                <a href="#">
                                                    <div class="service-card-hh">
                                                        <div class="image-sr-mm">
                                                            <img alt="custom-sport" src="images/service/vr.png" />
                                                        </div>
                                                        <div class="title-serv-c"><span>VR</span> Solution</div>
                                                    </div>
                                                </a>
                                            </div>
                                            <div class="service-slide card-bg-b" data-tilt data-tilt-max="10" data-tilt-speed="1000">
                                                <a href="#">
                                                    <div class="service-card-hh">
                                                        <div class="image-sr-mm">
                                                            <img alt="custom-sport" src="images/service/app-develop.png" />
                                                        </div>
                                                        <div class="title-serv-c"><span>Custom</span> App Solution</div>
                                                    </div>
                                                </a>
                                            </div>
                                            <div class="service-slide card-bg-c" data-tilt data-tilt-max="10" data-tilt-speed="1000">
                                                <a href="#">
                                                    <div class="service-card-hh">
                                                        <div class="image-sr-mm">
                                                            <img alt="custom-sport" src="images/service/startup.png" />
                                                        </div>
                                                        <div class="title-serv-c"><span>Startup</span> Solution</div>
                                                    </div>
                                                </a>
                                            </div>
                                            <div class="service-slide card-bg-d" data-tilt data-tilt-max="10" data-tilt-speed="1000">
                                                <a href="#">
                                                    <div class="service-card-hh">
                                                        <div class="image-sr-mm">
                                                            <img alt="custom-sport" src="images/service/car-rental.png" />
                                                        </div>
                                                        <div class="title-serv-c"><span>Car</span> Rental Solution</div>
                                                    </div>
                                                </a>
                                            </div>
                                            <div class="service-slide card-bg-e" data-tilt data-tilt-max="10" data-tilt-speed="1000">
                                                <a href="#">
                                                    <div class="service-card-hh">
                                                        <div class="image-sr-mm">
                                                            <img alt="custom-sport" src="images/service/marketing.png" />
                                                        </div>
                                                        <div class="title-serv-c"><span>Marketing</span> Solution</div>
                                                    </div>
                                                </a>
                                            </div>
                                            <div class="service-slide card-bg-f" data-tilt data-tilt-max="10" data-tilt-speed="1000">
                                                <a href="#">
                                                    <div class="service-card-hh">
                                                        <div class="image-sr-mm">
                                                            <img alt="custom-sport" src="images/service/ewallet.png" />
                                                        </div>
                                                        <div class="title-serv-c"><span>e-Wallet</span> Solution</div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </section>


            </div>

            <br></br>
            <br></br>
            <section className="about-lead-gen ">



                <center > <Link to="/Alumini"> <h3 style={{ color: "#0985dc" }}>Hear the Success Stories</h3> </Link></center>
                <br></br>
                <br></br>
                <div className='row' style={{ marginLeft: "2rem", marginRight: "2rem" }}>
                    <div className='col-sm' style={{ background: "lavenderblush" }}>

                        <center> <img src={human1} style={{ height: "10rem" }} />
                            <h6>Dialo-Infosys
                            </h6>
                            <h6> Position - Technology Analyst
                            </h6>
                        </center>
                        <p style={{ textAlign: "justify" }}>
                            The right place to learn software engineering from professionals, whether in the classroom or remotely.
                            You can be deployed in the job market and find your dream job right after your training. I was lucky to
                            find Binary Logic and grateful to the trainers and the human ressources employees
                        </p>


                    </div>

                    <div className='col-sm' style={{ background: "aliceblue;" }}>

                        <center> <img src={human2} style={{ height: "10rem" }} />
                            <h6>Mustafa- CVS Health  </h6>
                            <h6>Position - Spring boot Developer  </h6>
                        </center>
                        <p style={{ textAlign: "justify" }}>
                            It was a wonderful training experience with BLIT. They staff are very passionate and provide extensive
                            training. They did a great job in making sure that we all understood and additional information was given
                            on topic that were hard to grasp, which was very helpful. Would highly recommend BLIT.
                        </p>
                    </div>

                    <div className='col-sm' style={{ background: "aliceblue" }}>

                        <center> <img src={femaleicon} style={{ height: "10rem" }} />
                            <h6>Julian - booz Allen Hamilton  </h6>
                            <h6>position - Soft. Developer  </h6>
                        </center>
                        <p style={{ textAlign: "justify" }}>
                            I made the best career choice possible by joining BLIT. The staff is without a doubt the kindest set of
                            individuals I have ever encountered. I've developed relationships that will undoubtedly last a lifetime.
                        </p>

                    </div>
                </div>
            </section>


            {/* <div class="statistics-section bg-gradient6 pad-tb tilt3d">
                <div class="container">
                    <div class="row justify-content-center t-ctr">
                        <div class="col-lg-4 col-sm-6">
                            <div class="statistics">
                                <div data-tilt data-tilt-max="20" data-tilt-speed="1000" class="statistics-img">
                                    <img src="images/icons/startup.svg" alt="years" class="img-fluid" />
                                </div>
                                <div class="statnumb">
                                    <span class="counter">15</span><span>+</span>
                                    <p>Year In Business</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="statistics">
                                <div data-tilt data-tilt-max="20" data-tilt-speed="1000" class="statistics-img">
                                    <img src="images/icons/team.svg" alt="team" class="img-fluid" />
                                </div>
                                <div class="statnumb">
                                    <span class="counter">80</span><span>+</span>
                                    <p>Team Members</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row small t-ctr">
                        <div class="col-lg-3 col-sm-6">
                            <div class="statistics">
                                <div data-tilt data-tilt-max="20" data-tilt-speed="1000" class="statistics-img">
                                    <img src="images/icons/deal.svg" alt="happy" class="img-fluid" />
                                </div>
                                <div class="statnumb">
                                    <span class="counter">450</span>
                                    <p>Happy Clients</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-sm-6">
                            <div class="statistics">
                                <div data-tilt data-tilt-max="20" data-tilt-speed="1000" class="statistics-img">
                                    <img src="images/icons/computers.svg" alt="project" class="img-fluid" />
                                </div>
                                <div class="statnumb counter-number">
                                    <span class="counter">48</span><span>k</span>
                                    <p>Projects Done</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-sm-6">
                            <div class="statistics">
                                <div data-tilt data-tilt-max="20" data-tilt-speed="1000" class="statistics-img">
                                    <img src="images/icons/worker.svg" alt="work" class="img-fluid" />
                                </div>
                                <div class="statnumb">
                                    <span class="counter">95</span><span>k</span>
                                    <p>Hours Worked</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-sm-6">
                            <div class="statistics mb0">
                                <div data-tilt data-tilt-max="20" data-tilt-speed="1000" class="statistics-img">
                                    <img src="images/icons/customer-service.svg" alt="support" class="img-fluid" />
                                </div>
                                <div class="statnumb">
                                    <span class="counter">24</span><span>/</span><span class="counter">7</span>
                                    <p>Support Available</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

            {/* <section class="why-choos-lg pad-tb deep-dark">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="common-heading text-l">
                                <span>Why Choose Us</span>
                                <h2 class="mb20">Why The Niwax <span class="text-second text-bold">Ranked Top</span> Among The Leading Web & App Development Companies</h2>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
                                <div class="itm-media-object mt40 tilt-3d">
                                    <div class="media">
                                        <div class="img-ab- base" data-tilt data-tilt-max="20" data-tilt-speed="1000"><img src="images/icons/computers.svg" alt="icon" class="layer" /></div>
                                        <div class="media-body">
                                            <h4>Streamlined Project Management</h4>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc aliquet ligula nec leo elementum semper. Mauris aliquet egestas metus.</p>
                                        </div>
                                    </div>
                                    <div class="media mt40">
                                        <div class="img-ab- base" data-tilt data-tilt-max="20" data-tilt-speed="1000"><img src="images/icons/worker.svg" alt="icon" class="layer" /></div>
                                        <div class="media-body">
                                            <h4>A Dedicated Team of Experts</h4>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc aliquet ligula nec leo elementum semper. Mauris aliquet egestas metus.</p>
                                        </div>
                                    </div>
                                    <div class="media mt40">
                                        <div class="img-ab- base" data-tilt data-tilt-max="20" data-tilt-speed="1000"> <img src="images/icons/deal.svg" alt="icon" class="layer" /></div>
                                        <div class="media-body">
                                            <h4>Completion of Project in Given Time</h4>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc aliquet ligula nec leo elementum semper. Mauris aliquet egestas metus.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div data-tilt data-tilt-max="5" data-tilt-speed="1000" class="single-image bg-shape-dez wow fadeIn" data-wow-duration="2s"><img src="images/about/about-company.jpg" alt="image" class="img-fluid" /></div>
                            <p class="text-center mt30">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
                            <div class="cta-card mt60 text-center">
                                <h3 class="mb20">Let's Start a  <span class="text-second text-bold">New Project</span> Together</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc aliquet ligula nec leo elementum semper.</p>
                                <a href="#" class="niwax-btn1 mt30"><span>Request A Quote <i class="fas fa-chevron-right fa-icon"></i></span></a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="portfolio-section pad-tb">
                <div class="container">
                    <div class="row justify-content-center ">
                        <div class="col-lg-8">
                            <div class="common-heading">
                                <span>Our Work</span>
                                <h2 class="mb0">Our Latest Creative Work</h2>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-8 col-sm-8 mt60 wow fadeInUp" data-wow-delay="0.2s">
                            <div class="isotope_item hover-scale">
                                <div class="item-image" data-tilt data-tilt-max="2" data-tilt-speed="1000">
                                    <a href="#"><img src="images/portfolio/image-d.jpg" alt="image" class="img-fluid" /> </a>
                                </div>
                                <div class="item-info">
                                    <h4><a href="#">Ecommerce Development</a></h4>
                                    <p>Web Application</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-4 mt60 wow fadeInUp" data-wow-delay="0.4s">
                            <div class="isotope_item hover-scale">
                                <div class="item-image" data-tilt data-tilt-max="2" data-tilt-speed="1000">
                                    <a href="#"><img src="images/portfolio/image-1.jpg" alt="image" class="img-fluid" /> </a>
                                </div>
                                <div class="item-info">
                                    <h4><a href="#">Creative App</a></h4>
                                    <p>iOs, Android</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-4 mt60 wow fadeInUp" data-wow-delay="0.6s">
                            <div class="isotope_item hover-scale">
                                <div class="item-image" data-tilt data-tilt-max="2" data-tilt-speed="1000">
                                    <a href="#"><img src="images/portfolio/image-6.jpg" alt="image" class="img-fluid" /> </a>
                                </div>
                                <div class="item-info">
                                    <h4><a href="#">Brochure Design</a></h4>
                                    <p>Graphic, Print</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-8 col-sm-8 mt60 wow fadeInUp" data-wow-delay="0.8s">
                            <div class="isotope_item hover-scale">
                                <div class="item-image" data-tilt data-tilt-max="2" data-tilt-speed="1000">
                                    <a href="#"><img src="images/portfolio/image-c.jpg" alt="image" class="img-fluid" /> </a>
                                </div>
                                <div class="item-info">
                                    <h4><a href="#">Icon Pack</a></h4>
                                    <p>iOs, Android</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="contact-page pad-tb">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-6 v-center">
                            <div class="common-heading text-l">
                                <span>Contact Now</span>
                                <h2 class="mt0 mb0">Have Question? Write a Message</h2>
                                <p class="mb60 mt20">We will catch you as early as we receive the message</p>
                            </div>
                            <div class="form-block">
                                <form id="contactForm" data-bs-toggle="validator" class="shake">
                                    <div class="row">
                                        <div class="form-group col-sm-6">
                                            <input type="text" id="name" placeholder="Enter name" required data-error="Please fill Out" />
                                            <div class="help-block with-errors"></div>
                                        </div>
                                        <div class="form-group col-sm-6">
                                            <input type="email" id="email" placeholder="Enter email" required />
                                            <div class="help-block with-errors"></div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="form-group col-sm-6">
                                            <input type="text" id="mobile" placeholder="Enter mobile" required data-error="Please fill Out" />
                                            <div class="help-block with-errors"></div>
                                        </div>
                                        <div class="form-group col-sm-6">
                                            <select name="Dtype" id="Dtype" required>
                                                <option value="">Select Requirement</option>
                                                <option value="web">web</option>
                                                <option value="graphic">graphic</option>
                                                <option value="video">video</option>
                                            </select>
                                            <div class="help-block with-errors"></div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <textarea id="message" rows="5" placeholder="Enter your message" required></textarea>
                                        <div class="help-block with-errors"></div>
                                    </div>
                                    <button type="submit" id="form-submit" class="btn lnk btn-main bg-btn">Submit <span class="circle"></span></button>
                                    <div id="msgSubmit" class="h3 text-center hidden"></div>
                                    <div class="clearfix"></div>
                                </form>
                            </div>
                        </div>
                        <div class="col-lg-5 v-center">
                            <div class="contact-details">
                                <div class="contact-card wow fadeIn" data-wow-delay=".2s">
                                    <div class="info-card v-center">
                                        <span><i class="fas fa-phone-alt"></i> Phone:</span>
                                        <div class="info-body">
                                            <p>Assistance hours: Monday – Friday, 9 am to 5 pm</p>
                                            <a href="tel:+10000000000">(+91) 0000 000 000</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="email-card mt30 wow fadeIn" data-wow-delay=".5s">
                                    <div class="info-card v-center">
                                        <span><i class="fas fa-envelope"></i> Email:</span>
                                        <div class="info-body">
                                            <p>Our support team will get back to in 24-h during standard business hours.</p>
                                            <a href="mailto:info@businessname.com">Info@binarylogicit.com</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="skype-card mt30 wow fadeIn" data-wow-delay=".9s">
                                    <div class="info-card v-center">
                                        <span><i class="fab fa-skype"></i> Skype:</span>
                                        <div class="info-body">
                                            <p>We Are Online: Monday – Friday, 9 am to 5 pm</p>
                                            <a href="skype:niwax.company?call">binarylogicit.com</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}


        </>
    )
}
