import React from 'react'
import Header from '../Header/Header'
import Footer from '../Header/Footer'
export const Refundpolicy = () => {
    return (
        <div>

            <div>
                <Header />

                <section class="breadcrumb-area banner-1" data-background="images/banner/9.jpg">
                    <div class="text-block">
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-12 v-center">
                                    <div class="bread-inner">
                                        <div class="bread-menu">
                                            <ul>
                                                <li><a href="#">Binary Logic</a></li>
                                                <li><a href="#">Refun Policy</a></li>
                                            </ul>
                                        </div>
                                        <div class="bread-title">
                                            <h2>Refund Policy</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="about-agency pad-tb block-1">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12">

                                <div class="pvcpolcy">
                                    <h4 class="mb10"> Refund Policy?</h4>
                                    <p class="mb10">A. The Candidate will be required to pay BLIT a training and placement program fee of $18,000.00 (Eighteen Thousand Dollars Only) contemporaneously with his or her signing and delivering this Agreement to BLIT within seven business days. BLIT shall take no action on the Candidate’s application until receipt of the program fee.  </p>

                                    <p>B.If BLIT is not able to provide the placement within SIX (6)-month period from the effective date of the agreement, Program fee shall be refundable, only if the Candidate:  Attends in person training at the BLIT office for at least 320 hours. The candidate agrees to complete all weekly assessments and assignments, as instructed by the trainer, during the training duration.  Successfully completes all training and placement services offered to him or her by BLIT.   Such program fee(s) will be refunded to the candidate within 30 days from 6 months.  completion period.    </p>

                                    <p>C.Program fee shall be non-refundable if the Candidate:  Does not attend in person training at the BLIT office for at least 320 hours.  The candidate does not to complete all weekly assessments and assignments, as instructed by the trainer, during the training duration.  Successfully completes all the training and placement services offered to the candidate by BLIT and BLIT is able to provide the offer of employment with prospective employer within SIX (6)-month period from the effective date of the agreement.     </p>
                                    <p>D.Candidate acknowledges and agrees that his or her possible acceptance into BLIT’s training and placement service program is conditioned upon his or her:  Payment of the program fee.  Providing BLIT with the personal contact information requested by it. Clearing the aptitude test requested by BLIT, and BLIT’s acceptance of the Candidate into its training and placement services program.    </p>
                                    <p>E.<strong>Information and Investigation </strong>: - The Candidate represents and warrants that he/she has provided, and will continue to provide, complete, accurate, and necessary information regarding Candidate’s qualifications and background. The Candidate has provided BLIT with satisfactory evidence of his or her identity which includes, but is not limited to, a true and correct copy of the Candidate's
                                        passport or driver’s license, and a resume/curriculum vitae where such were requested by BLIT.    </p>

                                    <p>F. The Candidate has provided full and accurate information to BLIT regarding all prospective employers to whom the Candidate has already approached or been referred to through an agency other than the BLIT.    </p>

                                    <h4 class="mb10 mt30"> Cooperation</h4>

                                    <p class="mb10">  If contacted by a prospective employer referred by BLIT, the Candidate will cooperate fully in scheduling and appearing for confirmed interviews.</p>

                                    <h4 class="mb10 mt30">  Candidate’s Conduct; Professionalism</h4>

                                    <p class="mb10">The Candidate has not and will not engage in any of the following conduct(s) that:  A.Is detrimental to the interests of the BLIT.  B.Would negatively affect BLIT's relationship with any prospective employer.  C.Will bring disrepute or negatively affect BLIT's general reputation or business standing, or  D.Will negatively impact Candidate's reputation or qualification for prospective employment.  </p>

                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <hr></hr>

                <Footer />
            </div>

        </div>
    )
}
