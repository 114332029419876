import React from 'react'
import {Link} from 'react-router-dom'

export const Forindividuals = () => {
    return (
        <div>
            <section class="about-sec-rpb pad-tb">
                <div class="container">
                    <div class="row justify-content-center text-center">
                        <div class="col-lg-10">
                            <div class="common-heading">
                                <span className='text-white'>For Learners </span>
                                <h1 class="mb30 " style={{ color: "gold" }}>
                                    <span class="text-white">Explore </span>
                                    our Stacks</h1>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="service-section-prb pad-tb">
                <div class="container">
                    <div class="row upset">

                        <div data-tilt data-tilt-max="5" data-tilt-speed="1000" class=" col-sm wow fadeInUp" data-wow-delay=".4s">
                            <div class="service-sec-list srvc-bg-nx srcl1">
                                <center>  <img src="images/icons/development.svg" alt="service" /> </center>
                                <h6 class="mb10">Software Engineering </h6>
                                <ul class="-service-list" style={{ paddingLeft: "0rem" }}>
                                    <li> <a href="/Courses">Full Stack Java Development Program</a> </li>

                                </ul>
                            </div>
                        </div>
                        <div data-tilt data-tilt-max="5" data-tilt-speed="1000" class="col-sm wow fadeInUp" data-wow-delay=".6s">
                            <div class="service-sec-list srvc-bg-nx srcl2">
                                <center> <img src="images/icons/ecommerce.svg" alt="service" /> </center>
                                <h5 class="mb10">Quality Assurance </h5>
                                <ul class="-service-list" style={{ paddingLeft: "0rem" }}>
                                    <li> <a href="/Courses">SDET Automation Testing Program </a> </li>

                                </ul>
                            </div>
                        </div>
                        <div data-tilt data-tilt-max="5" data-tilt-speed="1000" class="col-sm  wow fadeInUp" data-wow-delay=".8s">
                            <div class="service-sec-list srvc-bg-nx srcl3">
                                <center> <img src="images/icons/app.svg" alt="service" /></center>
                                <h6 class="mb10">Project Management </h6>
                                <ul class="-service-list" style={{ paddingLeft: "0rem" }}>
                                    <li> <a href="/Courses">Scrum Master/ Project Management Program</a> </li>

                                </ul>
                            </div>
                        </div>
                        <div data-tilt data-tilt-max="5" data-tilt-speed="1000" class="col-sm  wow fadeInUp" data-wow-delay="1s">
                            <div class="service-sec-list srvc-bg-nx srcl4">
                                <center> <img src="images/icons/tech.svg" alt="service" /> </center>
                                <h6 class="mb10">infrastructure Support</h6>
                                <ul class="-service-list" style={{ paddingLeft: "0rem" }}>
                                    <li> <a href="/Courses">L1/L2 Support Training Program</a> </li>

                                </ul>
                            </div>
                        </div>

                    </div>
                    <div class="-cta-btn mt70">
                        <div class="free-cta-title v-center zoomInDown wow" data-wow-delay="1.4s">
                            <p>Find <span> Your Course </span></p>
                            <Link to="/forindividual2" class="btn-main bg-btn2 lnk">Click Here<i class="fas fa-chevron-right fa-icon"></i><span class="circle"></span></Link>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
