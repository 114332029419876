import React from 'react'
import binarydiff from './binarydiff.gif'
import { Link } from "react-router-dom"
export const Five = () => {
    return (
        <div>
            <br></br>
            <br></br>
            <br></br>
            <section className="why-choos-lg" >
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="common-heading text-l">
                                <span>The Binary Difference </span>

                                <div className="itm-media-object mt40 tilt-3d">
                                    <div className="media">
                                        <div
                                            className="img-ab- base"
                                            data-tilt=""
                                            data-tilt-max={20}
                                            data-tilt-speed={1000}
                                        >
                                            <img
                                                src="images/icons/computers.svg"
                                                alt="icon"
                                                className="layer"
                                            />
                                        </div>
                                        <div className="media-body">
                                            <h4>Our Culture</h4>
                                            <div>
                                                <div class="container" style={{ marginLeft: "-1.3rem" }}>
                                                    <p data-toggle="collapse" >At Binary, we are driven by the desire to make a positive difference in the society....
                                                        <Link to="/Binary">
                                                            know more
                                                        </Link>
                                                    </p>

                                                </div>

                                            </div>


                                        </div>


                                    </div>
                                    <div className="media mt40">
                                        <div
                                            className="img-ab- base"
                                            data-tilt=""
                                            data-tilt-max={20}
                                            data-tilt-speed={1000}
                                        >
                                            <img
                                                src="images/icons/worker.svg"
                                                alt="icon"
                                                className="layer"
                                            />
                                        </div>
                                        <div className="media-body">
                                            <h4>Mission Statement </h4>
                                            <div>
                                                <div class="container" style={{ marginLeft: "-1.3rem" }}>
                                                    <p data-toggle="collapse" > We are on the mission of Intensifying Talent by bridging the gap between Technology & Human.....
                                                        <Link to="/Binary">
                                                            know more
                                                        </Link>
                                                    </p>

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="media mt40">
                                        <div
                                            className="img-ab- base"
                                            data-tilt=""
                                            data-tilt-max={20}
                                            data-tilt-speed={1000}
                                        >
                                            {" "}
                                            <img
                                                src="images/icons/deal.svg"
                                                alt="icon"
                                                className="layer"
                                            />
                                        </div>
                                        <div className="media-body">
                                            <h4>Vision Statement</h4>


                                            <div>
                                                <div class="container" style={{ marginLeft: "-1.3rem" }}>
                                                    <p data-toggle="collapse" >Vision Statement
                                                        Our vision is to make IT skills accessible for .....
                                                        <Link to="/Binary">
                                                            know more
                                                        </Link>
                                                    </p>

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="single-image">
                                {" "}
                                <img
                                    src={binarydiff}
                                    alt="hero image"
                                    className="img-fluid"
                                />{" "}
                            </div>
                         
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
