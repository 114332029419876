import React from 'react'
import Footer from './Footer'
import Header from './Header'

export const Hire = () => {
    return (
        <div>

            <Header />

            <section class="hero-card-web bg-gradient12 shape-bg3">
                <div class="hero-main-rp container-fluid">
                    <div class="row">
                        <div class="col-lg-5">
                            <div class="hero-heading-sec">
                                <h2 class="wow fadeIn" data-wow-delay="0.3s"><span>
                                    Tech Skills in Demand</span> </h2>
                                <p class="wow fadeIn" data-wow-delay="0.6s">We've got a talent pool of diverse, skilled and job-ready tech professionals who have graduated from the TechTalent Academ</p>
                                <a href="#" class="niwax-btn2 wow fadeIn" data-wow-delay="0.8s">View Case Studies <i class="fas fa-chevron-right fa-ani"></i></a>
                                <div class="awards-block-tt  wow fadeIn" data-wow-delay="1s"><img src="images/hero/awards-logo.png" alt="awards-logo" class="img-fluid" /></div>
                            </div>
                        </div>
                        <div class="col-lg-7">
                            <div class="hero-content-sec wow fadeIn" data-wow-delay="0.8s">
                                <div class="video-intro-pp"><a class="video-link play-video" href="https://www.youtube.com/watch?v=SZEflIVnhH8?autoplay=1&amp;rel=0"><span class="triangle-play"></span></a></div>
                                <div class="title-hero-oth">
                                    <p>Hire certified and job-ready tech talent  <span>without the fees.</span></p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

            <section class="service-section-prb pad-tb">
                <div class="container">
                    <div class="row upset">
                        <div data-tilt data-tilt-max="5" data-tilt-speed="1000" class="col-lg-6-cus wow fadeInUp" data-wow-delay=".2s">
                            <div class="service-sec-brp srvc-bg-nx bg-gradient13 text-w">
                                <h4 class="mb10">Scholarship</h4>
                                <p>Diversity and inclusion</p>
                                <a href="javascript:void(0)" class="mt20 link-prb">Learn More <i class="fas fa-chevron-right fa-icon"></i></a>
                            </div>
                        </div>
                        <div data-tilt data-tilt-max="5" data-tilt-speed="1000" class="col-lg-3-cus wow fadeInUp" data-wow-delay=".4s">
                            <div class="service-sec-list srvc-bg-nx srcl1">
                                <img src="images/icons/development.svg" alt="service" />
                                <h5 class="mb10">Diversity  & Inclusion</h5>
                                <ul class="-service-list">
                                    <li> <a href="#">PHP</a> </li>
                                    <li> <a href="#"><strong>.</strong>Net</a> </li>
                                    <li> <a href="#">Java</a> </li>
                                    <li> <a href="#">Joomla</a></li>
                                </ul>
                                <p>Lorem Ipsum is text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500.</p>
                            </div>
                        </div>
                        <div data-tilt data-tilt-max="5" data-tilt-speed="1000" class="col-lg-3-cus wow fadeInUp" data-wow-delay=".6s">
                            <div class="service-sec-list srvc-bg-nx srcl2">
                                <img src="images/icons/ecommerce.svg" alt="service" />
                                <h5 class="mb10">Business Updates & Engagement</h5>
                                <ul class="-service-list">
                                    <li> <a href="#">Partnership Updates </a> </li>
                                    <li> <a href="#">Tech Engagement Activities</a> </li>
                                    <li> <a href="#">Global Centers </a> </li>
                                    <li> <a href="#">Student stories</a></li>
                                </ul>
                                <p>Lorem Ipsum is text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500.</p>
                            </div>
                        </div>
                        <div data-tilt data-tilt-max="5" data-tilt-speed="1000" class="col-lg-3-cus mt30- wow fadeInUp" data-wow-delay=".8s">
                            <div class="service-sec-list srvc-bg-nx srcl3">
                                <img src="images/icons/app.svg" alt="service" />
                                <h5 class="mb10">Tech Skills in Demand</h5>
                                <ul class="-service-list">
                                    <li> <a href="#">Full Stack Java Development Program </a> </li>
                                    <li> <a href="#">SDET Automation Testing Program</a> </li>
                                    <li> <a href="#">Scrum Master/Project Management Program </a></li>
                                </ul>
                                <p>Lorem Ipsum is text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500.</p>
                            </div>
                        </div>
                        <div data-tilt data-tilt-max="5" data-tilt-speed="1000" class="col-lg-3-cus mt30- wow fadeInUp" data-wow-delay="1s">
                            <div class="service-sec-list srvc-bg-nx srcl4">
                                <img src="images/icons/tech.svg" alt="service" />
                                <h5 class="mb10">Trending Technologies</h5>
                                <ul class="-service-list">
                                    <li> <a href="#">React.JS </a> </li>
                                    <li> <a href="#">Node.JS </a> </li>
                                    <li> <a href="#"> Angular.JS </a></li>
                                </ul>
                                <p>Lorem Ipsum is text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500.</p>
                            </div>
                        </div>
                        <div data-tilt data-tilt-max="5" data-tilt-speed="1000" class="col-lg-6-cus mt30- wow fadeInUp" data-wow-delay="1.2s">
                            <div class="service-sec-list srvc-bg-nx srcl5">
                                <img src="images/icons/seo.svg" alt="service" />
                                <h5 class="mb10">Digital Marketing</h5>
                                <ul class="-service-list">
                                    <li> <a href="#">SEO </a> </li>
                                    <li> <a href="#">SMO </a> </li>
                                    <li> <a href="#">PPC </a></li>
                                    <li> <a href="#">PPC </a></li>
                                </ul>
                                <p>Lorem Ipsum is text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500.</p>
                            </div>
                        </div>
                    </div>
                    <div class="-cta-btn mt70">
                        <div class="free-cta-title v-center zoomInDown wow" data-wow-delay="1.4s">
                            <p>Hire a <span>Dedicated Developer & Deploy</span></p>
                            <a href="#" class="btn-main bg-btn2 lnk">Hire Now<i class="fas fa-chevron-right fa-icon"></i><span class="circle"></span></a>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />

        </div>
    )
}
