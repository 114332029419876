import React from 'react'
import Footer from './Footer'
import Header from './Header'


export const ContactUs = () => {
    return (
        <div>

            <Header />

            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <section className="about-lead-gen pad-tb">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="common-heading text-l mt-4">
                                <span style={{ fontSize: "2.8em" }}>Fill out the given form </span>

                                <h3>Enter the details and a specialist will reach out to you.</h3>
                                <div className="servie-key-points">

                                    <ul className="key-points mt30">

                                        <li>Customizable Solutions to cater to your Problem Areas</li>

                                        <li>Request a free quote</li>
                                        <li>From start to finish, this only takes 2 minutes!</li>
                                        <h5>We promise to serve all your organization needs. No regrets!</h5>


                                    </ul>
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-6 lead-intro-">
                            <div className="col-lg-12 v-center" style={{ background: "darkgray", padding: "14px", borderRadius: "10px" }}>
                                <div className="common-heading text-l">

                                    <h4 className="text-center" style={{ color: "darkblue" }}>
                                        Tell us more About Your Company
                                    </h4>
                                </div>
                                <div className="form-block" >
                                    <form action="#" method="post" name="feedback-form" >
                                        <div className="fieldsets row">
                                            <div className="col-md-6">
                                                <input type="text" placeholder="First Name" name="name" />
                                            </div>

                                            <div className="col-md-6">
                                                <input type="text" placeholder="Last Name" name="name" />
                                            </div>

                                            <div className="col-md-12">
                                                <input
                                                    type="text"
                                                    placeholder="Company Name"
                                                    name="email"
                                                />
                                            </div>
                                            <div className="col-md-12">
                                                <input
                                                    type="text"
                                                    placeholder="Email Address"
                                                    name="email"
                                                />
                                            </div>
                                            <div className="col-md-12">
                                                <input
                                                    type="text"
                                                    placeholder="Phone Number"

                                                />
                                            </div>

                                        </div>
                                        <div className="fieldsets row">

                                            <div class="form-group col-md-6">


                                                <select id="inputState" class="form-control">
                                                    <option selected>Choose your State</option>
                                                    <option>Ohio</option>
                                                    <option>Virginia</option>
                                                </select>
                                            </div>
                                            <div class="form-group col-md-6">


                                                <select id="inputState" class="form-control">
                                                    <option selected>Choose Your City</option>
                                                    <option>Miamisburg</option>
                                                    <option>Sterling</option>

                                                </select>
                                            </div>
                                        </div>
                                        <div className="">
                                            <div class=" col-md-12  ppp " >


                                                <select class="">
                                                    <option selected>Choose the Service you want</option>
                                                    <option>Training & Development</option>
                                                    <option>Staff Augmentation</option>
                                                    <option>Emerging Technology Integration</option>
                                                    <option>Digital Transformation</option>
                                                    <option>Content Development & Delivery</option>
                                                    <option>Enterprise Upskills Programs</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="">
                                            <div class=" col-md-12 ppp">
                                                <input type="text" placeholder='Enter Your Message here'></input>
                                            </div>
                                        </div>
                                        <div className="custom-control custom-checkbox">
                                            <input
                                                type="checkbox"
                                                className="custom-control-input"
                                                id="customCheck"
                                                name="example1"
                                                defaultChecked="checked"
                                            />
                                            <label className="custom-control-label" htmlFor="customCheck">
                                                By clicking the button below, you consent to Coding Dojo using automated technology and/or pre-recorded means to call, text and e-mail you at the information above, including your wireless number, if provided, regarding educational services. You also agree to our "Site Terms" and "Privacy Policy". This consent is not required as a condition to purchase a good or services. Msg and data rates may apply.
                                                I Agree - Continue.

                                            </label>
                                        </div>
                                        <div className="fieldsets mt20">
                                            {" "}
                                            <button
                                                type="submit"
                                                name="submit"
                                                className="lnk btn-main bg-btn"
                                            >
                                                Get Started <i className="fas fa-chevron-right fa-icon" />
                                                <span className="circle" />
                                            </button>{" "}
                                        </div>
                                        <p className="trm">
                                            <i className="fas fa-lock" />
                                            We hate spam, and we respect your privacy.
                                        </p>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <hr></hr>

            <Footer />

        </div>
    )
}
