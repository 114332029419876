import React from 'react'
import Footer from './Footer'
import Header from './Header'
import staff2 from './staff2.jpg'
import Telework from './Telework.gif'
import './Staff.css'
export const Staff = () => {
    return (
        <div>

            <div>
                <Header />
            </div>

            <img src={staff2} className="staffimg staffimg1" ></img>
            <div > <h2 ><span className='staffs staffs1'> Staff <br></br> Augmentation</span></h2></div>


            <section className="about-lead-gen pad-tb">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="common-heading text-l">
                                <span>Staff Augmentation</span>
                                <h2 className="mb20">
                                    Benefits of IT Staff Augmentation.
                                </h2>
                                <p style={{ textAlign: "justify" }}>
                                    While both staff augmentation and project outsourcing can be beneficial in certain circumstances, the former is often most beneficial for project-based businesses who are in a state of constant flux. With IT staff augmentation, organizations can use outsourced talent on an as-needed basis, while still being able to manage workers directly
                                </p>
                                <p className="mt10" style={{ textAlign: "justify" }}>
                                    When organizations seek outside help in completing important IT projects, they often consider two primary delivery models: staff augmentation and project outsourcing. Staff augmentation is a type of outsourcing model that uses temporary workers to fill short-term job positions within your company.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-6 lead-intro-">
                            <img
                                src={Telework}
                                alt="image"
                                className="img-fluid"
                            />
                        </div>
                    </div>
                </div>
            </section>

            <section className="about-lead-gen " style={{ marginTop: "-6rem" }}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="common-heading text-l">

                                <h2 className="mb20">
                                    Paying For What You Need.
                                </h2>
                                <p style={{ textAlign: "justify" }}>

                                    IT staff augmentation also allows organizations to save money over time. This unique model allows you to hire temporary workers, meaning you do not have to employ full-time employees which can be highly costly. You only pay for what you need for as long as you need it.
                                </p>


                            </div>
                        </div>
                        <div className="col-lg-6 ">
                            <div className="common-heading text-l">
                                <h2 className="mb20">
                                    Crucial Time Saver.
                                </h2>
                                <p className="mt10" style={{ textAlign: "justify" }}>

                                    With IT staff augmentation, you can also save your business leaders a lot of time. It takes time to create job postings, interview job candidates and go through the proper paperwork to hire full-time employees. With it, you can eliminate time spent recruiting new employees.
                                </p>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="about-lead-gen pad-tb">
                <div className="container">
                    <div className='common-heading text-l'>
                        <span>How it Works</span>

                        <p style={{ textAlign: "justify" }}>
                            To be truly successful, a business must have the proper resources and talent at its disposal. Unfortunately, an organization may lack the talent needed to reach its goals. That is when IT staff augmentation can come in useful. Here is a closer look at how it works.
                        </p>
                    </div>

                </div>
            </section>

            <section className="about-lead-gen pad-tb deter deter1">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="common-heading text-l">


                                <h2>1. Determining Needs</h2>
                                <p className="" style={{ textAlign: "justify" }}>

                                    The first step in the process involves clearly outlining your outsourcing goals. This ensures that you are in the best position to create a team of talented workers who have the energy, creativity and skills needed to complete the project on time and on budget.
                                </p>
                                <br></br>
                                <br></br>
                                <br></br>
                                <h2>2. Sourcing Talent</h2>
                                <p className="mt10" style={{ textAlign: "justify" }}>
                                    Next, take time to choose the right talent source. Not all technical staff firms are made equal and you want to ensure that you are choosing one that sufficiently vets their workers. By properly sourcing your talent, you can maintain peace of mind that your workers are equipped for the task at hand.

                                </p>


                            </div>
                        </div>
                        <div className="col-lg-6 ">
                            <div className="common-heading text-l">
                                <h2 >3. On-Boarding Talent</h2>
                                <p className="mt10" style={{ textAlign: "justify" }}>
                                    Once you have a team onboard, it is important to clearly define all roles and responsibilities. This will help ensure that there is no confusion about who plays what roles in the project and that the project remains on track. Allow team members to ask questions and provide feedback throughout the process to create a cohesive team environment.
                                </p>

                                <h2>4. Providing Ongoing Support</h2>
                                <p className="mt10" style={{ textAlign: "justify" }}>
                                    It is not enough to simply hire temporary staff and leave them to their own devices. To ensure that your project is completed based on your specifications and deadlines, it is important to provide continuous support to your team. Even the most talented workforce needs a leader to guide them through the process.
                                </p>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div>
                <Footer />
            </div>


        </div>
    )
}
