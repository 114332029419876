import React from 'react'
import Footer from '../Footer'
import Header from '../Header'

import '../Staff.css'
export const Financing = () => {
    return (
        <div>

            <Header />

            <div>
                <img src="https://www.aihr.com/wp-content/uploads/hr-finance-cover.png" style={{ width: "100%", marginTop: "5rem" , height:"30rem" }}></img>
             
            </div>

            <Footer />
        </div>
    )
}
