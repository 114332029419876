import React from 'react'
import Footer from '../Footer'
import Header from '../Header'

export const Meetourteam = () => {
    return (
        <div>

            <Header />

            <div>
                <section class="hero-section hero-creative-agenc1" data-background="images/banner/hero-creative-agenc1.jpg" id="home">
                    <div class="text-block">
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-7 v-center niwax" data-rellax-speed="-3">
                                    <div class="header-heading">
                                        <h1 class="wow fadeInUp text-effect-2" data-wow-delay=".2s">Meet Our Team.</h1>
                                        <p class="wow fadeInUp" data-wow-delay=".4s">Our catalogue features courses and certifications in tech, across software development, cyber security, data science and cloud. We believe that learning should be an interactive and engaging experience..</p>
                                        <a href="#" class="btn-main bg-btn6 lnk wow fadeInUp" data-wow-delay=".6s">GET STARTED<i class="fas fa-chevron-right fa-icon"></i><span class="circle"></span></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="service-section-prb pad-tb">
                    <br></br>
                    <br></br>
                    <br></br>
                    <div class="container"  >
                        <div class="row ">
                            <div data-tilt data-tilt-max="5" data-tilt-speed="1000" class="col-lg-6-cus wow fadeInUp" data-wow-delay=".2s">
                                <div class="service-sec-brp srvc-bg-nx bg-gradient13 text-w">
                                    <h4 class="mb10">Ours Team</h4>
                                    <p>A strategic tool for improving business outcomes by implementing internal educational programs that advance employee growth and retention..</p>

                                </div>
                            </div>

                            <div data-tilt data-tilt-max="5" data-tilt-speed="1000" class="col-lg-6-cus wow fadeInUp" data-wow-delay=".2s">
                                <div class="service-sec-brp srvc-bg-nx bg-gradient13 text-w">
                                    <h4 class="mb10">Ours Team</h4>
                                    <p>The practice of growing employees into managers and managers into effective leaders by the ongoing enhancement of certain knowledge, skills and abilities..</p>

                                </div>
                            </div>

                           
                            <div >
                                <br></br>
                                <br></br>
                               
                            </div>
                        </div>
                        <div class="-cta-btn mt2">
                            <div class="free-cta-title v-center zoomInDown wow" data-wow-delay="1.4s">
                                <p>Hire a <span>Dedicated Developer & Deploy</span></p>
                                <a href="#" class="btn-main bg-btn2 lnk">Hire Now<i class="fas fa-chevron-right fa-icon"></i><span class="circle"></span></a>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <Footer />

        </div>
    )
}
