import React from 'react'
import staffing from './staffing.gif'
import {Link} from "react-router-dom"
export const Third = () => {
    return (
        <div  style={{background: "linear-gradient(90deg,#fbff00 0,#0072ff)"}}>
            <hr></hr>
            <section className="about-lead-gen pad-tb">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div>
                                <h1 > <b>Apply, Learn, <br></br> Get a job ,<br></br> Or your Tuition back</b></h1>

                                <p>
                                If you don’t get a job within six months of joining our program, we will refund 100% of your tuition.
                                </p>

                            </div>

                            <Link to="/admission" className="btn-main bg-btn3 lnk mt20">
                                Apply Now <i className="fas fa-chevron-right fa-icon" />
                                <span className="circle" />
                            </Link>
                        </div>
                        <div className="col-lg-6 lead-intro-">
                            <img
                                src={staffing}
                                alt="image"
                                className="img-fluid"
                            />
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
