import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Home from './Components/Home'
import Tech1 from './Components/Footerpage/Tech1'
import { Tech2 } from './Components/Footerpage/Tech2'
import { Techtalent } from './Components/Footerpage/Techtalent'
import { Skill } from './Components/Footerpage/Skill'
import { Mission } from './Components/Header/Mission'
import { Events } from './Components/Footerpage/Events'
import { Aboutus } from './Components/Footerpage/Aboutus'
import { Techtalentblog } from './Components/Footerpage/Techtalentblog'
import { Contact } from './Components/Footerpage/Contact'
import { Faq } from './Components/Footerpage/Faq'
import { Privacypolicy } from './Components/Footerpage/Privacypolicy'
import { Termcondition } from './Components/Footerpage/Termcondition'
import { Refundpolicy } from './Components/Footerpage/Refundpolicy'
import { Lisence } from './Components/Footerpage/Lisence'
import { Course1 } from './Components/Footerpage/Course1'
import { Course2 } from './Components/Footerpage/Course2'
import { Course3 } from './Components/Footerpage/Course3'
import { Course4 } from './Components/Footerpage/Course4'
import { Emerging } from './Components/Header/Emerging'
import { Binarytraining } from './Components/Header/Binarytraining'
import { Hire } from './Components/Header/Hire'
import { Techcourse } from './Components/Header/Techcourse'
import { Admission } from './Components/Header/Admission'
import { Leader } from './Components/Header/Leader'
import { Binary } from './Components/Header/Binary'
import { Courses } from './Components/Header/Courses'
import Career from './Components/Header/Career'
import { Testimonials } from './Components/Header/Testimonials'
import { Binaryjourney } from './Components/Header/Binaryjourney'
import { Curriculum } from './Components/Header/Curriculum'
import { ContactUs } from './Components/Header/ContactUs'
import { Forindividuals } from './Components/Header/Mains/Forindividuals'
import  Forindividual2 from './Components/Header/Mains/Forindividual2'
import { Faqs } from './Components/Header/Faqs'
import { Staff } from './Components/Header/Staff'
import { Blogs } from './Components/Header/Mains/Blogs'
import { Digital } from './Components/Header/Mains/Digital'
import { Content } from './Components/Header/Mains/Content'
import { Enterprise } from './Components/Header/Mains/Enterprise'
import { Alumini } from './Components/Header/Mains/Alumini'
import { Campuses } from './Components/Header/Mains/Campuses'
import { Financing } from './Components/Header/Mains/Financing'
import { Vission } from './Components/Header/Mains/Vission'
import { Meetourteam } from './Components/Header/Mains/Meetourteam'
import {Quality} from './Components/Header/Mains/Quality'
import ScrumMaster from './Components/Header/Mains/ScrumMaster'
import GetStart from './Components/Header/Mains/GetStart'
import {Offcanvas} from './Components/Header/Mains/Offcanvas'
import ScrollToTop from './Components/Header/Mains/ScrollToTop'
import OurCulture from './Components/Header/OurCulture'
import Tution from './Components/Header/Mains/Tution'
import ApplyForm from './Components/Header/Mains/ApplyForm'
import { Howdothings } from './Components/Header/Mains/Howdothings'
import Cookies from './Components/Footerpage/Cookies'
import { Test } from './Components/Header/Mains/Test'
function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Tech1" element={<Tech1 />} />
          <Route path="/Tech2" element={<Tech2 />} />
          <Route path="/Techtalent" element={<Techtalent />} />
          <Route path="/Skill" element={<Skill />} />
          <Route path="/getstart" element={<GetStart />} />
         
          <Route path="/Events" element={< Events />} />
          <Route path="/Aboutus" element={<Aboutus />} />
          <Route path="/Techtalentblog" element={<Techtalentblog />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/Faq" element={<Faq />} />
          <Route path="/Privacypolicy" element={<Privacypolicy />} />
          <Route path="/Termcondition" element={<Termcondition />} />
          <Route path="/Refundpolicy" element={<Refundpolicy />} />
          <Route path="/Lisence" element={<Lisence />} />
          <Route path="/Course1" element={<Course1 />} />
          <Route path="/Course2" element={<Course2 />} />
          <Route path="/Course3" element={<Course3 />} />
          <Route path="/Course4" element={<Course4 />} />
          <Route path="/Emerging" element={<Emerging />} />
          <Route path="/binarytraining" element={<Binarytraining />} />
          <Route path="/Hire" element={<Hire />} />
          <Route path="/Techcourse" element={<Techcourse />} />
          <Route path="/admission" element={<Admission />} />
          <Route path="/ContactUs" element={<ContactUs />} />
          <Route path="/Mission" element={<Mission />} />
          <Route path="/Leader" element={<Leader />} />
          <Route path="/Binary" element={<Binary />} />
          <Route path="/Courses" element={<Courses />} />
          <Route path="/career" element={<Career />} />
          <Route path="/Testimonials" element={<Testimonials />} />
          <Route path="/Binaryjourney" element={<Binaryjourney />} />
          <Route path="/Curriculum" element={<Curriculum />} />
          <Route path="/Forindividuals" element={<Forindividuals />} />
          <Route path="/forindividual2" element={<Forindividual2 />} />
          <Route path="/Faqs" element={<Faqs />} />
          <Route path="/staff" element={<Staff />} />
          <Route path="/Blogs" element={<Blogs />} />
          <Route path="/Digital" element={<Digital />} />
          <Route path="/Content" element={<Content />} /> 
          <Route path="/Enterprise" element={<Enterprise />} /> 
          <Route path="/Alumini" element={<Alumini />} /> 
          <Route path="/Campuses" element={<Campuses />} /> 
          <Route path="/Financing" element={<Financing />} />  
          <Route path="/Vission" element={<Vission />} />  
          <Route path="/Meetourteam" element={<Meetourteam />} /> 
          <Route path="/quality" element={<Quality />} /> 
          <Route path="/scrummaster" element={<ScrumMaster />} /> 
          <Route path="/tution" element={<Tution />} /> 
          <Route path="/ourculture" element={<OurCulture/>} /> 
          <Route path="/apply" element={<ApplyForm/>} />  
          <Route path="/Howdothings" element={<Howdothings/>} /> 
          <Route path="/cookiesPolicy" element={<Cookies/>} /> 
          <Route path="/Test" element={<Test/>} /> 
        </Routes>  
        <Offcanvas/>
        <ScrollToTop/>
      </Router>
    </>
  )
}

export default App