import React from 'react'
import Footer from '../Footer'
import Header from '../Header'
import aluminifinal1 from './aluminifinal1.jpeg'
import '../Staff.css'
export const Alumini = () => {
    return (
        <div>
            <Header />

            <div>
                <img src={aluminifinal1} style={{ width: "100%", marginTop: "5.5rem", height: "30rem" }}></img>


            </div>
            <section className="about-lead-gen ">

                <br></br>
                <br></br>

                <center > <h2 style={{ color: "darkorange" , marginTop:"-1.6rem"}} >Hear The Sucess Stories</h2></center>
                <div className='row' style={{ marginLeft: "2rem", marginRight: "2rem" }}>
                    <div className='col-sm' style={{background:"antiquewhite"}}>

                        <center> <img src='https://kalasalingam.ac.in/wp-content/uploads/2021/08/Achievements-dummy-profile.png' style={{ height: "15rem" }} />
                            <center>
                           
                                <h6>  Jean Philippe Henry- (JP) - KLAS   </h6>
                                <h6>position - Technical Support Intern </h6>
                            </center>
                        </center>
                        <p>
                            The right place to learn software engineering from professionals, whether in the classroom or remotely.
                            You can be deployed in the job market and find your dream job right after your training. I was lucky to
                            find Binary Logic and grateful to the trainers and the human ressources employees
                        </p>


                    </div>
                    <div className='col-sm' style={{background:"#f2ffad"}}>

                        <center> <img src='https://kalasalingam.ac.in/wp-content/uploads/2021/08/Achievements-dummy-profile.png' style={{ height: "15rem" }} />
                            <center>
                             
                                <h6> Mourtada Imam - Hexaware  </h6>
                                <h6>position - Sr. Software Engineer  </h6>
                            </center>
                        </center>
                        <p>
                            The right place to learn software engineering from professionals, whether in the classroom or remotely.
                            You can be deployed in the job market and find your dream job right after your training. I was lucky to
                            find Binary Logic and grateful to the trainers and the human ressources employees
                        </p>


                    </div>
                    <div className='col-sm' style={{background: "#d3eaff"}}>

                        <center> <img src='https://kalasalingam.ac.in/wp-content/uploads/2021/08/Achievements-dummy-profile.png' style={{ height: "15rem" }} />
                            <center>
                            
                                <h6>Yuriy Korolev - Hexaware  </h6>
                                <h6>position - Sr. software engineer </h6>
                            </center>
                        </center>
                        <p>
                            The right place to learn software engineering from professionals, whether in the classroom or remotely.
                            You can be deployed in the job market and find your dream job right after your training. I was lucky to
                            find Binary Logic and grateful to the trainers and the human ressources employees
                        </p>


                    </div>




                </div>

                <br></br>

                <div className='row' style={{ marginLeft: "2rem", marginRight: "2rem" }}>
                <div className='col-sm' style={{background:"#ffd7a2"}}>

                        <center> <img src='/static/media/femaleicon.9cd9ef7ef23ca74cba58.png' style={{ height: "15rem" }} />
                         
                        <center>
                            
                            <h6> Irene (Ereny Michael)- Wipro  </h6>
                            <h6>position - Soft. engineer </h6>
                        </center>
                        </center>
                        <p>
                            The staff at BLIT are dedicated to equip you with the skills and resources needed. They go above and
                            beyond to help during and after the time with them
                        </p>


                    </div>

                    <div className='col-sm' style={{background:"#c6fbca"}}>

                        <center> <img src='https://kalasalingam.ac.in/wp-content/uploads/2021/08/Achievements-dummy-profile.png' style={{ height: "15rem" }} />
                        </center>
                        <center> 
                            <h6>Mustafa- CVS Health  </h6>
                            <h6>Position - Spring boot Developer  </h6>
                        </center>
                        <p>
                            BLIT is a considerable avenue to a successful career in IT for those with the drive and discipline to work
                            towards it.
                        </p>
                    </div>

                    <div className='col-sm' style={{background:"#fff384"}}>

                        <center> <img src='/static/media/femaleicon.9cd9ef7ef23ca74cba58.png' style={{ height: "15rem" }} />
                        </center>
                        <center>  
                            <h6>Julian - booz Allen Hamilton  </h6>
                            <h6>position - Soft. Developer  </h6>
                        </center>
                        <p>
                            Binary Logic provides extensive training and real-world skill development to get the job you want. I can't
                            thank them enough, and have made connections I'll keep for the rest of life.
                        </p>

                    </div>


                </div>
            </section>
            <hr></hr>

            <Footer />
        </div>
    )
}
