import React from 'react'
import { useState } from 'react';
import Footer from '../Footer';
import './Test.css'
import Header from '../Header';
export const Test = () => {




    return (
        <div>

            <Header />

            <br></br>
            <br></br>
            <br></br>
           
           

            <main style={{textAlign: "left"}}>
      <form method="post" action="https://freecodecamp.org/practice-project/accessibility-quiz">
       
        <section role="region" aria-labelledby="html-questions" className='secwidth'>
          <h2 id="html-questions" className='haa'>Start Test</h2>
          <div class="question-block">
            <p>1</p>
            <fieldset class="question" name="html-question-one">
              <legend>
                The legend element represents a caption for the content of its
                parent fieldset element
              </legend>
              <ul class="answers-list">
                <li>
                  <label for="q1-a1">
                    <input type="radio" id="q1-a1" name="q1" value="true" />
                    True
                  </label>
                </li>
                <li>
                  <label for="q1-a2">
                    <input type="radio" id="q1-a2" name="q1" value="false" />
                    False
                  </label>
                </li>
              </ul>
            </fieldset>
          </div>
          <div class="question-block">
            <p>2</p>
            <fieldset class="question" name="html-question-two">
              <legend>
                A label element nesting an input element is required to have a
                for attribute with the same value as the input's id
              </legend>
              <ul class="answers-list">
                <li>
                  <label for="q2-a1">
                    <input type="radio" id="q2-a1" name="q2" value="true" />
                    True
                  </label>
                </li>
                <li>
                  <label for="q2-a2">
                    <input type="radio" id="q2-a2" name="q2" value="false" />
                    False
                  </label>
                </li>
              </ul>
            </fieldset>
          </div>
        </section>
       
        <button type="submit" className='sumitbtn'>Submit</button>
      </form>
    </main>

            <Footer />
        </div>
    )
}
