import React from 'react'
import Footer from './Footer'
import Header from './Header'
import vir1 from './Mains/vir1.jpeg'
import journey6 from './Mains/journey6.png'
import './Binary.css'
export const Binaryjourney = () => {
    return (
        <div>

            <Header />

            <br></br>
            <br></br>


            <div>
                <hr />
                <div  >
                    {/* <section class="hero-card-web bg-gradient12 shape-bg3" style={{ background: "linear-gradient(to right, rgb(46, 168, 205) 0%, rgb(188 188 219) 100%)" }} >
                        <div class="hero-main-rp container-fluid">
                            <div class="row">
                                <div class="col-lg-5">
                                    <div class="hero-heading-sec">
                                        <h3 style={{ color: "white" }}>Your Journey With Binary</h3>
                                        <p>
                                            Meeting Binary Logic IT LLC was out of the blue and considering the scale of the thoughts on talent management, it has been an amazing journey with them on a variety of our diverse needs. Cost, quality and time-effective. <br></br> <br></br>
                                            If you have a problem statement then they always have a better solution. They know their customers and ensure customer experience as their top priority.
                                        </p>



                                    </div>
                                </div>
                                <div class="col-lg-7">
                                    <div class="hero-content-sec wow fadeIn" data-wow-delay="0.8s">
                                        <div class="video-intro-pp"><a class="video-link play-video" href="https://www.youtube.com/watch?v=SZEflIVnhH8?autoplay=1&amp;rel=0"><span class="triangle-play"></span></a></div>
                                        <div class="title-hero-oth" >

                                            <img src={journey6} style={{ height: "25rem" }} ></img>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </section> */}
                </div>

                {/* <section class="service-section-prb " style={{ marginTop: "-2rem" }}>

                    <div class="container" style={{ marginTop: "1rem" }} >
                        <div class="row upset">
                            <div data-tilt data-tilt-max="4" data-tilt-speed="1000" class="col-sm-4 -cus wow fadeInUp" data-wow-delay=".2s">
                                <div class="service-sec-brp srvc-bg-nx bg-gradient13 text-w">
                                    <h4 class="mb10">BUSINESS SERVICES</h4>
                                    <p>
                                        We are committed about the building of consulting & software systems in order to deliver value to our customers and to act in
                                    </p>

                                </div>
                            </div>

                            <div data-tilt data-tilt-max="4" data-tilt-speed="1000" class="col-sm-4 -cus wow fadeInUp" data-wow-delay=".2s">
                                <div class="service-sec-brp srvc-bg-nx bg-gradient13 text-w">
                                    <h4 class="mb10">FINANCIAL SERVICES</h4>
                                    <p>
                                        The financial services dynamics is impermanent from advisory to compliances; whether it’s Wealth Management, Insurance, Stocks, Debt or Audit consulting etc…
                                    </p>

                                </div>
                            </div>

                            <div data-tilt data-tilt-max="4" data-tilt-speed="1000" class="col-sm-4 -cus wow fadeInUp" data-wow-delay=".2s">
                                <div class="service-sec-brp srvc-bg-nx bg-gradient13 text-w">
                                    <h4 class="mb10">TECHNOLOGY</h4>
                                    <p>
                                        Our Binary Logic IT LLC team extends decade of experience with business/digital transformation journey,.
                                    </p>

                                </div>
                            </div>
                        </div>

                    </div>
                </section> */}
                <hr />

                <section className="about-lead-gen pad-tb">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="common-heading text-l mt-4">
                                    <span style={{ fontSize: "2.8em" }}>  Start the application process </span>

                                    <h3>Submit your application to start the admissions process for your program!.</h3>
                                    <div className="servie-key-points">

                                        <ul className="key-points mt30">

                                            <li>Applications are free to submit and editable upon request.</li>

                                            <li>Enrollment is 'first come, first serve'. Applying early is highly recommended.</li>
                                            <li>From start to finish, this only takes 2 minutes!</li>
                                            <h5>Go ahead; take the plunge. You've got this.</h5>


                                        </ul>
                                    </div>

                                </div>
                            </div>
                            <div className="col-lg-6 lead-intro-">
                                <div className="col-lg-12 v-center" style={{ background: "darkgray", padding: "14px", borderRadius: "10px" }}>
                                    <div className="common-heading text-l">

                                        <h4 className="text-center" style={{ color: "darkblue" }}>
                                            First, let's get some basic info.
                                        </h4>
                                    </div>
                                    <div className="form-block" >
                                        <form action="#" method="post" name="feedback-form" >
                                            <div className="fieldsets row">
                                                <div className="col-md-6">
                                                    <input type="text" placeholder="First Name" name="name" />
                                                </div>

                                                <div className="col-md-6">
                                                    <input type="text" placeholder="Last Name" name="name" />
                                                </div>
                                                <div className="col-md-12">
                                                    <input
                                                        type="text"
                                                        placeholder="Email Address"
                                                        name="email"
                                                    />
                                                </div>
                                                <div className="col-md-12">
                                                    <input
                                                        type="text"
                                                        placeholder="Phone Number"

                                                    />
                                                </div>

                                            </div>
                                            <div className="fieldsets row">

                                                <div class="form-group col-md-6">


                                                    <select id="inputState" class="form-control">
                                                        <option selected>Choose Your State</option>
                                                        <option>Ohio</option>
                                                        <option>Virginia</option>
                                                    </select>
                                                </div>
                                                <div class="form-group col-md-6">


                                                    <select id="inputState" class="form-control">
                                                        <option selected>Choose Your City</option>
                                                        <option>Miamisburg</option>
                                                        <option>Sterling</option>
                                                    </select>
                                                </div>
                                            </div>
                                            {/* <div className="">
                                                <div class=" col-md-12  ppp " >


                                                    <select class="">
                                                        <option selected>-Select-</option>
                                                        <option>...</option>
                                                    </select>
                                                </div>
                                            </div> */}
                                            <div className="">
                                                <div class=" col-md-12 ppp">


                                                    <select id="inputState" class="">
                                                        <option selected>Choose program Category</option>
                                                        <option>Software Engineering</option>
                                                        <option>Quality Assurance</option>
                                                        <option>Scrum Master</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="">
                                            <div class=" col-md-12 ppp">
                                                <input type="text" placeholder='Enter Your Message here'></input>
                                            </div>
                                        </div>
                                        
                                            <div className="custom-control custom-checkbox">
                                                <input
                                                    type="checkbox"
                                                    className="custom-control-input"
                                                    id="customCheck"
                                                    name="example1"
                                                    defaultChecked="checked"
                                                />
                                                <label className="custom-control-label" htmlFor="customCheck">
                                                    By clicking the button below, you consent to Coding Dojo using automated technology and/or pre-recorded means to call, text and e-mail you at the information above, including your wireless number, if provided, regarding educational services. You also agree to our "Site Terms" and "Privacy Policy". This consent is not required as a condition to purchase a good or services. Msg and data rates may apply.
                                                    I Agree - Continue.

                                                </label>
                                            </div>
                                            <div className="fieldsets mt20">
                                                {" "}
                                                <button
                                                    type="submit"
                                                    name="submit"
                                                    className="lnk btn-main bg-btn"
                                                >
                                                    Get Started <i className="fas fa-chevron-right fa-icon" />
                                                    <span className="circle" />
                                                </button>{" "}
                                            </div>
                                            <p className="trm">
                                                <i className="fas fa-lock" />
                                                We hate spam, and we respect your privacy.
                                            </p>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>


            <Footer />
        </div>
    )
}
